import React, { useEffect, useState } from "react";
import {
  AlignRightOutlined,
  UserOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Dropdown, Avatar, Button } from "antd";
import { LuLogOut, LuGlobe } from "react-icons/lu";
import { RiMessage2Line } from "react-icons/ri";
import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri";
import { RxTextAlignRight } from "react-icons/rx";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook for navigation
import "../style.css";
const UserInfo = ({ mobileViewItems }) => {
  const navigate = useNavigate(); // useNavigate hook for navigation

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const [isMobile, setIsMobile] = useState(false);

  // Function to detect if the view is mobile
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Example breakpoint for mobile view
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Check the initial size
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const firstName = sessionStorage.getItem("firstName")
  const lastName = sessionStorage.getItem('lastName');
  const userName = sessionStorage.getItem('userName');
  const initials = `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
  const items = [
    {
      label: (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Avatar
              style={{ width: "30px", height: "30px", backgroundColor: '#87d068' }}
            >
              {initials}
            </Avatar>
            <div>
              <p style={{ margin: "0", fontWeight: "500" }}>
                {firstName} {lastName}
              </p>
              <small style={{ color: "#8692A6" }}>
                {userName}
              </small>
            </div>
          </div>
          <Button
            className="adminBtn"
            style={{
              borderRadius: "16px",
              border: "1.8px solid #001B72",
              marginTop: "10px",
              fontSize: "12px",
              color: "#001B72",
            }}
            block
          >
            Admin
          </Button>
          <hr style={{ border: ".2px solid #F0F0F0" }} />
        </>
      ),
    },
    // {
    //   label: (
    //     <a href="#">
    //       <div style={{ display: "flex", gap: "10px", fontWeight: "500" }}>
    //         <SettingOutlined />
    //         Settings
    //       </div>
    //     </a>
    //   ),
    // },
    // {
    //   label: (
    //     <a href="#">
    //       <div style={{ display: "flex", gap: "10px", fontWeight: "500" }}>
    //         <QuestionCircleOutlined />
    //         FAQ
    //       </div>
    //     </a>
    //   ),
    // },
    // {
    //   label: (
    //     <a href="#">
    //       <div
    //         style={{
    //           display: "flex",
    //           gap: "10px",
    //           fontWeight: "500",
    //           alignItems: "center",
    //         }}
    //       >
    //         <RiMessage2Line style={{ fontSize: "16px" }} /> Help & Resources
    //       </div>
    //       <hr style={{ border: ".2px solid #F0F0F0" }} />
    //     </a>
    //   ),
    // },
    {
      label: (
        <a href="#" onClick={handleLogout}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              fontWeight: "500",
              color: "red",
              alignItems: "center",
            }}
          >
            <LuLogOut
              style={{ transform: "rotate(180deg)", fontSize: "16px" }}
            />{" "}
            Logout
          </div>
        </a>
      ),
    },
  ];

  const mobileItems = [
    {
      label: (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Avatar
              style={{ width: "30px", height: "30px", backgroundColor: '#87d068' }}
            >
              {initials}
            </Avatar>
            <div>
              <p style={{ margin: "0", fontWeight: "500" }}>
                Kiranjith Ravindran
              </p>
              <small style={{ color: "#8692A6" }}>
                kiranjith.r123@gmail.com
              </small>
            </div>
          </div>
          <Button
            className="adminBtn"
            style={{
              borderRadius: "16px",
              border: "1.8px solid #001B72",
              marginTop: "10px",
              fontSize: "12px",
              color: "#001B72",
            }}
            block
          >
            Admin
          </Button>
          <hr style={{ border: ".2px solid #F0F0F0" }} />
        </>
      ),
    },
    ...mobileViewItems,
    {
      label: (
        <>
          <hr style={{ border: ".2px solid #F0F0F0" }} />
          <a href="#">
            <div style={{ display: "flex", gap: "10px", fontWeight: "500" }}>
              <SettingOutlined />
              Settings
            </div>
          </a>
        </>
      ),
    },
    {
      label: (
        <a href="#">
          <div style={{ display: "flex", gap: "10px", fontWeight: "500" }}>
            <QuestionCircleOutlined />
            FAQ
          </div>
        </a>
      ),
    },
    {
      label: (
        <a href="#">
          <div
            style={{
              display: "flex",
              gap: "10px",
              fontWeight: "500",
              alignItems: "center",
            }}
          >
            <RiMessage2Line style={{ fontSize: "16px" }} /> Help & Resources
          </div>
          <hr style={{ border: ".2px solid #F0F0F0" }} />
        </a>
      ),
    },
    {
      label: (
        <a href="#" onClick={handleLogout}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              fontWeight: "500",
              color: "red",
              alignItems: "center",
            }}
          >
            <LuLogOut
              style={{ transform: "rotate(180deg)", fontSize: "16px" }}
            />{" "}
            Logout
          </div>
        </a>
      ),
    },
  ];

  return (
    <Dropdown
      overlayClassName="userDrop"
      menu={{ items: isMobile ? mobileItems : items }}
      trigger={["click"]}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Avatar
          style={{ width: "30px", height: "30px", backgroundColor: '#87d068' }}
        >
          {initials}
        </Avatar>
        <RiArrowDownSLine
          className="downArrow"
          style={{
            fontSize: "16px",
            color: "#5D5D5D",
            marginLeft: "5px",
            verticalAlign: "middle",
          }}
        />
        <RxTextAlignRight
          className="alignRight"
          style={{
            fontSize: "30px",
            color: "#000000",
            marginLeft: "5px",
            verticalAlign: "middle",
          }}
        />
      </a>
    </Dropdown>
  );
};

export default UserInfo;
