import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../Components/Navbar";
import { UserOutlined } from "@ant-design/icons";
import { LuCheckCircle2 } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import HeaderTable from "../../Components/HeaderTable";
import "../NavlinksTable.css";
import { IoIosMore, IoMdCheckmark } from "react-icons/io";
import ViewButton from "../../Assets/ViewButton";
import DetailModal from "../../Assets/DetailModal";
import ConfirmationModal from "../../Assets/ConfirmationModal";
import moment from "moment";
import {
  Table,
  Badge,
  Dropdown,
  Avatar,
  Menu,
  Pagination,
  Select,
  Button,
  Steps,
  Input,
  Divider,
  Checkbox,
  Form,
  Spin,
  DatePicker,
} from "antd";
import { FiPlus } from "react-icons/fi";
import DragDropInput from "../../Components/DragDropInput";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import successIcon from "../../Assets/Images/successIcon.png";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import MessageRes from "../../Assets/MessageRes";
const ActionButton = ({ status }) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Active{" "}
          {status === "Active" ? (
            <IoMdCheckmark
              style={{
                color: "black",
                marginLeft: "20px",
                verticalAlign: "middle",
              }}
            />
          ) : null}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Inactive{" "}
          {status === "Inactive" ? (
            <IoMdCheckmark
              style={{
                color: "black",
                marginLeft: "20px",
                verticalAlign: "middle",
              }}
            />
          ) : null}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Edit
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="d-flex">
      <Dropdown overlay={menu}>
        <IoIosMore />
      </Dropdown>
    </div>
  );
};
const columns = [
  { title: "#", dataIndex: "no" },
  { title: "Photo", dataIndex: "photo" },
  { title: `Name`, dataIndex: "name" },
  // { title: 'Nationality', dataIndex: 'nationality' },
  // { title: 'Address', dataIndex: 'address' },
  { title: "Email", dataIndex: "emiratesId" },
  { title: "User Name", dataIndex: "userName" },
  {
    title: "Status",
    dataIndex: "status",
    render: (text) => (
      <span>
        {text === "ACTIVE" ? (
          <Badge
            count={
              <>
                <LuCheckCircle2
                  className="tabStatusIcon"
                  style={{
                    color: "green",
                    verticalAlign: "middle",
                    fontSize: "16px",
                  }}
                />{" "}
                Active
              </>
            }
          />
        ) : text === "Booked" ? (
          <Badge
            count={
              <>
                <LuCheckCircle2
                  className="tabStatusIcon"
                  style={{
                    color: "#00C8F7",
                    verticalAlign: "middle",
                    fontSize: "16px",
                  }}
                />{" "}
                Booked
              </>
            }
          />
        ) : (
          <Badge
            count={
              <>
                <RxCrossCircled
                  className="tabStatusIcon"
                  style={{
                    color: "red",
                    verticalAlign: "middle",
                    fontSize: "17px",
                  }}
                />{" "}
                Inactive
              </>
            }
          />
        )}
      </span>
    ),
  },
  {
    title: "Action",
    dataIndex: "status",
    key: "x",
    render: (status) => <ActionButton status={status} />,
  },
];
const generateData = (driverList) => {
  return driverList.map((driverData, index) => ({
    no: (index + 1).toString().padStart(2, "0"),
    photo: (
      <Avatar
        className="userAvatar"
        src={require("../../Assets/Images/Ellipse 12.png")}
        icon={<UserOutlined />}
      />
    ),
    name: `${driverData.firstName} ${driverData.lastName}`,
    // nationality: 'Indian',
    // address: 'B456, Main Street, Karama, Dubai',
    emiratesId: driverData.email,
    userName: driverData.userName,
    status: driverData.status,
  }));
};
const CardView = ({ data, currentPage, pageSize, onViewDetails }) => {
  // const startIndex = (currentPage - 1) * pageSize;
  // const endIndex = startIndex + pageSize;
  const currentData = data;
  return (
    <div className="cardContainer">
      {currentData.length > 0 ? (
        currentData.map((item) => (
          <div key={item.no} className="card">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600px",
                  margin: "0 0 10px 0",
                }}
              >
                {item.name}
              </p>
              {item.status === "ACTIVE" ? (
                <Badge
                  count={
                    <>
                      <LuCheckCircle2
                        style={{
                          color: "green",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />{" "}
                      Active
                    </>
                  }
                />
              ) : item.status === "Booked" ? (
                <Badge
                  count={
                    <>
                      <LuCheckCircle2
                        style={{
                          color: "#00C8F7",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />{" "}
                      Booked
                    </>
                  }
                />
              ) : (
                <Badge
                  count={
                    <>
                      <RxCrossCircled
                        style={{
                          color: "#C01111",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />{" "}
                      Inactive
                    </>
                  }
                />
              )}
            </div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500px",
                margin: "10px 0 20px 0",
              }}
            >
              {item.emiratesId}
            </p>
            <ViewButton onClick={() => onViewDetails(item)} />
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};
const { Step } = Steps;
const initFormData = {
  photo: null,
  firstName: "",
  lastName: "",
  gender: "",
  nationality: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  country: "UAE",
  pinCode: "",
  email: "",
  phoneNumber: "",
  emiratesId: "",
  emiratesIdPhotoFront: null,
  emiratesIdPhotoBack: null,
  passportNumber: "",
  passportNumberPhotoFront: null,
  passportNumberPhotoBack: null,
  licenseNumber: "",
  licenseType: "",
  licensePhotoFront: null,
  licensePhotoBack: null,
  licenseIssueDate: "",
  licenseExpiryDate: "",
  drivingExperience: null,
  transporterId: null,
  vehicleId: null,
};
export default function Drivers() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [statusToChange, setStatusToChange] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [driverList, setDriverList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isAddConfirmModalVisible, setIsAddConfirmModalVisible] =
    useState(false);
  const [current, setCurrent] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = driverList.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.emiratesId.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.status.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = sessionStorage.getItem("token");
  const [formData, setFormData] = useState(initFormData);
  const [isValidStep, setIsValidStep] = useState([false, false, false]);

  useEffect(() => {
    validateCurrentStep();
  }, [formData, current]);

  const validateCurrentStep = () => {
    const stepValidations = [
      // Step 1 validation
      formData.photo &&
        formData.firstName &&
        formData.lastName &&
        formData.gender &&
        formData.nationality &&
        formData.addressLine1 &&
        formData.addressLine2 &&
        formData.city &&
        formData.state &&
        formData.pinCode &&
        formData.email &&
        formData.phoneNumber,

      // Step 2 validation
      formData.emiratesId &&
        formData.emiratesIdPhotoFront &&
        formData.emiratesIdPhotoBack &&
        formData.passportNumber &&
        formData.passportNumberPhotoFront &&
        formData.passportNumberPhotoBack,

      // Step 3 validation
      formData.licenseNumber &&
        formData.licenseType &&
        formData.licensePhotoFront &&
        formData.licensePhotoBack &&
        formData.licenseIssueDate &&
        formData.licenseExpiryDate &&
        formData.drivingExperience,

      // Step 4 validation
      formData.transporterId,
    ];
    setIsValidStep(stepValidations);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchDriversList(currentPage - 1, pageSize);
  }, [currentPage, pageSize]);

  const fetchDriversList = async (page = 0, size = pageSize) => {
    const params = new URLSearchParams({
      page: page,
      size: size,
      sort: "createdDate,desc",
      userType: "DRIVER",
    });
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/users/list?${params.toString()}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setDriverList(generateData(data.content));
        setTotalItems(data.page.totalElements);
      })
      .catch((error) => {
        console.error("Error fetching Data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the request completes
      });
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Example breakpoint for mobile view
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleViewDetails = (item) => {
    setModalItem(item);
    setIsModalVisible(true);
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalItem(null);
  };
  // const handleStatusChange = (value) => {
  //     setStatusToChange(value);
  //     setIsConfirmModalVisible(true);
  // };
  const handleConfirmStatusChange = () => {
    setModalItem({ ...modalItem, status: statusToChange });
    setIsConfirmModalVisible(false);
  };
  const handleCancelStatusChange = () => {
    setIsConfirmModalVisible(false);
  };

  const handleAddDriver = () => {
    setIsAddConfirmModalVisible(true);
  };
  const handleCancelAddDriver = () => {
    setFormData(initFormData);
    setCurrent(0);
    setIsAddConfirmModalVisible(false);
  };
  ////////////////////////
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const formattedValue = type === "number" ? Number(value) : value;
    const finalValue =
      name === "licenseIssueDate" || name === "licenseExpiryDate"
        ? moment(formattedValue).format("YYYY-MM-DD")
        : formattedValue;
    setFormData({ ...formData, [name]: finalValue });
  };

  const handleDateChange = (name, date, dateString) => {
    setFormData({ ...formData, [name]: dateString });
  };
  const handleSelectChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };
  const handleFileChange = (name, file) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: file,
    }));
  };
  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/documents/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,
          },
        }
      );
      return response.data.url;
    } catch (error) {
      console.error("Error uploading image:", error);
      const errorMessage =
      error.response?.data?.message || "Error Uploading Image";
      MessageRes("error", "Upload Failed", errorMessage);
      throw error;
    }
  };
  const handleNext = async () => {
    if (current === steps.length - 1) {
      try {
        // Create an array of upload promises
        const uploadPromises = Object.entries(formData).map(
          async ([key, value]) => {
            if (value instanceof File) {
              const url = await uploadImage(value);
              return [key, url];
            }
            return [key, value];
          }
        );

        // Wait for all uploads to complete in parallel
        const updatedEntries = await Promise.all(uploadPromises);
        const updatedFormData = Object.fromEntries(updatedEntries);

        // Submit the final formData
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/driver-details`,
          updatedFormData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          }
        );
        console.log("Data submitted successfully:", response.data);
        setFormData(initFormData);

        Swal.fire({
          html: `
                        <div style="padding: 45px 30px 25px 30px">
                            <img src="${successIcon}" alt="Success Icon"/>
                            <p style="color: black; font-size: 20px; font-weight: 500;">Driver Added Successfully</p>
                            <p style="color: rgba(0, 0, 0, 0.60); font-size: 16px; font-weight: 400;">Lorem ipsum dolor sit amet consectetur.<br/> Interdum diam amet tincidunt amet</p>
                            <div id="customButtonContainer"></div>
                        </div>
                    `,
          showConfirmButton: false,
          didOpen: () => {
            const container = document.getElementById("customButtonContainer");
            if (container) {
              // Render the Ant Design button inside the container
              const button = document.createElement("div");
              container.appendChild(button);
              ReactDOM.render(
                <Button
                  style={{
                    width: "74px",
                    height: "32px",
                    textAlign: "center",
                    borderRadius: "15px",
                    color: "black",
                    paddingInline: "15px",
                    border: "1px solid rgba(89, 108, 148, 0.12)",
                  }}
                  onClick={() => Swal.close()}
                >
                  OK
                </Button>,
                button
              );
            }
          },
        });
        setIsAddConfirmModalVisible(false);
        fetchDriversList();
        setCurrent(0);
        setCurrentPage(1);
      } catch (error) {
        console.error("Error submitting data:", error);
        const errorMessage =
          error.response?.data?.message || "Error submitting data";
        MessageRes("error", "Failed To Add", errorMessage);
      } finally {
        setIsSubmitting(false); // Hide spin
      }
    } else {
      setCurrent(current + 1);
    }
  };

  const steps = [
    {
      title: "Details",
      content: (
        <>
          <div
            className="dirverImageUpload"
            style={{ width: "236px", margin: "0 auto" }}
          >
            <p>
              Driver Photo <span style={{ color: "#FF4D4F" }}>*</span>
            </p>
            {isMobile ? (
              <img
                style={{ width: "64px", height: "64px", margin: "0 auto" }}
                src={require("../../Assets/Images/DriverUser.png")}
              />
            ) : (
              <DragDropInput
                onChange={(file) => handleFileChange("photo", file)}
              />
            )}
          </div>
          <div>
            <div className="driverStepsInput">
              <div className="driverOneInput">
                <p>
                  First Name <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="driverOneInput">
                <p>
                  Last Name <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="driverOneInput">
                <p>
                  Gender<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Choose Gender"
                  value={formData.gender}
                  onChange={(value) => handleSelectChange(value, "gender")}
                  options={[
                    { value: "MALE", label: "Male" },
                    { value: "FEMALE", label: "Female" },
                  ]}
                />
              </div>
            </div>
            <div className="driverStepsInput">
              <div className="driverOneInput">
                <p>
                  Nationality<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Choose Nationality"
                  onChange={(value) => handleSelectChange(value, "nationality")}
                  value={formData.nationality}
                  options={[
                    { value: "Emirati", label: "Emirati" },
                    { value: "Indian", label: "Indian" },
                  ]}
                />
              </div>
              <div className="driverOneInput">
                <p>
                  Address Line 1<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Address"
                  name="addressLine1"
                  value={formData.addressLine1}
                  onChange={handleInputChange}
                />
              </div>
              <div className="driverOneInput">
                <p>
                  Address Line 2<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Address"
                  name="addressLine2"
                  value={formData.addressLine2}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="driverStepsInput">
              <div className="driverOneInput">
                <p>
                  City <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter City"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                />
              </div>
              <div className="driverOneInput">
                <p>
                  Emirates <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Enter State"
                  onChange={(value) => handleSelectChange(value, "state")}
                  value={formData.state}
                  options={[
                    { value: "Dubai", label: "Dubai" },
                    { value: "Sharjah", label: "Sharjah" },
                    { value: "Ajman", label: "Ajman" },
                  ]}
                />
              </div>
              <div className="driverOneInput">
                <p>
                  Country<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Country"
                  name="country"
                  value="UAE"
                  disabled
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="driverStepsInput">
              <div className="driverOneInput">
                <p>
                  Postal code<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Postal code"
                  name="pinCode"
                  value={formData.pinCode}
                  onChange={handleInputChange}
                />
              </div>
              <div className="driverOneInput">
                <p>
                  Email Address <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="driverOneInput">
                <p>
                  Phone Number<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Identity",
      content: (
        <>
          <div>
            <div>
              <p>
                Emirates ID<span style={{ color: "#FF4D4F" }}>*</span>
              </p>
              <Input
                placeholder="Enter Emirates ID"
                name="emiratesId"
                value={formData.emiratesId}
                onChange={handleInputChange}
              />
            </div>
            <div
              className=""
              style={{ display: "flex", gap: "12px", margin: "12px auto" }}
            >
              <DragDropInput
                DragWidth={"100%"}
                text="Upload Front"
                onChange={(file) =>
                  handleFileChange("emiratesIdPhotoFront", file)
                }
              />
              <DragDropInput
                DragWidth={"100%"}
                text="Upload Back"
                onChange={(file) =>
                  handleFileChange("emiratesIdPhotoBack", file)
                }
              />
            </div>
          </div>
          <div>
            <div>
              <p>
                Passport No<span style={{ color: "#FF4D4F" }}>*</span>
              </p>
              <Input
                placeholder="Enter Passport No"
                name="passportNumber"
                value={formData.passportNumber}
                onChange={handleInputChange}
              />
            </div>
            <div
              className=""
              style={{ display: "flex", gap: "12px", margin: "12px auto" }}
            >
              <DragDropInput
                DragWidth={"100%"}
                text="Upload Front"
                onChange={(file) =>
                  handleFileChange("passportNumberPhotoFront", file)
                }
              />
              <DragDropInput
                DragWidth={"100%"}
                text="Upload Back"
                onChange={(file) =>
                  handleFileChange("passportNumberPhotoBack", file)
                }
              />
            </div>
          </div>
        </>
      ),
    },
    {
      title: "License",
      content: (
        <>
          <div>
            <div>
              <p>
                Driver’s License Number
                <span style={{ color: "#FF4D4F" }}>*</span>
              </p>
              <Input
                placeholder="Enter License Number"
                name="licenseNumber"
                value={formData.licenseNumber}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <p>
                Driver’s License Type<span style={{ color: "#FF4D4F" }}>*</span>
              </p>
              <Select
                style={{ width: "100%" }}
                placeholder="Choose License Type"
                onChange={(value) => handleSelectChange(value, "licenseType")}
                value={formData.licenseType}
                options={[
                  { value: "1", label: "Heavy Vehicle Type" },
                  { value: "2", label: "Light Vehicle Type" },
                ]}
              />
            </div>
            <div
              className=""
              style={{ display: "flex", gap: "12px", margin: "12px auto" }}
            >
              <DragDropInput
                DragWidth={"100%"}
                text="Upload Front"
                onChange={(file) => handleFileChange("licensePhotoFront", file)}
              />
              <DragDropInput
                DragWidth={"100%"}
                text="Upload Back"
                onChange={(file) => handleFileChange("licensePhotoBack", file)}
              />
            </div>
            <div>
              <p>
                Date of Issue<span style={{ color: "#FF4D4F" }}>*</span>
              </p>
              <DatePicker
                style={{ width: "100%" }}
                value={
                  formData.licenseIssueDate
                    ? moment(formData.licenseIssueDate)
                    : null
                }
                onChange={(date, dateString) =>
                  handleDateChange("licenseIssueDate", date, dateString)
                }
                required
                placeholder="Enter Date of Issue"
              />
            </div>
            <div>
              <p>
                Expiry Date<span style={{ color: "#FF4D4F" }}>*</span>
              </p>
              <DatePicker
                style={{ width: "100%" }}
                value={
                  formData.licenseExpiryDate
                    ? moment(formData.licenseExpiryDate)
                    : null
                }
                onChange={(date, dateString) =>
                  handleDateChange("licenseExpiryDate", date, dateString)
                }
                required
                placeholder="Enter Expiry Date"
              />
            </div>
            <div>
              <p>
                Total Driving Experience
                <span style={{ color: "#FF4D4F" }}>*</span>
              </p>
              <Input
                type="number"
                placeholder="Enter Year"
                name="drivingExperience"
                value={formData.drivingExperience}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Vehicle",
      content: (
        <>
          <div className="">
            <div>
              <p>Transporter</p>
              <Select
                style={{ width: "100%" }}
                placeholder="Choose Transporter"
                onChange={(value) => handleSelectChange(value, "transporterId")}
                value={formData.transporterId}
                options={[
                  { value: 1, label: "Company 1" },
                  { value: 2, label: "Company 2" },
                ]}
              />
            </div>
            <div>
              <p>Vehicle ID(Optional)</p>
              <Select
                style={{ width: "100%" }}
                placeholder="Choose Vehicle"
                onChange={(value) => handleSelectChange(value, "vehicleId")}
                value={formData.vehicleId}
                options={[]}
              />
            </div>
            {/* <div className="AddVehicleDriver">
              <span
                style={{
                  height: "48px",
                  width: "48px",
                  marginRight: "12px",
                  borderRadius: "8px",
                  background: "#D9D9D9",
                }}
              ></span>
              <div>
                <p style={{ margin: "0" }}>Lorem Ipsum</p>
                <small style={{ color: "#8692A6" }}>Lorem Ipsum</small>
              </div>
            </div> */}
          </div>
        </>
      ),
    },
  ];
  return (
    <div
      style={{
        background: "rgba(230, 230, 230, 0.40)",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <Navbar showStatusInfo={false} />
      <div className="tableContainer" style={{ fontWeight: "600" }}>
        <HeaderTable
          title="Drivers"
          buttonText="Add Driver"
          onAddClick={handleAddDriver}
          onSearchChange={handleSearchChange}
          activeCount={driverList.filter((v) => v.status === "ACTIVE").length}
          inactiveCount={
            driverList.filter((v) => v.status === "INACTIVE").length
          }
          bookedCount={driverList.filter((v) => v.status === "Booked").length}
        />
        <Spin size="large" spinning={loading}>
          <div
            className="bigScreenSize"
            style={{ color: "#747474 !important" }}
          >
            <Table
              style={{
                paddingInline: "20px",
                overflowX: "auto",
                borderRadius: "0 0 12px 12px",
                backgroundColor: "white",
              }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredData}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} items`,
                onChange: handlePageChange,
              }}
            />
          </div>
        </Spin>
        <div
          className="smallScreenSize"
          style={{ background: "#F7F8FB", padding: "0px 20px 20px 20px" }}
        >
          <CardView
            data={driverList}
            currentPage={currentPage}
            pageSize={pageSize}
            onViewDetails={handleViewDetails}
          />
          <div className="mobilePagination">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalItems}
              showTotal={(total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`
              }
              onChange={handlePageChange}
              style={{ textAlign: "center" }}
            />
          </div>
          <DetailModal
            isVisible={isModalVisible}
            handleClose={handleCloseModal}
            content={
              modalItem && (
                <div>
                  <span
                    style={{
                      display: "flex",
                      width: "60%",
                      justifyContent: "space-between",
                      marginTop: "5px",
                    }}
                  >
                    <p
                      style={{
                        margin: "0",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    >
                      Details
                    </p>
                    {modalItem.photo}
                  </span>
                  <span
                    style={{
                      display: "block",
                      height: "1px",
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.06)",
                      margin: "20px 0",
                    }}
                  ></span>
                  <span className="cardDetails">
                    <p>Name</p>
                    <p className="cardDetailHeading">{modalItem.name}</p>
                  </span>
                  {/* <span className="cardDetails">
                                <p>Nationality</p>
                                <p className="cardDetailHeading"> {modalItem.nationality}</p >
                            </span>
                            <span className="cardDetails">
                                <p>Address</p>
                                <p className="cardDetailHeading">{modalItem.address}</p>
                            </span> */}
                  <span className="cardDetails">
                    <p>Emirates ID</p>
                    <p className="cardDetailHeading">{modalItem.emiratesId}</p>
                  </span>
                  <span className="cardDetails">
                    <p>DL Number</p>
                    <p className="cardDetailHeading">{modalItem.userName}</p>
                  </span>
                  {/* <span className="cardDetails">
                                <p>Change Status</p>
                                <Select
                                    value={modalItem.status}
                                    style={{ width: 120 }}
                                    onChange={handleStatusChange}
                                >
                                    <Select.Option value="Active">
                                        <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                                    </Select.Option>
                                    <Select.Option value="Inactive">
                                        <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Inactive</>} />
                                    </Select.Option>
                                    <Select.Option value="Booked">
                                        <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
                                    </Select.Option>
                                </Select>
                            </span> */}
                </div>
              )
            }
          />
          <ConfirmationModal
            isVisible={isConfirmModalVisible}
            confirmModalMargin={"20%"}
            handleClose={handleCancelStatusChange}
            content={
              <div>
                <p
                  style={{
                    margin: "0 0 12px 0",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Are you sure?
                </p>
                <p>
                  Are You Sure You Want To Change The Status From{" "}
                  <strong>{modalItem?.status}</strong> To{" "}
                  <strong>{statusToChange}</strong>?
                </p>
              </div>
            }
            actions={[
              <Button
                style={{
                  marginTop: "30px",
                  width: "48%",
                  height: "32px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "black",
                  border: "1px solid rgba(89, 108, 148, 0.12)",
                }}
                key="cancel"
                onClick={handleCancelStatusChange}
              >
                Cancel
              </Button>,
              <Button
                style={{
                  marginTop: "30px",
                  width: "48%",
                  height: "32px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "white",
                  background: "#001B72",
                  border: "none",
                }}
                key="confirm"
                type="primary"
                onClick={handleConfirmStatusChange}
              >
                Confirm
              </Button>,
            ]}
          />
        </div>
      </div>

      <ConfirmationModal
        isVisible={isAddConfirmModalVisible}
        closeBtn={true}
        // onConfirm={handleConfirmAddTransporter}
        handleClose={handleCancelAddDriver}
        content={
          <Spin size="large" spinning={isSubmitting}>
            <div className="addVehicleMain">
              <div>
                <p style={{ margin: "0", fontSize: "20px", fontWeight: "500" }}>
                  Driver Onboarding
                </p>
              </div>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "rgba(0, 0, 0, 0.06)",
                  width: "100%",
                  margin: "20px 0 ",
                }}
              ></div>
              <Steps
                current={current}
                progressDot
                direction="horizontal"
                responsive={false}
                className="progressStepper"
              >
                {steps.map((step, index) => (
                  <Step key={index} title={step.title} />
                ))}
              </Steps>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "rgba(0, 0, 0, 0.06)",
                  width: "100%",
                  margin: "20px 0 ",
                }}
              ></div>
              <div className="steps-content">{steps[current].content}</div>
            </div>
          </Spin>
        }
        actions={[
          <div
            className="addFooterMainDiv"
            style={{
              display: "flex",
              gap: "12px",
              marginLeft: "auto",
              width: "max-content",
            }}
          >
            <Button
              className="addModalFooterBtn"
              style={{
                // width: '48%',
                height: "32px",
                textAlign: "center",
                borderRadius: "50px",
                color: "black",
                border: "1px solid rgba(89, 108, 148, 0.12)",
              }}
              key="cancel"
              onClick={handleCancelAddDriver}
            >
              Cancel
            </Button>
            <Button
              disabled={!isValidStep[current]}
              className="addModalFooterBtn"
              style={{
                height: "32px",
                textAlign: "center",
                borderRadius: "50px",
                color: "white",
                background: "#00C8F7",
                border: "none",
              }}
              key="confirm"
              type="primary"
              onClick={handleNext}
              // onClick={() => {
              //     setIsAddConfirmModalVisible(false);
              // }}
            >
              Continue
            </Button>
          </div>,
        ]}
      />
    </div>
  );
}
