import React from 'react';
import { Button } from 'antd';

const ViewButton = ({ onClick }) => {
    return (
        <Button
            style={{
                height: "36px",
                width: '100%',
                textAlign: "center",
                borderRadius: "40px",
                color: "rgba(0, 0, 0, 0.88)",
                border: "none",
                backgroundColor: '#F5F5F5'
            }}
            type="default"
            shape="round"
            onClick={onClick}
        >
            View Details
        </Button>
    );
};

export default ViewButton;
