import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';

const { Dragger } = Upload;

const DragDropInput = ({ responsiveClass, text, DragWidth, onChange }) => {
    const props = {
        name: 'file',
        maxCount:1,
        multiple: false, // Assuming single file upload for photo
        action: '', // URL to handle file upload if needed
        beforeUpload: (file) => {
            onChange(file); // Pass the file to the parent component
            return false; // Prevent automatic upload
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading')
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <div className={responsiveClass ? 'reponsiveDrag' : null} style={{ width: DragWidth }}>
            <Dragger {...props} className='dragBox'>
                <p className="ant-upload-drag-icon">
                    <UploadOutlined style={{ fontSize: '24px', color: '#00C8F7' }} />
                </p>
                <p className="ant-upload-text">
                    {text ? text : <>Drag & Drop, or <a style={{ textDecoration: 'underline', color: '#001B72', fontWeight: '500', fontSize: '14px', textUnderlineOffset: '3px', textDecorationThickness: '1.5px', textDecorationColor: '#001B72' }} href='#'>Browse</a> </>}
                </p>
            </Dragger>
        </div>
    );
};

export default DragDropInput;
