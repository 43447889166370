import React, { useState } from "react";
import { Table, Badge, Dropdown, Avatar, Menu, Pagination, Select, Button, Steps, Input, Divider, Checkbox } from 'antd';
import Navbar from "../Components/Navbar";
import { LuCheckCircle2, LuMinusCircle } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import HeaderTable from '../Components/HeaderTable';
import './NavlinksTable.css';
import { IoIosMore, IoMdCheckmark } from "react-icons/io";
import ViewButton from "../Assets/ViewButton";
import DetailModal from "../Assets/DetailModal";
import ConfirmationModal from "../Assets/ConfirmationModal";
import { HiOutlineExclamationCircle } from "react-icons/hi";

import Swal from 'sweetalert2';
import ReactDOM from 'react-dom';
import successIcon from "../Assets/Images/successIcon.png"
const ActionButton = ({ status }) => {
    const menu = (
        <Menu>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">
                    Active {status === 'Active' ? <IoMdCheckmark style={{ color: 'black', marginLeft: '20px', verticalAlign: 'middle' }} /> : null}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">
                    Inactive {status === 'Inactive' ? <IoMdCheckmark style={{ color: 'black', marginLeft: '20px', verticalAlign: 'middle' }} /> : null}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">Edit</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="d-flex">
            <Dropdown overlay={menu}>
                <IoIosMore />
            </Dropdown>
        </div>
    );
};

const DetailsIcon = (
    <HiOutlineExclamationCircle style={{ fontSize: '17px', verticalAlign: 'middle', color: '#8692A6', marginLeft: '3px', transform: 'rotate(180deg)' }} />
)
const columns = [
    { title: '#', dataIndex: 'no' },
    { title: 'Date', dataIndex: 'date' },
    { title: 'Trip ID', dataIndex: 'tripId' },
    { title: 'Delivery', dataIndex: 'delivery' },
    { title: 'Customer', dataIndex: 'customer' },
    { title: 'Vehicle', dataIndex: 'vehicle' },
    { title: 'Driver', dataIndex: 'driver' },
    { title: 'Start Time', dataIndex: 'startTime' },
    { title: 'End Time', dataIndex: 'endTime' },
    { title: 'Duration', dataIndex: 'duration' },
    { title: 'Distance', dataIndex: 'distance' },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (text) => (
            <span>
                {text === 'Active' ? (
                    <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                ) : text === 'Cancelled' ? (
                    <Badge count={<><RxCrossCircled className="tabStatusIcon" style={{ color: 'red', verticalAlign: 'middle', fontSize: '17px' }} /> Cancelled</>} />
                ) : text === 'Completed' ? (
                    <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Completed</>} />
                ) : (
                    <Badge count={<><LuMinusCircle style={{ color: '#FEA800', verticalAlign: 'middle', fontSize: '17px' }} /> Pending</>} />
                )}
            </span>
        ),
    },
    {
        title: 'Action',
        key: 'x',
        dataIndex: 'status',
        render: (status) => <ActionButton status={status} />,
    },
];

const generateData = (startTime, endTime, duration, statuses) => {
    return statuses.map((status, index) => ({
        no: (index + 1).toString().padStart(2, '0'),
        date: '18 Feb, 2024',
        tripId: 'ST0001',
        delivery: 'Abu Dhabi',
        customer: 'Abhijith S',
        vehicle: "F54329",
        driver: "Manjindar",
        vehicleDisplay: (
            <>
                <span className="bigScreenSize">
                    F54329 {DetailsIcon}
                </span>
                <span className="smallScreenSize">
                    {DetailsIcon} F54329
                </span>
            </>
        ),
        driverDisplay: (
            <>
                <span className="bigScreenSize">
                    Manjindar {DetailsIcon}
                </span>
                <span className="smallScreenSize">
                    {DetailsIcon} Manjindar
                </span>
            </>
        ),
        startTime: startTime[index] ? startTime[index].toString() : '', // Convert to string
        endTime: endTime[index] ? endTime[index].toString() : '', // Convert to string
        duration: duration[index] ? duration[index].toString() : '', // Convert to string
        distance: '300KM', // Placeholder data, replace with actual distance data if available
        status: status,
    }));
};
const statuses = [
    'Completed',
    'Cancelled',
    'Pending',
    'Active',
    'Pending',
    'Active',
    'Cancelled',
    'Active',
    'Active',
    'Completed',
    'Completed',
];
const startTime = [
    '09:10 AM',
    '-',
    '09:10 AM',
    '09:10 AM',
    '09:10 AM',
    '09:10 AM',
    '-',
    '09:10 AM',
    '09:10 AM',
    '09:10 AM',
    '09:10 AM',
];
const endTime = [
    '04:10 AM',
    '-',
    '-',
    '04:10 AM',
    '-',
    '04:10 AM',
    '-',
    '04:10 AM',
    '04:10 AM',
    '04:10 AM',
    '04:10 AM',
];
const duration = [
    '07:00H',
    '-',
    '07:00H',
    '07:00H',
    '-',
    '07:00H',
    '-',
    '07:00H',
    '07:00H',
    '07:00H',
    '07:00H',
];
const data = generateData(startTime, endTime, duration, statuses);
const activeCount = statuses.filter(status => status === 'Active').length;
const cancelledCount = statuses.filter(status => status === 'Cancelled').length;
const completedCount = statuses.filter(status => status === 'Completed').length;
const pendingCount = statuses.filter(status => status === 'Pending').length;
const CardView = ({ data, currentPage, pageSize, onViewDetails }) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentData = data.slice(startIndex, endIndex);

    return (
        <div className="cardContainer">
            {currentData.map(item => (
                <div key={item.no} className="card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontSize: '18px', fontWeight: '600px', margin: '0 0 10px 0' }}>{item.customer}</p>
                        {item.status === 'Active' ? (
                            <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                        ) : item.status === 'Completed' ? (
                            <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Completed</>} />
                        ) : item.status === 'Cancelled' ? (
                            <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Cancelled</>} />
                        ) : (
                            <Badge count={<><LuMinusCircle style={{ color: '#FEA800', verticalAlign: 'middle', fontSize: '17px' }} /> Pending</>} />
                        )
                        }
                    </div>
                    <p style={{ fontSize: '14px', fontWeight: '500px', margin: '10px 0 20px 0' }}>{item.delivery}</p>
                    <ViewButton onClick={() => onViewDetails(item)} />
                </div>
            ))}
        </div>
    );
};
export default function Trips() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [currentPageMobile, setCurrentPageMobile] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalItem, setModalItem] = useState(null);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [statusToChange, setStatusToChange] = useState(null);
    const pageSize = 5;
    const [isAddConfirmModalVisible, setIsAddConfirmModalVisible] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const filteredData = data.filter(item =>
        item.date.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.tripId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.delivery?.toLowerCase().includes(searchTerm.toLowerCase()) ||  // Optional chaining for emailId
        item.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.vehicle.toLowerCase().includes(searchTerm.toLowerCase()) || // Include startTime
        item.driver.toLowerCase().includes(searchTerm.toLowerCase()) || // Include endTime
        item.startTime.toLowerCase().includes(searchTerm.toLowerCase()) || // Include endTime
        item.endTime.toLowerCase().includes(searchTerm.toLowerCase()) || // Include endTime
        item.distance.toLowerCase().includes(searchTerm.toLowerCase()) || // Include endTime
        item.status.toLowerCase().includes(searchTerm.toLowerCase()) || // Include endTime
        item.duration.toLowerCase().includes(searchTerm.toLowerCase())   // Include duration
    );

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handlePageChangeMobile = (page) => {
        setCurrentPageMobile(page);
    };

    const handleViewDetails = (item) => {
        setModalItem(item);
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setModalItem(null);
    };

    const handleStatusChange = (value) => {
        setStatusToChange(value);
        setIsConfirmModalVisible(true);
    };

    const handleConfirmStatusChange = () => {
        setModalItem({ ...modalItem, status: statusToChange });
        setIsConfirmModalVisible(false);
    };

    const handleCancelStatusChange = () => {
        setIsConfirmModalVisible(false);
    };
    const handleAddTrip = () => {
        setIsAddConfirmModalVisible(true);
    };
    const handleCancelAddTrip = () => {
        setIsAddConfirmModalVisible(false);
    };

    return (
        <div style={{ background: 'rgba(230, 230, 230, 0.40)', height: '100%', overflow: 'scroll' }}>
            <Navbar showStatusInfo={false} />
            <div className="tableContainer" style={{ fontWeight: '600' }}>
                <HeaderTable
                    title="Trips"
                    buttonText="Download PDF"
                    icon="download"
                    onSearchChange={handleSearchChange}
                    onAddClick={handleAddTrip}
                    activeCount={activeCount}
                    cancelledCount={cancelledCount}
                    completedCount={completedCount}
                    pendingCount={pendingCount}
                />
                <div className="bigScreenSize" style={{ color: '#747474 !important' }}>
                    <Table
                        size='large'
                        style={{ paddingInline: '20px', borderRadius: '0 0 12px 12px', overflowX: 'auto', backgroundColor: 'white' }}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredData}
                        pagination={{
                            total: 100,
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of 892 items`,
                            defaultPageSize: 11,
                            defaultCurrent: 1,
                        }}
                    />
                </div>
                <div className="smallScreenSize" style={{ background: '#F7F8FB', padding: '0px 20px 20px 20px' }} >
                    <CardView data={data} currentPage={currentPageMobile} pageSize={pageSize} onViewDetails={handleViewDetails} />
                    <div className="mobilePagination">
                        <Pagination
                            current={currentPageMobile}
                            pageSize={pageSize}
                            total={data.length}
                            onChange={handlePageChangeMobile}
                            style={{ textAlign: 'center' }}
                        />
                    </div>
                    <DetailModal isVisible={isModalVisible} handleClose={handleCloseModal} content={modalItem && (
                        <div>
                            <p style={{ margin: "0", fontSize: '20px', fontWeight: '500' }}>Details</p>
                            <span style={{ display: 'block', height: '1px', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.06)', margin: '20px 0' }}></span>
                            <span className="cardDetails">
                                <p>Date</p>
                                <p className="cardDetailHeading">{modalItem.date}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Trip ID</p>
                                <p className="cardDetailHeading"> {modalItem.tripId}</p >
                            </span>
                            <span className="cardDetails">
                                <p>Delivery</p>
                                <p className="cardDetailHeading">{modalItem.delivery}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Customer</p>
                                <p className="cardDetailHeading">{modalItem.customer}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Vehicle</p>
                                <p className="cardDetailHeading">{modalItem.vehicleDisplay}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Driver</p>
                                <p className="cardDetailHeading">{modalItem.driverDisplay}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Start Time</p>
                                <p className="cardDetailHeading">{modalItem.startTime}</p>
                            </span>
                            <span className="cardDetails">
                                <p>End Time</p>
                                <p className="cardDetailHeading">{modalItem.endTime}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Duration</p>
                                <p className="cardDetailHeading">{modalItem.duration}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Distance</p>
                                <p className="cardDetailHeading">{modalItem.distance}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Change Status</p>
                                <Select
                                    value={modalItem.status}
                                    style={{ width: 'fit-content' }}
                                    onChange={handleStatusChange}
                                >
                                    <Select.Option value="Active">
                                        <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                                    </Select.Option>
                                    <Select.Option value="Cancelled">
                                        <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Cancelled</>} />
                                    </Select.Option>
                                    <Select.Option value="Completed">
                                        <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Completed</>} />
                                    </Select.Option>
                                    <Select.Option value="Pending">
                                        <Badge count={<><LuMinusCircle style={{ color: '#FEA800', verticalAlign: 'middle', fontSize: '16px' }} /> Pending</>} />
                                    </Select.Option>
                                </Select>
                            </span>
                        </div>
                    )} />
                    <ConfirmationModal isVisible={isConfirmModalVisible} handleClose={handleCancelStatusChange} content={
                        <div>
                            <p style={{ margin: "0 0 12px 0", fontSize: '20px', fontWeight: '500' }}>Are you sure?</p>
                            <p>Are You Sure You Want To Change The Status From <strong>{modalItem?.status}</strong> To <strong>{statusToChange}</strong>?</p>
                        </div>
                    } actions={[
                        <Button
                            style={{
                                marginTop: '30px',
                                width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "black",
                                border: "1px solid rgba(89, 108, 148, 0.12)",
                            }}
                            key="cancel" onClick={handleCancelStatusChange}>Cancel</Button>,
                        <Button
                            style={{
                                marginTop: '30px',
                                width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "white",
                                background: '#001B72',
                                border: "none",
                            }}
                            key="confirm" type="primary" onClick={handleConfirmStatusChange}>Confirm</Button>
                    ]} />
                </div>
            </div>
            <ConfirmationModal
                isVisible={isAddConfirmModalVisible}
                closeBtn={true}
                // onConfirm={handleConfirmAddTransporter}
                handleClose={handleCancelAddTrip}
                content={
                    <div className="addVehicleMain" >
                        <div>
                            <p style={{ margin: "0", fontSize: '20px', fontWeight: '500' }}>Add Trip</p>
                        </div>
                        <div className="responsiveHrLine" style={{ height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.06)', width: '100%' }}></div>
                        <div className="addTripModalContent" style={{ display: 'flex', width: '100%' }} >
                            <div className="addModalInputs">
                                <div className="vehicleStepsInput tripInputFill" style={{ display: 'flex' }}>
                                    <div >
                                        <p>Pickup Location <span style={{ color: '#FF4D4F' }}>*</span></p>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Choose Location"
                                            // style={{ color:'red'}}
                                            onChange={''}
                                            options={[
                                                { value: 'option1', label: 'Option 1' },
                                                { value: 'option2', label: 'Option 2' },
                                            ]}
                                        />
                                    </div>
                                    <div >
                                        <p>Delivery Location <span style={{ color: '#FF4D4F' }}>*</span></p>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Choose Location"
                                            // style={{ color:'red'}}
                                            onChange={''}
                                            options={[
                                                { value: 'option1', label: 'Option 1' },
                                                { value: 'option2', label: 'Option 2' },
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div className="vehicleStepsInput tripInputFill" style={{ display: 'flex' }}>
                                    <div>
                                        <p>Pickup Date <span style={{ color: '#FF4D4F' }}>*</span></p>
                                        <Input type="date" placeholder="Select" />
                                    </div>
                                    <div>
                                        <p>Pickup Time <span style={{ color: '#FF4D4F' }}>*</span></p>
                                        <Input type="time" placeholder="Select" />
                                    </div>
                                </div>
                                <div className="vehicleStepsInput tripInputFill" style={{ display: 'flex' }}>
                                    <div>
                                        <p>Weight <span style={{ color: '#FF4D4F' }}>*</span></p>
                                        <Input placeholder="Enter Weight" />
                                    </div>
                                    <div>
                                        <p>Customer Name <span style={{ color: '#FF4D4F' }}>*</span></p>
                                        <Input placeholder="Enter Name" />
                                    </div>
                                </div>
                                <div className="vehicleStepsInput tripInputFill" style={{ display: 'flex' }}>
                                    <div>
                                        <p>Phone Number <span style={{ color: '#FF4D4F' }}>*</span></p>
                                        <Input placeholder="Enter Phone Number" />
                                    </div>
                                    <div>
                                        <p>Vehicle <span style={{ color: '#FF4D4F' }}>*</span></p>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Choose Vehicle"
                                            // style={{ color:'red'}}
                                            onChange={''}
                                            options={[
                                                { value: 'option1', label: 'Option 1' },
                                                { value: 'option2', label: 'Option 2' },
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div className="vehicleStepsInput tripInputFill" style={{ display: 'flex' }}>
                                    <div>
                                        <p>Distance <span style={{ color: '#FF4D4F' }}>*</span></p>
                                        <Input placeholder="Enter Distance" />
                                    </div>
                                    <div>
                                        <p>Driver <span style={{ color: '#FF4D4F' }}>*</span></p>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Choose Driver"
                                            // style={{ color:'red'}}
                                            onChange={''}
                                            options={[
                                                { value: 'option1', label: 'Option 1' },
                                                { value: 'option2', label: 'Option 2' },
                                            ]}
                                        />
                                    </div>
                                </div>
                                <div className="vehicleStepsInput tripInputFill" style={{ display: 'flex' }}>
                                    <div>
                                        <p>Transporter <span style={{ color: '#FF4D4F' }}>*</span></p>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Choose transporter"
                                            // style={{ color:'red'}}
                                            onChange={''}
                                            options={[
                                                { value: 'option1', label: 'Option 1' },
                                                { value: 'option2', label: 'Option 2' },
                                            ]}
                                        />
                                    </div>
                                    <div>
                                        <p>Revenue <span style={{ color: '#FF4D4F' }}>*</span></p>
                                        <Input placeholder="Enter Revenue" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                } actions={[
                    <div className="addFooterMainDiv" style={{ display: 'flex', gap: '12px', marginLeft: 'auto', width: 'max-content' }}>
                        <Button
                            className="addModalFooterBtn"
                            style={{
                                // width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "black",
                                border: "1px solid rgba(89, 108, 148, 0.12)",
                            }}
                            key="cancel" onClick={handleCancelAddTrip}>Cancel</Button>
                        <Button
                            className="addModalFooterBtn"
                            style={{
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "white",
                                background: '#00C8F7',
                                border: "none",

                            }}
                            key="confirm" type="primary" onClick={() => {
                                // Close the modal
                                setIsAddConfirmModalVisible(false);
                                // Show SweetAlert
                                Swal.fire({
                                    html: `
                                        <div style="padding: 45px 30px 25px 30px">
                                            <img src="${successIcon}" alt="Success Icon"/>
                                            <p style="color: black; font-size: 20px; font-weight: 500;">Trip Added Successfully</p>
                                            <p style="color: rgba(0, 0, 0, 0.60); font-size: 16px; font-weight: 400;">Lorem ipsum dolor sit amet consectetur.<br/> Interdum diam amet tincidunt amet</p>
                                            <div id="customButtonContainer"></div>
                                        </div>
                                    `,
                                    showConfirmButton: false,
                                    didOpen: () => {
                                        const container = document.getElementById('customButtonContainer');
                                        if (container) {
                                            // Render the Ant Design button inside the container
                                            const button = document.createElement('div');
                                            container.appendChild(button);
                                            ReactDOM.render(
                                                <Button
                                                    style={{
                                                        width: '74px',
                                                        height: "32px",
                                                        textAlign: "center",
                                                        borderRadius: "15px",
                                                        color: "black",
                                                        paddingInline: '15px',
                                                        border: "1px solid rgba(89, 108, 148, 0.12)",
                                                    }}
                                                    onClick={() => Swal.close()}>
                                                    OK</Button>,
                                                button
                                            );
                                        }
                                    }
                                });
                            }}>Save now</Button>
                    </div>
                ]}
            />
        </div>
    );
}
