import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Badge,
  Dropdown,
  Menu,
  Pagination,
  Input,
  Select,
  Button,
  DatePicker,
  Spin,
} from "antd";
import Navbar from "../../Components/Navbar";
import { LuCheckCircle2 } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import HeaderTable from "../../Components/HeaderTable";
import "../NavlinksTable.css";
import { IoIosMore, IoMdCheckmark } from "react-icons/io";
import ViewButton from "../../Assets/ViewButton";
import DetailModal from "../../Assets/DetailModal";
import ConfirmationModal from "../../Assets/ConfirmationModal";
import DragDropInput from "../../Components/DragDropInput";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import successIcon from "../../Assets/Images/successIcon.png";
import MessageRes from "../../Assets/MessageRes";
import moment from "moment";
const ActionButton = ({ status }) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Active{" "}
          {status === "Active" ? (
            <IoMdCheckmark
              style={{
                color: "black",
                marginLeft: "20px",
                verticalAlign: "middle",
              }}
            />
          ) : null}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Inactive{" "}
          {status === "Inactive" ? (
            <IoMdCheckmark
              style={{
                color: "black",
                marginLeft: "20px",
                verticalAlign: "middle",
              }}
            />
          ) : null}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Edit
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="d-flex">
      <Dropdown overlay={menu}>
        <IoIosMore />
      </Dropdown>
    </div>
  );
};
const columns = [
  { title: "#", dataIndex: "no" },
  { title: "Company Name", dataIndex: "companyName" },
  { title: 'Address', dataIndex: 'address' },
  { title: "Contact Number", dataIndex: "contactNumber" },
  { title: "Email ID", dataIndex: "emailId" },
  { title: 'VAT Number', dataIndex: 'vatNumber' },
  { title: 'Reg Number', dataIndex: 'regNumber' },
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   render: (text) => (
  //     <span>
  //       {text === "ACTIVE" ? (
  //         <Badge
  //           count={
  //             <>
  //               <LuCheckCircle2
  //                 className="tabStatusIcon"
  //                 style={{
  //                   color: "green",
  //                   verticalAlign: "middle",
  //                   fontSize: "16px",
  //                 }}
  //               />{" "}
  //               Active
  //             </>
  //           }
  //         />
  //       ) : text === "Booked" ? (
  //         <Badge
  //           count={
  //             <>
  //               <LuCheckCircle2
  //                 className="tabStatusIcon"
  //                 style={{
  //                   color: "#00C8F7",
  //                   verticalAlign: "middle",
  //                   fontSize: "16px",
  //                 }}
  //               />{" "}
  //               Booked
  //             </>
  //           }
  //         />
  //       ) : (
  //         <Badge
  //           count={
  //             <>
  //               <RxCrossCircled
  //                 className="tabStatusIcon"
  //                 style={{
  //                   color: "red",
  //                   verticalAlign: "middle",
  //                   fontSize: "17px",
  //                 }}
  //               />{" "}
  //               Inactive
  //             </>
  //           }
  //         />
  //       )}
  //     </span>
  //   ),
  // },
  {
    title: "Action",
    key: "x",
    dataIndex: "status",
    render: (status) => <ActionButton status={status} />,
  },
];
const generateData = (transportersList) => {
  return transportersList.map((transporterData, index) => ({
    companyName: transporterData.name || '',
    address: `${transporterData.addressLine1 || ''} ${transporterData.addressLine2 || ''}`,
    contactNumber: transporterData.contactNumber?.toString() || '',
    emailId: transporterData.email || '',
    vatNumber: transporterData.vatNumber?.toString() || '',
    regNumber: transporterData.registrationNumber?.toString() || '',
    status: transporterData.status || '',
  }));
};

const CardView = ({ data, currentPage, pageSize, onViewDetails }) => {
  // const startIndex = (currentPage - 1) * pageSize;
  // const endIndex = startIndex + pageSize;
  const currentData = data;
  return (
    <div className="cardContainer">
      {currentData.length > 0 ? (
        currentData.map((item) => (
          <div key={item.no} className="card">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600px",
                  margin: "0 0 10px 0",
                }}
              >
                {item.companyName}
              </p>
              {item.status === "ACTIVE" ? (
                <Badge
                  count={
                    <>
                      <LuCheckCircle2
                        style={{
                          color: "green",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />{" "}
                      Active
                    </>
                  }
                />
              ) : item.status === "Booked" ? (
                <Badge
                  count={
                    <>
                      <LuCheckCircle2
                        style={{
                          color: "#00C8F7",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />{" "}
                      Booked
                    </>
                  }
                />
              ) : (
                <Badge
                  count={
                    <>
                      <RxCrossCircled
                        style={{
                          color: "#C01111",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />{" "}
                      Inactive
                    </>
                  }
                />
              )}
            </div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500px",
                margin: "10px 0 20px 0",
              }}
            >
              {" "}
              Address: {item.address}
            </p>
            <ViewButton onClick={() => onViewDetails(item)} />
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

const initFormData = {
  name: "",
  companyLogoUrl: null,
  tradeLicenseUrl: null,
  vatCertificateUrl: null,
  type: "",
  licenseExpiryDate: "",
  contactName: "",
  contactNumber: "",
  addressLine1: "",
  addressLine2: "",
  vatNumber: "",
  registrationNumber: "",
  email: "",
  password: "",
};
export default function Transporter() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [statusToChange, setStatusToChange] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [transportersList, setTransportersList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [closeBtn, setCloseBtn] = useState(window.innerWidth <= 765);
  const [isAddConfirmModalVisible, setIsAddConfirmModalVisible] =
    useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = transportersList.filter(
    (item) =>
      item.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.contactNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.emailId.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.vatNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.regNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.status.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = sessionStorage.getItem("token");
  const [formData, setFormData] = useState(initFormData);
  const [isValidStep, setIsValidStep] = useState(false);

  useEffect(() => {
    validateCurrentStep();
  }, [formData]);

  const validateCurrentStep = () => {
    const isStepValid =
      formData.name &&
      formData.companyLogoUrl &&
      formData.tradeLicenseUrl &&
      formData.vatCertificateUrl &&
      formData.type &&
      formData.licenseExpiryDate &&
      formData.contactName &&
      formData.contactNumber &&
      formData.addressLine1 &&
      formData.addressLine2 &&
      formData.vatNumber &&
      formData.registrationNumber &&
      formData.email &&
      formData.password;
    setIsValidStep(isStepValid);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  // Function to detect if the view is mobile
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Example breakpoint for mobile view
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Check the initial size
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setCloseBtn(window.innerWidth <= 765);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    fetchTransporterList(currentPage - 1, pageSize);
  }, [currentPage, pageSize]);
  const fetchTransporterList = async (page = 0, size = pageSize) => {
    const token = sessionStorage.getItem("token");
    const params = new URLSearchParams({
      page: page,
      size: size,
      sort: "createdDate,desc",
    });
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/transporters/list?${params.toString()}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setTransportersList(generateData(data.content));
        setTotalItems(data.page.totalElements);
      })
      .catch((error) => {
        console.error("Error fetching Data:", error);
        const errorMessage =
          error.response?.data?.message || "Error fetching Data";
        console.error("Error submitting data:", errorMessage);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the request completes
      });
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleViewDetails = (item) => {
    setModalItem(item);
    setIsModalVisible(true);
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalItem(null);
  };
  const handleStatusChange = (value) => {
    setStatusToChange(value);
    setIsConfirmModalVisible(true);
  };
  const handleConfirmStatusChange = () => {
    setModalItem({ ...modalItem, status: statusToChange });
    setIsConfirmModalVisible(false);
  };
  const handleCancelStatusChange = () => {
    setIsConfirmModalVisible(false);
  };
  // const handleConfirmAddTransporter = () => {
  //     setIsAddConfirmModalVisible(false);
  // };
  const handleAddTransporter = () => {
    setIsAddConfirmModalVisible(true);
  };
  const handleCancelAddTransporter = () => {
    setFormData(initFormData);
    setIsAddConfirmModalVisible(false);
  };
  ///////////
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const formattedValue = type === "number" ? Number(value) : value;
    const finalValue =
      name === "licenseExpiryDate"
        ? moment(formattedValue).format("YYYY-MM-DD")
        : formattedValue;
    setFormData({ ...formData, [name]: finalValue });
  };
  const handleDateChange = (date, dateString) => {
    setFormData({ ...formData, licenseExpiryDate: dateString });
  };
  const handleSelectChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };
  const handleFileChange = (name, file) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: file,
    }));
  };
  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/documents/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,
          },
        }
      );
      return response.data.url;
    } catch (error) {
      console.error("Error uploading image:", error);
      const errorMessage =
        error.response?.data?.message || "Error Uploading Image";
      MessageRes("error", "Upload Failed", errorMessage);
      throw error;
    }
  };
  const handleNext = async () => {
    try {
      // Create an array of upload promises
      const uploadPromises = Object.entries(formData).map(
        async ([key, value]) => {
          if (value instanceof File) {
            const url = await uploadImage(value);
            return [key, url];
          }
          return [key, value];
        }
      );

      // Wait for all uploads to complete in parallel
      const updatedEntries = await Promise.all(uploadPromises);
      const updatedFormData = Object.fromEntries(updatedEntries);

      // Submit the final formData
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/transporters`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );
      console.log("Data submitted successfully:", response.data);
      setFormData(initFormData);
      Swal.fire({
        html: `
                        <div style="padding: 45px 30px 25px 30px">
                            <img src="${successIcon}" alt="Success Icon"/>
                            <p style="color: black; font-size: 20px; font-weight: 500;">Transporter Added Successfully</p>
                            <p style="color: rgba(0, 0, 0, 0.60); font-size: 16px; font-weight: 400;">Lorem ipsum dolor sit amet consectetur.<br/> Interdum diam amet tincidunt amet</p>
                            <div id="customButtonContainer"></div>
                        </div>
                    `,
        showConfirmButton: false,
        didOpen: () => {
          const container = document.getElementById("customButtonContainer");
          if (container) {
            // Render the Ant Design button inside the container
            const button = document.createElement("div");
            container.appendChild(button);
            ReactDOM.render(
              <Button
                style={{
                  width: "74px",
                  height: "32px",
                  textAlign: "center",
                  borderRadius: "15px",
                  color: "black",
                  paddingInline: "15px",
                  border: "1px solid rgba(89, 108, 148, 0.12)",
                }}
                onClick={() => Swal.close()}
              >
                OK
              </Button>,
              button
            );
          }
        },
      });
      setIsAddConfirmModalVisible(false);
      fetchTransporterList();
      setCurrentPage(1);
    } catch (error) {
      console.error("Error submitting data:", error);
      const errorMessage =
        error.response?.data?.message || "Error submitting data";
      MessageRes("error", "Failed To Add", errorMessage);
    } finally {
      setIsSubmitting(false); // Hide spin
    }
  };
  return (
    <div
      style={{
        background: "rgba(230, 230, 230, 0.40)",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <Navbar showStatusInfo={false} />
      <div className="tableContainer" style={{ fontWeight: "600" }}>
        <HeaderTable
          title="Transporter"
          buttonText="Add Transporter"
          onSearchChange={handleSearchChange}
          onAddClick={handleAddTransporter}
          activeCount={
            transportersList.filter((v) => v.status === "ACTIVE").length
          }
          inactiveCount={
            transportersList.filter((v) => v.status === "INACTIVE").length
          }
          bookedCount={
            transportersList.filter((v) => v.status === "Booked").length
          }
        />
        <Spin size="large" spinning={loading}>
          <div
            className="bigScreenSize"
            style={{ color: "#747474 !important" }}
          >
            <Table
              style={{
                paddingInline: "20px",
                overflowX: "auto",
                borderRadius: "0 0 12px 12px",
                backgroundColor: "white",
              }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredData}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} items`,
                onChange: handlePageChange,
              }}
            />
          </div>
        </Spin>
        <div
          className="smallScreenSize"
          style={{ background: "#F7F8FB", padding: "0px 20px 20px 20px" }}
        >
          <CardView
            data={transportersList}
            currentPage={currentPage}
            pageSize={pageSize}
            onViewDetails={handleViewDetails}
          />
          <div className="mobilePagination">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalItems}
              showTotal={(total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`
              }
              onChange={handlePageChange}
              style={{ textAlign: "center" }}
            />
          </div>
          <DetailModal
            isVisible={isModalVisible}
            handleClose={handleCloseModal}
            content={
              modalItem && (
                <div>
                  <p
                    style={{ margin: "0", fontSize: "20px", fontWeight: "500" }}
                  >
                    Details
                  </p>
                  <span
                    style={{
                      display: "block",
                      height: "1px",
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.06)",
                      margin: "20px 0",
                    }}
                  ></span>
                  <span className="cardDetails">
                    <p>Company Name</p>
                    <p className="cardDetailHeading">{modalItem.companyName}</p>
                  </span>
                  {/* <span className="cardDetails">
                                <p>Address</p>
                                <p className="cardDetailHeading"> {modalItem.address}</p >
                            </span> */}
                  <span className="cardDetails">
                    <p>Contact Number</p>
                    <p className="cardDetailHeading">
                      {modalItem.contactNumber}
                    </p>
                  </span>
                  <span className="cardDetails">
                    <p>Email ID</p>
                    <p className="cardDetailHeading">{modalItem.emailId}</p>
                  </span>
                  {/* <span className="cardDetails">
                                <p>VAT Number</p>
                                <p className="cardDetailHeading">{modalItem.vatNumber}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Reg Number</p>
                                <p className="cardDetailHeading">{modalItem.regNumber}</p>
                            </span> */}
                  <span className="cardDetails">
                    <p>Change Status</p>
                    <Select
                      value={modalItem.status}
                      style={{ width: "fit-content" }}
                      onChange={handleStatusChange}
                    >
                      <Select.Option value="ACTIVE">
                        <Badge
                          count={
                            <>
                              <LuCheckCircle2
                                style={{
                                  color: "green",
                                  verticalAlign: "middle",
                                  fontSize: "16px",
                                }}
                              />{" "}
                              Active
                            </>
                          }
                        />
                      </Select.Option>
                      <Select.Option value="Inactive">
                        <Badge
                          count={
                            <>
                              <RxCrossCircled
                                style={{
                                  color: "#C01111",
                                  verticalAlign: "middle",
                                  fontSize: "16px",
                                }}
                              />{" "}
                              Inactive
                            </>
                          }
                        />
                      </Select.Option>
                      <Select.Option value="Booked">
                        <Badge
                          count={
                            <>
                              <LuCheckCircle2
                                style={{
                                  color: "#00C8F7",
                                  verticalAlign: "middle",
                                  fontSize: "16px",
                                }}
                              />{" "}
                              Booked
                            </>
                          }
                        />
                      </Select.Option>
                    </Select>
                  </span>
                </div>
              )
            }
          />
          <ConfirmationModal
            isVisible={isConfirmModalVisible}
            confirmModalMargin={"20%"}
            handleClose={handleCancelStatusChange}
            content={
              <div>
                <p
                  style={{
                    margin: "0 0 12px 0",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Are you sure?
                </p>
                <p>
                  Are You Sure You Want To Change The Status From{" "}
                  <strong>{modalItem?.status}</strong> To{" "}
                  <strong>{statusToChange}</strong>?
                </p>
              </div>
            }
            actions={[
              <Button
                style={{
                  marginTop: "30px",
                  width: "48%",
                  height: "32px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "black",
                  border: "1px solid rgba(89, 108, 148, 0.12)",
                }}
                key="cancel"
                onClick={handleCancelStatusChange}
              >
                Cancel
              </Button>,
              <Button
                style={{
                  marginTop: "30px",
                  width: "48%",
                  height: "32px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "white",
                  background: "#001B72",
                  border: "none",
                }}
                key="confirm"
                type="primary"
                onClick={handleConfirmStatusChange}
              >
                Confirm
              </Button>,
            ]}
          />
        </div>
      </div>
      <ConfirmationModal
        isVisible={isAddConfirmModalVisible}
        closeBtn={closeBtn}
        // onConfirm={handleConfirmAddTransporter}
        handleClose={handleCancelAddTransporter}
        content={
          <Spin size="large" spinning={isSubmitting}>
            <div className="addModalMain">
              <div className="addModalTitle">
                <p style={{ margin: "0", fontSize: "20px", fontWeight: "700" }}>
                  Transporter Onboarding
                </p>
              </div>
              <div
                className="addModalHrLine"
                style={{
                  height: "1px",
                  backgroundColor: "rgba(0, 0, 0, 0.06)",
                  width: "100%",
                  margin: "0px ",
                }}
              ></div>
              <div
                className="addModalContent"
                style={{ display: "flex", width: "100%" }}
              >
                <div className="antModalUploads">
                  <p>
                    Company Logo <span style={{ color: "#FF4D4F" }}>*</span>
                  </p>
                  <DragDropInput
                    DragWidth={isMobile ? "" : "25vw"}
                    onChange={(file) =>
                      handleFileChange("companyLogoUrl", file)
                    }
                  />
                  <p>
                    Trade License <span style={{ color: "#FF4D4F" }}>*</span>
                  </p>
                  <DragDropInput
                    DragWidth={isMobile ? "" : "25vw"}
                    onChange={(file) =>
                      handleFileChange("tradeLicenseUrl", file)
                    }
                  />
                  <p>
                    VAT Certificate <span style={{ color: "#FF4D4F" }}>*</span>
                  </p>
                  <DragDropInput
                    DragWidth={isMobile ? "" : "25vw"}
                    onChange={(file) =>
                      handleFileChange("vatCertificateUrl", file)
                    }
                  />
                </div>
                <div
                  className="addModalVrLine"
                  style={{
                    height: "auto",
                    backgroundColor: "rgba(0, 0, 0, 0.06)",
                    width: "1px",
                    margin: "55px 35px 0 33px",
                  }}
                ></div>
                <div className="addModalInputs">
                  <div
                    className="transportInputFill"
                    style={{ display: "flex" }}
                  >
                    <div>
                      <p>
                        Transporter Type{" "}
                        <span style={{ color: "#FF4D4F" }}>*</span>
                      </p>
                      <Select
                        className=""
                        placeholder="Choose Transporter Type"
                        onChange={(value) => handleSelectChange(value, "type")}
                        options={[
                          { value: "OTHER", label: "Others" },
                          { value: "option1", label: "Option 1" },
                          { value: "option2", label: "Option 2" },
                        ]}
                      />
                    </div>
                    <div>
                      <p>
                        Company Name <span style={{ color: "#FF4D4F" }}>*</span>
                      </p>
                      <Input
                        placeholder="Enter Name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div
                    className="transportInputFill"
                    style={{ display: "flex" }}
                  >
                    <div>
                      <p>
                        Contact Name <span style={{ color: "#FF4D4F" }}>*</span>
                      </p>
                      <Input
                        placeholder="Enter Contact Name"
                        name="contactName"
                        value={formData.contactName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <p>
                        Contact Number
                        <span style={{ color: "#FF4D4F" }}>*</span>
                      </p>
                      <Input
                        placeholder="Enter Contact Number"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div
                    className="transportInputFill"
                    style={{ display: "flex" }}
                  >
                    <div>
                      <p>
                        Address Line 1{" "}
                        <span style={{ color: "#FF4D4F" }}>*</span>
                      </p>
                      <Input
                        placeholder="Enter Address"
                        name="addressLine1"
                        value={formData.addressLine1}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <p>Address Line 2 (Optional)</p>
                      <Input
                        placeholder="Enter Address"
                        name="addressLine2"
                        value={formData.addressLine2}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div
                    className="transportInputFill"
                    style={{ display: "flex" }}
                  >
                    <div>
                      <p>
                        VAT Number <span style={{ color: "#FF4D4F" }}>*</span>
                      </p>
                      <Input
                        placeholder="Enter VAT Number"
                        name="vatNumber"
                        value={formData.vatNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <p>
                        Registration Number
                        <span style={{ color: "#FF4D4F" }}>*</span>
                      </p>
                      <Input
                        placeholder="Enter Registration Number"
                        name="registrationNumber"
                        value={formData.registrationNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div
                    className="transportInputFill"
                    style={{ display: "flex" }}
                  >
                    <div>
                      <p>
                        Email <span style={{ color: "#FF4D4F" }}>*</span>
                      </p>
                      <Input
                        type="email"
                        placeholder="Enter Email ID"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="addModalInputPassword">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p>
                          Password<span style={{ color: "#FF4D4F" }}>*</span>
                        </p>
                        <a style={{ color: "#001B72" }} href="#">
                          Generate
                        </a>
                      </div>
                      <Input.Password
                        placeholder="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div
                    className="transportInputFill"
                    style={{ display: "flex" }}
                  >
                    <div>
                      <p>
                        License Expiry Date{" "}
                        <span style={{ color: "#FF4D4F" }}>*</span>
                      </p>
                      <DatePicker
                        style={{ width: "100%" }}
                        value={
                          formData.licenseExpiryDate
                            ? moment(formData.licenseExpiryDate)
                            : null
                        }
                        onChange={handleDateChange}
                        name="licenseExpiryDate"
                        required
                        placeholder="Select Expiry Date"
                      />
                    </div>
                    <div className="addModalInputPassword"></div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        }
        actions={[
          <div
            className="addFooterMainDiv"
            style={{
              display: "flex",
              gap: "12px",
              marginLeft: "auto",
              width: "max-content",
            }}
          >
            <Button
              className="addModalFooterBtn"
              style={{
                // width: '48%',
                height: "32px",
                textAlign: "center",
                borderRadius: "50px",
                color: "black",
                border: "1px solid rgba(89, 108, 148, 0.12)",
              }}
              key="cancel"
              onClick={handleCancelAddTransporter}
            >
              Cancel
            </Button>
            <Button
              className="addModalFooterBtn"
              style={{
                height: "32px",
                textAlign: "center",
                borderRadius: "50px",
                color: "white",
                background: "#00C8F7",
                border: "none",
              }}
              disabled={!isValidStep}
              key="confirm"
              type="primary"
              onClick={handleNext}
            >
              Add now
            </Button>
          </div>,
        ]}
      />
    </div>
  );
}
