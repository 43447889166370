import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Badge,
  Dropdown,
  Menu,
  Pagination,
  Input,
  Select,
  Button,
  Spin,
} from "antd";
import Navbar from "../../Components/Navbar";
import { LuCheckCircle2 } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import HeaderTable from "../../Components/HeaderTable";
import "../NavlinksTable.css";
import { IoIosMore, IoMdCheckmark } from "react-icons/io";
import ViewButton from "../../Assets/ViewButton";
import DetailModal from "../../Assets/DetailModal";
import ConfirmationModal from "../../Assets/ConfirmationModal";
import DragDropInput from "../../Components/DragDropInput";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import successIcon from "../../Assets/Images/successIcon.png";
import MessageRes from "../../Assets/MessageRes";
const ActionButton = ({ status }) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Active{" "}
          {status === "Active" ? (
            <IoMdCheckmark
              style={{
                color: "black",
                marginLeft: "20px",
                verticalAlign: "middle",
              }}
            />
          ) : null}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Inactive{" "}
          {status === "Inactive" ? (
            <IoMdCheckmark
              style={{
                color: "black",
                marginLeft: "20px",
                verticalAlign: "middle",
              }}
            />
          ) : null}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Edit
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="d-flex">
      <Dropdown overlay={menu}>
        <IoIosMore />
      </Dropdown>
    </div>
  );
};

const columns = [
  { title: "#", dataIndex: "no" },
  { title: `Type Name`, dataIndex: "typeName" },
  { title: "Size", dataIndex: "size" },
  { title: "Capacity", dataIndex: "capacity" },
  // { title: 'Description', dataIndex: 'description' },
  // {
  //     title: 'Status',
  //     dataIndex: 'status',
  //     render: (text) => (
  //         <span>
  //             {text === 'Active' ? (
  //                 <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
  //             ) : text === 'Booked' ? (
  //                 <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
  //             ) : (
  //                 <Badge count={<><RxCrossCircled className="tabStatusIcon" style={{ color: 'red', verticalAlign: 'middle', fontSize: '17px' }} /> Inactive</>} />
  //             )}
  //         </span>
  //     ),
  // },
  {
    title: "Action",
    key: "x",
    dataIndex: "status",
    render: (status) => <ActionButton status={status} />,
  },
];

const generateData = (vehicleTypes) => {
  return vehicleTypes.map((vehicleType, index) => ({
    no: (index + 1).toString().padStart(2, "0"),
    typeName: vehicleType.name || "",
    size: vehicleType.key || "",
    capacity: (vehicleType.capacity || "") + " Ton",
    // description: 'Lorem ipsum dolor sit amet consectetur. Leo vel...',
    // status: status,
  }));
};
// const activeCount = statuses.filter(status => status === 'Active').length;
// const inactiveCount = statuses.filter(status => status === 'Inactive').length;
// const bookedCount = statuses.filter(status => status === 'Booked').length;
const CardView = ({ data, currentPage, pageSize, onViewDetails }) => {
  // const startIndex = (currentPage - 1) * pageSize;
  // const endIndex = startIndex + pageSize;
  const currentData = data;
  return (
    <div className="cardContainer">
      {currentData.length > 0 ? (
        currentData.map((item) => (
          <div key={item.no} className="card">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600px",
                  margin: "0 0 10px 0",
                }}
              >
                {item.typeName}
              </p>
              {item.status === "Active" ? (
                <Badge
                  count={
                    <>
                      <LuCheckCircle2
                        style={{
                          color: "green",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />{" "}
                      Active
                    </>
                  }
                />
              ) : item.status === "Booked" ? (
                <Badge
                  count={
                    <>
                      <LuCheckCircle2
                        style={{
                          color: "#00C8F7",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />{" "}
                      Booked
                    </>
                  }
                />
              ) : (
                <Badge
                  count={
                    <>
                      <RxCrossCircled
                        style={{
                          color: "#C01111",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />{" "}
                      Inactive
                    </>
                  }
                />
              )}
            </div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500px",
                margin: "10px 0 20px 0",
              }}
            >
              {item.description}
            </p>
            <ViewButton onClick={() => onViewDetails(item)} />
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

const initPayload = {
  key: "",
  name: "",
  capacity: 0,
};
export default function VehicleMaster() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [payload, setPayload] = useState(initPayload);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [statusToChange, setStatusToChange] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isAddConfirmModalVisible, setIsAddConfirmModalVisible] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = vehicleTypes.filter(
    (item) =>
      item.typeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.size.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.capacity?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [loading, setLoading] = useState(false);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  useEffect(() => {
    fetchVehicleTypeMaster(currentPage - 1, pageSize);
  }, [currentPage, pageSize]);
  const fetchVehicleTypeMaster = async (page=0, size=pageSize) => {
    const token = sessionStorage.getItem("token");
    const params = new URLSearchParams({
      page: page,
      size: size,
      sort: "createdDate,desc",
    });
    setLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/vehicle-types/list?${params.toString()}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setVehicleTypes(generateData(data.content));
        setTotalItems(data.page.totalElements);
      })
      .catch((error) => {
        console.error("Error fetching Data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the request completes
      });
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleViewDetails = (item) => {
    setModalItem(item);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalItem(null);
  };

  const handleStatusChange = (value) => {
    setStatusToChange(value);
    setIsConfirmModalVisible(true);
  };

  const handleConfirmStatusChange = () => {
    setModalItem({ ...modalItem, status: statusToChange });
    setIsConfirmModalVisible(false);
  };

  const handleCancelStatusChange = () => {
    setIsConfirmModalVisible(false);
  };
  const handleAddVehicleMaster = () => {
    setIsAddConfirmModalVisible(true);
  };
  const handleCancelAddVehicleMaster = () => {
    setIsAddConfirmModalVisible(false);
    setPayload(initPayload);
  };

  const successModal = () => {
    setIsAddConfirmModalVisible(false);
    Swal.fire({
      html: `
                              <div style="padding: 45px 30px 25px 30px">
                                  <img src="${successIcon}" alt="Success Icon"/>
                                  <p style="color: black; font-size: 20px; font-weight: 500;">Success</p>
                                  <p style="color: rgba(0, 0, 0, 0.60); font-size: 16px; font-weight: 400;">Vehicle Type Added Successfully</p>
                                  <div id="customButtonContainer"></div>
                              </div>
                          `,
      showConfirmButton: false,
      didOpen: () => {
        const container = document.getElementById("customButtonContainer");
        if (container) {
          // Render the Ant Design button inside the container
          const button = document.createElement("div");
          container.appendChild(button);
          ReactDOM.render(
            <Button
              style={{
                width: "74px",
                height: "32px",
                textAlign: "center",
                borderRadius: "15px",
                color: "black",
                paddingInline: "15px",
                border: "1px solid rgba(89, 108, 148, 0.12)",
              }}
              onClick={() => {
                Swal.close();
              }}
            >
              OK
            </Button>,
            button
          );
        }
      },
    });
  };

  return (
    <div
      style={{
        background: "rgba(230, 230, 230, 0.40)",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <Navbar showStatusInfo={false} />
      <div className="tableContainer" style={{ fontWeight: "600" }}>
        <HeaderTable
          title="Vehicle Type Master"
          buttonText="Add Master"
          onSearchChange={handleSearchChange}
          onAddClick={handleAddVehicleMaster}
          activeCount={vehicleTypes.filter((v) => v.status === "Active").length}
          inactiveCount={
            vehicleTypes.filter((v) => v.status === "Inactive").length
          }
          bookedCount={vehicleTypes.filter((v) => v.status === "Booked").length}
        />
        <Spin size="large" spinning={loading}>
          <div
            className="bigScreenSize"
            style={{ color: "#747474 !important" }}
          >
            <Table
              style={{
                paddingInline: "20px",
                overflowX: "auto",
                borderRadius: "0 0 12px 12px",
                backgroundColor: "white",
              }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredData}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} items`,
                onChange: handlePageChange,
              }}
            />
          </div>
        </Spin>
        <div
          className="smallScreenSize"
          style={{ background: "#F7F8FB", padding: "0px 20px 20px 20px" }}
        >
          <CardView
            data={vehicleTypes}
            currentPage={currentPage}
            pageSize={pageSize}
            onViewDetails={handleViewDetails}
          />
          <div className="mobilePagination">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalItems}
              showTotal={(total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`
              }
              onChange={handlePageChange}
              style={{ textAlign: "center" }}
            />
          </div>
          <DetailModal
            isVisible={isModalVisible}
            handleClose={handleCloseModal}
            content={
              modalItem && (
                <div>
                  <p
                    style={{ margin: "0", fontSize: "20px", fontWeight: "500" }}
                  >
                    Details
                  </p>
                  <span
                    style={{
                      display: "block",
                      height: "1px",
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.06)",
                      margin: "20px 0",
                    }}
                  ></span>
                  <span className="cardDetails">
                    <p>Type Name</p>
                    <p className="cardDetailHeading">{modalItem.typeName}</p>
                  </span>
                  <span className="cardDetails">
                    <p>Size</p>
                    <p className="cardDetailHeading"> {modalItem.size}</p>
                  </span>
                  <span className="cardDetails">
                    <p>Capacity</p>
                    <p className="cardDetailHeading">{modalItem.capacity}</p>
                  </span>
                  {/* <span className="cardDetails">
                                <p>Description</p>
                                <p className="cardDetailHeading" style={{ textAlign: 'right' }}>{modalItem.description}</p>
                            </span> */}
                  {/* <span className="cardDetails">
                                <p>Change Status</p>
                                <Select
                                    value={modalItem.status}
                                    style={{ width: 'fit-content' }}
                                    onChange={handleStatusChange}
                                >
                                    <Select.Option value="Active">
                                        <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                                    </Select.Option>
                                    <Select.Option value="Inactive">
                                        <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Inactive</>} />
                                    </Select.Option>
                                    <Select.Option value="Booked">
                                        <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
                                    </Select.Option>
                                </Select>
                            </span> */}
                </div>
              )
            }
          />
          <ConfirmationModal
            isVisible={isConfirmModalVisible}
            handleClose={handleCancelStatusChange}
            content={
              <div>
                <p
                  style={{
                    margin: "0 0 12px 0",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Are you sure?
                </p>
                <p>
                  Are You Sure You Want To Change The Status From{" "}
                  <strong>{modalItem?.status}</strong> To{" "}
                  <strong>{statusToChange}</strong>?
                </p>
              </div>
            }
            actions={[
              <Button
                style={{
                  marginTop: "30px",
                  width: "48%",
                  height: "32px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "black",
                  border: "1px solid rgba(89, 108, 148, 0.12)",
                }}
                key="cancel"
                onClick={handleCancelStatusChange}
              >
                Cancel
              </Button>,
              <Button
                style={{
                  marginTop: "30px",
                  width: "48%",
                  height: "32px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "white",
                  background: "#001B72",
                  border: "none",
                }}
                key="confirm"
                type="primary"
                onClick={handleConfirmStatusChange}
              >
                Confirm
              </Button>,
            ]}
          />
        </div>
      </div>
      <ConfirmationModal
        isVisible={isAddConfirmModalVisible}
        closeBtn={true}
        // onConfirm={handleConfirmAddTransporter}
        handleClose={handleCancelAddVehicleMaster}
        content={
          <div className="addVehicleMain addvehicleMasterMain">
            <div>
              <p style={{ margin: "0", fontSize: "20px", fontWeight: "500" }}>
                Add Vehicle Type Master
              </p>
            </div>
            <div
              className="responsiveHrLine"
              style={{
                height: "1px",
                backgroundColor: "rgba(0, 0, 0, 0.06)",
                width: "100%",
                margin: "20px 0",
              }}
            ></div>
            <div className="addTripModalContent" style={{ width: "100%" }}>
              <div>
                <p>
                  Key <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  onChange={(e) => {
                    setPayload({ ...payload, key: e.target.value });
                  }}
                  placeholder="Enter Vehicle Key"
                />
              </div>
              <div>
                <p>
                  Name <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  onChange={(e) => {
                    setPayload({ ...payload, name: e.target.value });
                  }}
                  placeholder="Enter Vehicle Name"
                />
              </div>
              <div>
                <p>
                  Capacity <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  onChange={(e) => {
                    setPayload({ ...payload, capacity: e.target.value });
                  }}
                  type="number"
                  placeholder="Enter Vehicle Capacity"
                />
              </div>
              {/* <div>
                                <p>Description <span style={{ color: '#FF4D4F' }}>*</span></p>
                                <Input placeholder="Enter Description" />
                            </div>
                            <div>
                                <p>Registration Certificate <span style={{ color: '#FF4D4F' }}>*</span></p>
                                <DragDropInput DragWidth={'100%'} />
                            </div> */}
            </div>
          </div>
        }
        actions={[
          <div
            className="addFooterMainDiv"
            style={{
              display: "flex",
              gap: "12px",
              marginLeft: "auto",
              width: "max-content",
            }}
          >
            <Button
              className="addModalFooterBtn"
              style={{
                // width: '48%',
                height: "32px",
                textAlign: "center",
                borderRadius: "50px",
                color: "black",
                border: "1px solid rgba(89, 108, 148, 0.12)",
              }}
              key="cancel"
              onClick={handleCancelAddVehicleMaster}
            >
              Cancel
            </Button>
            <Button
              className="addModalFooterBtn"
              disabled={!payload.capacity || !payload.name || !payload.key}
              style={{
                height: "32px",
                textAlign: "center",
                borderRadius: "50px",
                color: "white",
                background: "#00C8F7",
                border: "none",
              }}
              key="confirm"
              type="primary"
              onClick={async () => {
                // Close the modal
                try {
                  const token = sessionStorage.getItem("token");
                  await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/vehicle-types`,
                    payload,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `${token}`,
                      },
                    }
                  );
                  fetchVehicleTypeMaster()
                  setPayload(initPayload)
                  successModal();
                } catch (error) {
                  console.error("Error submitting data:", error);
                  const errorMessage =
                    error.response?.data?.message || "Error submitting data";
                  MessageRes("error", "Failed To Add", errorMessage);
                }
              }}
            >
              Save now
            </Button>
          </div>,
        ]}
      />
    </div>
  );
}
