import React, { useState } from 'react';
import axios from 'axios';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Input, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import MessageRes from '../Assets/MessageRes';

const Login = () => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
                userName,
                password
            });

            const { token, expiresIn, firstName, lastName } = response.data;
            const expirationTime = new Date().getTime() + expiresIn;
            sessionStorage.setItem('firstName', firstName.replace(/\s+/g, ''));
            sessionStorage.setItem('lastName', lastName.replace(/\s+/g, ''));
            sessionStorage.setItem('userName', userName.replace(/\s+/g, ''));

            sessionStorage.setItem('token', token);
            sessionStorage.setItem('expiresIn', expirationTime);
            // Navigate to the dashboard
            navigate('/dashboard');
        } catch (error) {
            console.error('Login failed:', error);
            const errorMessage = error.response?.data?.message || 'An unexpected error occurred';

            MessageRes('error', 'Login failed', errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className='LoginBg' style={{ width: '100vw', height: "100vh" }}>
                <div className='LoginDetail'>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={require('../Assets/Images/SwapLogo2.png')} alt="Logo" />
                        <h2 style={{ fontWeight: '500', margin: "20px auto 10px auto" }}>Welcome!</h2>
                        <span style={{ color: '#787878', fontSize: '16px', marginBottom: '35px' }}>To access your account, enter details.</span>
                    </div>
                    <form onSubmit={handleLogin}>
                        <div>
                            <h4 style={{ fontWeight: '500', margin: '5px 0' }}>
                                Username or Email Address
                            </h4>
                            <Input
                                style={{ height: '48px' }}
                                placeholder="Enter here"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                            <h4 style={{ fontWeight: '500', margin: '15px 0 5px 0 ' }}>
                                Password
                            </h4>
                            <Input.Password
                                style={{ height: '48px' }}
                                placeholder="Enter Password"
                                iconRender={(visible) => (visible ? <EyeTwoTone style={{ color: 'black' }} /> : <EyeInvisibleOutlined style={{ color: 'black' }} />)}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '14px 0 20px 0' }}>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="checkbox" />
                                <span style={{ fontSize: '15px' }}>Remember Me</span>
                            </span>
                            <a href='#'>
                                Forgot password?
                            </a>
                        </div>
                        <Button
                            htmlType="submit"
                            style={{ backgroundColor: '#001B72', color: 'white', width: '100%', height: '40px' }}
                            loading={loading}
                        >
                            Sign in
                        </Button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Login;
