import React, { useState, useEffect } from "react";
import { Input, Button, Badge, Checkbox, Select } from "antd";
import { IoFilter } from "react-icons/io5";
import { FiSearch, FiPlus } from "react-icons/fi";
import { LuDownload } from "react-icons/lu";
import { TbRefresh } from "react-icons/tb";
import "./HeaderTable.css";
import axios from "axios";

const HeaderTable = ({
  title,
  buttonText,
  onAddClick,
  icon,
  activeCount,
  inactiveCount,
  bookedCount,
  completedCount,
  pendingCount,
  cancelledCount,
  isRateProfile,
  onSearchChange,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [zones, setZones] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  // Function to detect if the view is mobile
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 900); // Example breakpoint for mobile view
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Check the initial size
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [selectedFilters, setSelectedFilters] = useState({
    active: false,
    inactive: false,
    booked: false,
    cancelled: false,
    completed: false,
    pending: false,
  });
  const [showFilters, setShowFilters] = useState(false);

  const handleMenuClick = (key) => {
    setSelectedFilters({ ...selectedFilters, [key]: !selectedFilters[key] });
  };

  const handleShowResults = () => {
    console.log("Show Results", selectedFilters);
    setShowFilters(false);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const fetchVehicleTypeMaster = async () => {
    const token = sessionStorage.getItem("token");
    const params = new URLSearchParams({
      page: 0,
      size: 100,
      sort: "createdDate,desc",
    });
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/vehicle-types/list?${params.toString()}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setVehicleTypes(data.content);
      })
      .catch((error) => {
        console.error("Error fetching Data:", error);
      });
  };

  const fetchZoneMaster = async () => {
    const token = sessionStorage.getItem("token");
    const params = new URLSearchParams({
      page: 0,
      size: 100,
      sort: "createdDate,desc",
    });
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/zones/list?${params.toString()}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setZones(data.content);
      })
      .catch((error) => {
        console.error("Error fetching Data:", error);
      });
  };

  useEffect(() => {
    if (isRateProfile) {
      fetchVehicleTypeMaster();
      fetchZoneMaster();
    }
  }, []);
  const FilterPopup = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: "40px",
          right: "0",
          backgroundColor: "white",
          borderRadius: "10px",
          width: "170px",
          boxShadow: " 0px 4px 30px 0px rgba(0, 0, 0, 0.15)",
          padding: "18px 15px",
          zIndex: 100,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <span style={{ color: "black", fontWeight: "400", fontSize: "14px" }}>
            Filter
          </span>
          <Button
            style={{ padding: "0", color: "#8692A6" }}
            type="link"
            onClick={() =>
              setSelectedFilters({
                active: false,
                inactive: false,
                booked: false,
              })
            }
          >
            Clear All
          </Button>
        </div>
        {activeCount ? (
          <div
            style={{
              display: "flex",
              marginBottom: "12px",
              justifyContent: "space-between",
            }}
          >
            <Checkbox
              checked={selectedFilters.active}
              onChange={() => handleMenuClick("active")}
            >
              <span
                style={{
                  color: selectedFilters.active ? "black" : "#8C8C8C",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                Active
              </span>
            </Checkbox>
            <span
              style={{ color: "#8C8C8C", fontWeight: "500", fontSize: "14px" }}
            >
              {activeCount}
            </span>
          </div>
        ) : null}

        {inactiveCount ? (
          <div
            style={{
              display: "flex",
              marginBottom: "12px",
              justifyContent: "space-between",
            }}
          >
            <Checkbox
              checked={selectedFilters.inactive}
              onChange={() => handleMenuClick("inactive")}
            >
              <span
                style={{
                  color: selectedFilters.inactive ? "black" : "#8C8C8C",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                Inactive
              </span>
            </Checkbox>
            <span
              style={{ color: "#8C8C8C", fontWeight: "500", fontSize: "14px" }}
            >
              {inactiveCount}
            </span>
          </div>
        ) : null}
        {bookedCount ? (
          <div
            style={{
              display: "flex",
              marginBottom: "12px",
              justifyContent: "space-between",
            }}
          >
            <Checkbox
              checked={selectedFilters.booked}
              onChange={() => handleMenuClick("booked")}
            >
              <span
                style={{
                  color: selectedFilters.booked ? "black" : "#8C8C8C",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                Booked
              </span>
            </Checkbox>
            <span
              style={{ color: "#8C8C8C", fontWeight: "500", fontSize: "14px" }}
            >
              {bookedCount}
            </span>
          </div>
        ) : null}
        {completedCount ? (
          <div
            style={{
              display: "flex",
              marginBottom: "12px",
              justifyContent: "space-between",
            }}
          >
            <Checkbox
              checked={selectedFilters.completed}
              onChange={() => handleMenuClick("completed")}
            >
              <span
                style={{
                  color: selectedFilters.completed ? "black" : "#8C8C8C",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                Completed
              </span>
            </Checkbox>
            <span
              style={{ color: "#8C8C8C", fontWeight: "500", fontSize: "14px" }}
            >
              {completedCount}
            </span>
          </div>
        ) : null}
        {pendingCount ? (
          <div
            style={{
              display: "flex",
              marginBottom: "12px",
              justifyContent: "space-between",
            }}
          >
            <Checkbox
              checked={selectedFilters.pending}
              onChange={() => handleMenuClick("pending")}
            >
              <span
                style={{
                  color: selectedFilters.pending ? "black" : "#8C8C8C",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                Pending
              </span>
            </Checkbox>
            <span
              style={{ color: "#8C8C8C", fontWeight: "500", fontSize: "14px" }}
            >
              {pendingCount}
            </span>
          </div>
        ) : null}
        {cancelledCount ? (
          <div
            style={{
              display: "flex",
              marginBottom: "12px",
              justifyContent: "space-between",
            }}
          >
            <Checkbox
              checked={selectedFilters.cancelled}
              onChange={() => handleMenuClick("cancelled")}
            >
              <span
                style={{
                  color: selectedFilters.cancelled ? "black" : "#8C8C8C",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                Cancelled
              </span>
            </Checkbox>
            <span
              style={{ color: "#8C8C8C", fontWeight: "500", fontSize: "14px" }}
            >
              {cancelledCount}
            </span>
          </div>
        ) : null}

        <Button
          shape="round"
          style={{
            backgroundColor: "black",
            color: "white",
            width: "100%",
            margin: "8px 0 10px 0",
          }}
          onClick={handleShowResults}
        >
          Show Results
        </Button>
      </div>
    );
  };
  let iconComponent;
  switch (icon) {
    case "download":
      iconComponent = <LuDownload style={{ fontSize: "15px" }} />;
      break;
    case "refresh":
      iconComponent = <TbRefresh style={{ fontSize: "15px" }} />;
      break;
    default:
      iconComponent = <FiPlus style={{ fontSize: "15px" }} />;
  }

  return (
    <>
      <div className="bigScreenSize">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: isRateProfile ? "0 20px": "20px",
            backgroundColor: "white",
            borderRadius: "12px 12px 0 0",
          }}
        >
          <p style={{ fontSize: "24px" }}>{title}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {isRateProfile ? null : (
              <>
                <Input
                  type="text"
                  placeholder="Search anything here..."
                  onChange={onSearchChange}
                  style={{
                    boxSizing: "border-box",
                    border: "1px solid rgba(89, 108, 148, 0.12)",
                    height: "38px",
                    padding: "0 12px",
                    borderRadius: "20px",
                    fontSize: "14px",
                    width: isMobile ? "200px" : "300px",
                  }}
                  prefix={
                    <FiSearch style={{ fontSize: "20px", color: "#787878" }} />
                  }
                />
                <span style={{ position: "relative" }}>
                  <Button
                    style={{
                      marginInline: "12px",
                      height: "36px",
                      width: "88px",
                      textAlign: "center",
                      borderRadius: "20px",
                      color: "black",
                      border: "1px solid rgba(89, 108, 148, 0.12)",
                    }}
                    type="default"
                    shape="round"
                    icon={
                      <IoFilter
                        style={{ fontSize: "15px", marginLeft: "20px" }}
                      />
                    }
                    onClick={toggleFilters}
                  >
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        position: "relative",
                      }}
                    >
                      Filter{" "}
                      <Badge
                        offset={[0, -22]}
                        count={1}
                        style={{ background: "#001B72" }}
                      />
                    </span>
                  </Button>
                  {showFilters && <FilterPopup />}
                </span>
              </>
            )}
            <Button
              style={{
                height: "36px",
                textAlign: "center",
                backgroundColor: "#00C8F7",
                borderRadius: "20px",
                color: "white",
                border: "none",
              }}
              type="default"
              shape="round"
              icon={iconComponent}
              onClick={onAddClick}
            >
              <span style={{ fontSize: "13px", fontWeight: "400" }}>
                {buttonText}
              </span>
            </Button>
          </div>
        </div>
        {isRateProfile ? (
          <div style={{ backgroundColor: "white", padding: "15px 17px" }}>
            <div className="rateProFilter">
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    margin: "6px 0",
                  }}
                >
                  From Zone <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Select
                  className="filterSelect"
                  style={{ width: "18vw" }}
                  placeholder="Choose Type"
                  onChange={(value) => onSearchChange("fromZoneId", value)}
                  options={zones.map((v) => ({ label: v.name, value: v.id }))}
                  allowClear
                />
              </div>
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    margin: "6px 0",
                  }}
                >
                  To Zone <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Select
                  className="filterSelect"
                  style={{ width: "18vw" }}
                  placeholder="Choose Type"
                  onChange={(value) => onSearchChange("toZoneId", value)}
                  options={zones.map((v) => ({ label: v.name, value: v.id }))}
                  allowClear
                />
              </div>
              <div>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    margin: "6px 0",
                  }}
                >
                  Vehicle Type <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Select
                  className="filterSelect"
                  style={{ width: "18vw" }}
                  placeholder="Choose Type"
                  onChange={(value) => onSearchChange("vehicleTypeId", value)}
                  options={vehicleTypes.map((v) => ({ label: v.name, value: v.id }))}
                  allowClear
                />
              </div>
              <div></div>
            </div>
          </div>
        ) : null}
      </div>
      <div
        className="smallScreenSize"
        style={{ background: "#F7F8FB", padding: "20px 20px 0 20px" }}
      >
        <div style={{ background: "#FFF", padding: "20px 15px 30px 15px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "20px" }}>{title}</p>
            <Button
              style={{
                height: "31px",
                textAlign: "center",
                backgroundColor: "#00C8F7",
                borderRadius: "20px",
                color: "white",
                border: "none",
              }}
              type="default"
              shape="round"
              icon={iconComponent}
              onClick={onAddClick}
            >
              <span style={{ fontSize: "12px", fontWeight: "400" }}>
                {buttonText}
              </span>
            </Button>
          </div>
          {isRateProfile ? (
            <div style={{ backgroundColor: "white" }}>
              <div className="rateProFilter">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      margin: "6px 0",
                    }}
                  >
                    From Zone <span style={{ color: "#FF4D4F" }}>*</span>
                  </p>
                  <Select
                    className="filterSelect"
                    placeholder="Choose Type"
                    style={{ width: "60%" }}
                    onChange={""}
                    options={[
                      { value: "option1", label: "Option 1" },
                      { value: "option2", label: "Option 2" },
                    ]}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      margin: "6px 0",
                    }}
                  >
                    To Zone <span style={{ color: "#FF4D4F" }}>*</span>
                  </p>
                  <Select
                    className="filterSelect"
                    placeholder="Choose Type"
                    style={{ width: "60%" }}
                    onChange={""}
                    options={[
                      { value: "option1", label: "Option 1" },
                      { value: "option2", label: "Option 2" },
                    ]}
                  />
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      margin: "6px 0",
                    }}
                  >
                    Vehicle Type <span style={{ color: "#FF4D4F" }}>*</span>
                  </p>
                  <Select
                    className="filterSelect"
                    placeholder="Choose Type"
                    style={{ width: "60%" }}
                    onChange={""}
                    options={[
                      { value: "option1", label: "Option 1" },
                      { value: "option2", label: "Option 2" },
                    ]}
                  />
                </div>
                <div></div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                type="text"
                placeholder="Search anything here..."
                style={{
                  boxSizing: "border-box",
                  border: "1px solid rgba(89, 108, 148, 0.12)",
                  height: "36px",
                  padding: "0 12px",
                  borderRadius: "20px",
                  fontSize: "14px",
                  width: "260px",
                }}
                prefix={
                  <FiSearch style={{ fontSize: "20px", color: "#787878" }} />
                }
              />
              <span style={{ position: "relative" }}>
                <Button
                  style={{
                    marginInline: "12px",
                    height: "36px",
                    width: "88px",
                    textAlign: "center",
                    borderRadius: "20px",
                    color: "black",
                    border: "1px solid rgba(89, 108, 148, 0.12)",
                  }}
                  type="default"
                  shape="round"
                  icon={
                    <IoFilter
                      style={{ fontSize: "15px", marginLeft: "20px" }}
                    />
                  }
                  onClick={toggleFilters}
                >
                  <span
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                      position: "relative",
                    }}
                  >
                    Filter{" "}
                    <Badge
                      offset={[0, -22]}
                      count={1}
                      style={{ background: "#001B72" }}
                    />
                  </span>
                </Button>
                {showFilters && <FilterPopup />}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderTable;
