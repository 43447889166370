import React, { useState } from "react";
import {
  CloseOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Tag, Slider } from "antd";
import { GrDeliver } from "react-icons/gr";
import { HiMiniSignal } from "react-icons/hi2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import "../Pages/dashboard.css";
import CamViewModal from "./CamViewModal";
function getGradientColor(percentage) {
  const startColor = [0, 20, 117];
  const endColor = [0, 200, 247];
  const midColor = startColor.map((start, i) => {
    const end = endColor[i];
    const delta = end - start;
    return (start + delta * percentage).toFixed(0);
  });
  return `rgb(${midColor.join(",")})`;
}

const CardModal = ({
  handleModalCancel,
  current = {},
  images = {},
  selectedMapyType,
}) => {
  const [value, setValue] = React.useState([0, 100]);
  const start = value[0] / 100;
  const end = value[value.length - 1] / 100;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const showModal = (image) => {
    setCurrentImage(image);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const toggleImage = (image) => {
    setCurrentImage(image);
  };
  return (
    <>
      {["trip", "container"].includes(selectedMapyType) && (
        <Card
          bordered={false}
          style={{
            backgroundColor: "black",
            margin: "0 0 15px 0",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Tag
              icon={<InfoCircleOutlined />}
              style={{
                backgroundColor: "#FFFFFF12",
                color: "white",
                border: ".2px solid #3A3A3A",
                borderRadius: "20px",
                width: "fit-content",
                textAlign: "center",
                height: "28px",
                fontSize: "14px",
                padding: "3px 10px",
              }}
            >
              {current?.container?.code}
            </Tag>
            <CloseOutlined
              className="ModalCloseBtn"
              type="default"
              style={{ float: "right", color: "#FFFFFF" }}
              onClick={handleModalCancel}
            />
          </div>
          <div
            style={{ display: "flex", height: "48px", margin: "15px 0 0 0" }}
          >
            <img
              src={require("../Assets/Images/maerskIcon.png")}
              alt="Maersk Icon"
            />
            <div style={{ height: "48px", margin: "0 15px" }}>
              <h3 style={{ color: "white", margin: "0" }}>
                {current?.container?.shippingLine?.name}
              </h3>
              <span style={{ color: "#52C41A" }}>Imported</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              height: "48px",
              margin: "25px 0 0 0",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <span
                style={{
                  height: "5px",
                  width: "90px",
                  display: "flex",
                  backgroundColor: "#3D3D3D",
                  borderRadius: "5px",
                  marginBottom: "5px",
                }}
              ></span>
              <span>Capacity</span>
              <h4 style={{ color: "white", margin: "0" }}>
                {current?.container?.capacity}
              </h4>
            </div>
            <div style={{ textAlign: "center" }}>
              <span
                style={{
                  height: "5px",
                  width: "90px",
                  display: "flex",
                  backgroundColor: "#3D3D3D",
                  borderRadius: "5px",
                  marginBottom: "5px",
                }}
              ></span>
              <span>Type</span>
              <h4 style={{ color: "white", margin: "0" }}>
                {current?.container?.type.replace("_", " ")}
              </h4>
            </div>
            <div style={{ textAlign: "center" }}>
              <span
                style={{
                  height: "5px",
                  width: "90px",
                  display: "flex",
                  backgroundColor: "#3D3D3D",
                  borderRadius: "5px",
                  marginBottom: "5px",
                }}
              ></span>
              <span>Weight</span>
              <h4 style={{ color: "white", margin: "0" }}>
                {current?.container?.weight}
              </h4>
            </div>
          </div>
        </Card>
      )}
      {["trip", "track"].includes(selectedMapyType) && (
        <Card
          bordered={true}
          style={{
            backgroundColor: "white",
            margin: "0 0 15px 0",
            color: "black",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Tag
              icon={<UserOutlined />}
              style={{
                color: "#00C8F7",
                border: ".2px solid #596C9425 ",
                boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
                borderRadius: "20px",
                width: "fit-content",
                textAlign: "center",
                height: "28px",
                fontSize: "14px",
                padding: "2px 12px",
              }}
            >
              {current?.driver?.userName}
            </Tag>
          </div>
          <div
            style={{
              display: "flex",
              height: "48px",
              margin: "15px 0 0 0",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <img src={require("../Assets/Images/User2.png")} alt="User" />
              <div>
                <h4 style={{ color: "black", margin: "0" }}>
                  {current?.driver?.firstName} {current?.driver?.lastName}
                </h4>
                <span style={{ color: "#8692A6" }}>
                  {current?.driver?.phone ? current?.driver?.phone : '-'}
                </span>
              </div>
            </div>
            <Button shape="round" size="small">
              <span style={{ fontWeight: "500" }}>Call Now</span>
            </Button>
          </div>
        </Card>
      )}
      {["trip", "track"].includes(selectedMapyType) && (
        <Card
          bordered={true}
          style={{
            backgroundColor: "white",
            margin: "0 0 15px 0",
            color: "black",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tag
              icon={
                <GrDeliver
                  style={{ verticalAlign: "middle", marginRight: "5px" }}
                />
              }
              style={{
                color: "#00C8F7",
                border: ".2px solid #596C9425 ",
                boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
                borderRadius: "20px",
                width: "fit-content",
                textAlign: "center",
                height: "28px",
                fontSize: "14px",
                padding: "2px 12px",
              }}
            >
              {current?.vehicle?.key}
            </Tag>
          </div>
          <div
            style={{
              display: "flex",
              height: "48px",
              margin: "15px 0 0 0",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <img src={require("../Assets/Images/Truck.png")} alt="Truck" />
              <div>
                <h4 style={{ color: "black", margin: "0" }}>
                  {current?.vehicle?.brand}{" "}
                  {current?.vehicle?.make}{" "}
                  {current?.vehicle?.model}
                </h4>
                <FontAwesomeIcon
                  icon={faCircle}
                  size={"2xs"}
                  style={{ color: "#000000", marginRight: "4px" }}
                />
                <span style={{ color: "#000000" }}>On Route</span>
              </div>
            </div>
          </div>
          {["trip"].includes(selectedMapyType) && (
            <>
              <hr
                style={{ borderTop: ".2px solid #dbdbdbb9", margin: "20px 0" }}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4 style={{ margin: "0" }}>Distance Traveled</h4>
                <span style={{ color: "#787878" }}>-</span>
              </div>
              <Slider
                range
                defaultValue={value}
                onChange={setValue}
                tooltip={{ open: false }}
                trackStyle={{
                  background: `linear-gradient(to right, ${getGradientColor(
                    start
                  )} 0%, ${getGradientColor(end)} 100%)`,
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "25px 5px 0 5px",
                  padding: "10px 15px",
                  backgroundColor: "#F5F5F5",
                  borderRadius: "12px",
                }}
              >
                <div>
                  <span style={{ color: "#747474" }}>Starting From</span>
                  <p style={{fontWeight:'500',  margin: "0", color: "#494949" }}>
                    {current?.trip?.request?.fromAddressLine1}
                    <br />
                    {current?.trip?.request?.fromAddressLine2}
                  </p>
                </div>
                <div>
                  <span style={{ color: "#747474" }}>Destination</span>
                  <h4 style={{fontWeight:'500',  margin: "0", color: "#494949" }}>
                    {current?.trip?.request?.toAddressLine1}
                    <br />
                    {current?.trip?.request?.toAddressLine2}
                  </h4>
                </div>
              </div>
            </>
          )}
        </Card>
      )}
      {["trip", "track"].includes(selectedMapyType) && (
        <Card
          bordered={true}
          style={{
            backgroundColor: "white",
            margin: "0 0 15px 0",
            color: "black",
          }}
          className="images-card"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Tag
              icon={
                <HiMiniSignal
                  style={{ verticalAlign: "middle", marginRight: "5px" }}
                />
              }
              style={{
                color: "red",
                border: ".2px solid #596C9425 ",
                boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.20)',
                borderRadius: "20px",
                width: "fit-content",
                textAlign: "center",
                height: "28px",
                fontSize: "14px",
                padding: "2px 10px 0 10px",
              }}
            >
              Live Cam
            </Tag>
          </div>
          <div
            className="camViewDiv"
            style={{
              display: "flex",
              margin: "15px 0 0 0",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div className="image-container" onClick={() => showModal("Front")}>
              <img
                className="camViewImg"
                style={{ borderRadius: "12px", cursor: "pointer" }}
                src={images?.frontImage}
                //   src={require("./Images/Driver.png")}
                alt="Driver"
              />
              <span className="full-view-text">Full view</span>
            </div>
            <div className="image-container" onClick={() => showModal("Back")}>
              <img
                className="camViewImg2"
                style={{ borderRadius: "12px", cursor: "pointer" }}
                src={images?.backImage}
                //   src={require("./Images/FrontCam.png")}
                alt="Front Cam"
              />
              <span className="full-view-text">Full view</span>
            </div>
          </div>
        </Card>
      )}
      <CamViewModal
        isVisible={isModalVisible}
        handleCancel={handleCancel}
        currentImage={currentImage}
        toggleImage={toggleImage}
        images={images}
      />
    </>
  );
};

export default CardModal;
