import React, { useState } from "react";
import { Table, Badge, Dropdown, Menu, Pagination, Select, Button } from 'antd';
import Navbar from "../Components/Navbar";
import { LuCheckCircle2, LuMinusCircle } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import HeaderTable from '../Components/HeaderTable';
import './NavlinksTable.css';
import { IoIosMore, IoMdCheckmark } from "react-icons/io";
import ViewButton from "../Assets/ViewButton";
import DetailModal from "../Assets/DetailModal";
import ConfirmationModal from "../Assets/ConfirmationModal";
import { HiOutlineExclamationCircle } from "react-icons/hi";

const ActionButton = ({ status }) => {
    const menu = (
        <Menu>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">
                    Active {status === 'Active' ? <IoMdCheckmark style={{ color: 'black', marginLeft: '20px', verticalAlign: 'middle' }} /> : null}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">
                    Inactive {status === 'Inactive' ? <IoMdCheckmark style={{ color: 'black', marginLeft: '20px', verticalAlign: 'middle' }} /> : null}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">Edit</a>
            </Menu.Item>
        </Menu>
    );
    return (
        <div className="d-flex">
            <Dropdown overlay={menu}>
                <IoIosMore />
            </Dropdown>
        </div>
    );
};
const DetailsIcon = (
    <HiOutlineExclamationCircle style={{ fontSize: '17px', verticalAlign: 'middle', color: '#8692A6', marginLeft: '3px', transform: 'rotate(180deg)' }} />
)
const columns = [
    { title: '#', dataIndex: 'no' },
    { title: 'Date', dataIndex: 'date' },
    { title: 'Trip ID', dataIndex: 'tripId' },
    { title: 'Pickup', dataIndex: 'pickup' },
    { title: 'Delivery', dataIndex: 'delivery' },
    { title: 'Customer', dataIndex: 'customer' },
    { title: 'Vehicle', dataIndex: 'vehicle' },
    { title: 'Driver', dataIndex: 'driver' },
    { title: 'Start Time', dataIndex: 'startTime' },
    { title: 'End Time', dataIndex: 'endTime' },
    { title: 'Duration', dataIndex: 'duration' },
    { title: 'Distance', dataIndex: 'distance' },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (text) => (
            <span>
                {text === 'Active' ? (
                    <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                ) : text === 'Cancelled' ? (
                    <Badge count={<><RxCrossCircled className="tabStatusIcon" style={{ color: 'red', verticalAlign: 'middle', fontSize: '17px' }} /> Cancelled</>} />
                ) : text === 'Completed' ? (
                    <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Completed</>} />
                ) : (
                    <Badge count={<><LuMinusCircle style={{ color: '#FEA800', verticalAlign: 'middle', fontSize: '17px' }} /> Pending</>} />
                )}
            </span>
        ),
    },
    {
        title: 'Action',
        key: 'x',
        dataIndex: 'status',
        render: (status) => <ActionButton status={status} />,
    },
];


const generateData = (startTime, endTime, duration, statuses) => {
    return statuses.map((status, index) => ({
        no: (index + 1).toString().padStart(2, '0'),
        date: '18 Feb, 2024',
        tripId: 'Jebel ali free zone',
        pickup: 'ST0001',
        delivery: 'Abu Dhabi',
        customer: 'Abhijith S',
        vehicle: (
            <>
                <span className="bigScreenSize">
                    F54329 {DetailsIcon}
                </span>
                <span className="smallScreenSize">
                    F54329
                </span>
            </>
        ),
        driver: (
            <>
                <span className="">
                    Manjindar {DetailsIcon}
                </span>
            </>
        ),
        startTime: startTime[index],
        endTime: endTime[index],
        duration: duration[index],
        distance: '300KM', // Placeholder data, replace with actual distance data if available
        status: status,
    }));
};
const statuses = [
    'Completed',
    'Cancelled',
    'Pending',
    'Active',
    'Pending',
    'Active',
    'Cancelled',
    'Active',
    'Active',
    'Completed',
    'Completed',
];
const startTime = [
    '09:10 AM',
    '-',
    '09:10 AM',
    '09:10 AM',
    '09:10 AM',
    '09:10 AM',
    '-',
    '09:10 AM',
    '09:10 AM',
    '09:10 AM',
    '09:10 AM',
];
const endTime = [
    '04:10 AM',
    '-',
    '-',
    '04:10 AM',
    '-',
    '04:10 AM',
    '-',
    '04:10 AM',
    '04:10 AM',
    '04:10 AM',
    '04:10 AM',
];
const duration = [
    '07:00H',
    '-',
    '07:00H',
    '07:00H',
    '-',
    '07:00H',
    '-',
    '07:00H',
    '07:00H',
    '07:00H',
    '07:00H',
];
const data = generateData(startTime, endTime, duration, statuses);
const activeCount = statuses.filter(status => status === 'Active').length;
const cancelledCount = statuses.filter(status => status === 'Cancelled').length;
const completedCount = statuses.filter(status => status === 'Completed').length;
const pendingCount = statuses.filter(status => status === 'Pending').length;
const CardView = ({ data, currentPage, pageSize, onViewDetails }) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentData = data.slice(startIndex, endIndex);

    return (
        <div className="cardContainer">
            {currentData.map(item => (
                <div key={item.no} className="card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontSize: '18px', fontWeight: '600px', margin: '0 0 10px 0' }}>{item.customer}</p>
                        {item.status === 'Active' ? (
                            <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                        ) : item.status === 'Completed' ? (
                            <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Completed</>} />
                        ) : item.status === 'Cancelled' ? (
                            <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Cancelled</>} />
                        ) : (
                            <Badge count={<><LuMinusCircle style={{ color: '#FEA800', verticalAlign: 'middle', fontSize: '17px' }} /> Pending</>} />
                        )
                        }
                    </div>
                    <p style={{ fontSize: '14px', fontWeight: '500px', margin: '10px 0 20px 0' }}>{item.delivery}</p>
                    <ViewButton onClick={() => onViewDetails(item)} />
                </div>
            ))}
        </div>
    );
};
export default function Reports() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [currentPageMobile, setCurrentPageMobile] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalItem, setModalItem] = useState(null);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [statusToChange, setStatusToChange] = useState(null);
    const pageSize = 5;
    const [searchTerm, setSearchTerm] = useState("");
    const filteredData = data.filter(item =>
        item.date.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.tripId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.pickup.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.delivery.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
        // item.vehicle.toLowerCase().includes(searchTerm.toLowerCase()) ||
        // item.driver.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.startTime.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.endTime.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.duration.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.status.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handlePageChangeMobile = (page) => {
        setCurrentPageMobile(page);
    };

    const handleViewDetails = (item) => {
        setModalItem(item);
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setModalItem(null);
    };

    const handleStatusChange = (value) => {
        setStatusToChange(value);
        setIsConfirmModalVisible(true);
    };

    const handleConfirmStatusChange = () => {
        setModalItem({ ...modalItem, status: statusToChange });
        setIsConfirmModalVisible(false);
    };

    const handleCancelStatusChange = () => {
        setIsConfirmModalVisible(false);
    };

    return (
        <div style={{ background: 'rgba(230, 230, 230, 0.40)', height: '100%', overflow: 'scroll' }}>
            <Navbar showStatusInfo={false} />
            <div className="tableContainer" style={{ fontWeight: '600' }}>
                <HeaderTable
                    title="Reports"
                    buttonText="Download PDF"
                    icon="download"
                    onAddClick={() => console.log('Download Reports PDF button clicked')}
                    activeCount={activeCount}
                    cancelledCount={cancelledCount}
                    completedCount={completedCount}
                    pendingCount={pendingCount}
                    onSearchChange={handleSearchChange}
                />
                <div className="bigScreenSize" style={{ color: '#747474 !important' }}>
                    <Table
                        className="reportsCustomTable"
                        style={{ paddingInline: '20px', overflowX: 'auto', borderRadius: '0 0 12px 12px', backgroundColor: 'white' }}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredData}
                        pagination={{
                            total: 100,
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of 892 items`,
                            defaultPageSize: 11,
                            defaultCurrent: 1,
                        }}
                    />
                </div>
                <div className="smallScreenSize" style={{ background: '#F7F8FB', padding: '0px 20px 20px 20px' }} >
                    <CardView data={data} currentPage={currentPageMobile} pageSize={pageSize} onViewDetails={handleViewDetails} />
                    <div className="mobilePagination">
                        <Pagination
                            current={currentPageMobile}
                            pageSize={pageSize}
                            total={data.length}
                            onChange={handlePageChangeMobile}
                            style={{ textAlign: 'center' }}
                        />
                    </div>
                    <DetailModal isVisible={isModalVisible} handleClose={handleCloseModal} content={modalItem && (
                        <div>
                            <p style={{ margin: "0", fontSize: '20px', fontWeight: '500' }}>Details</p>
                            <span style={{ display: 'block', height: '1px', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.06)', margin: '20px 0' }}></span>
                            <span className="cardDetails">
                                <p>Date</p>
                                <p className="cardDetailHeading">{modalItem.date}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Trip ID</p>
                                <p className="cardDetailHeading"> {modalItem.tripId}</p >
                            </span>
                            <span className="cardDetails">
                                <p>Pickup</p>
                                <p className="cardDetailHeading"> {modalItem.pickup}</p >
                            </span>
                            <span className="cardDetails">
                                <p>Delivery</p>
                                <p className="cardDetailHeading">{modalItem.delivery}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Customer</p>
                                <p className="cardDetailHeading">{modalItem.customer}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Vehicle</p>
                                <p className="cardDetailHeading">{modalItem.vehicle}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Driver</p>
                                <p className="cardDetailHeading">{modalItem.driver}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Start Time</p>
                                <p className="cardDetailHeading">{modalItem.startTime}</p>
                            </span>
                            <span className="cardDetails">
                                <p>End Time</p>
                                <p className="cardDetailHeading">{modalItem.endTime}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Duration</p>
                                <p className="cardDetailHeading">{modalItem.duration}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Distance</p>
                                <p className="cardDetailHeading">{modalItem.distance}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Change Status</p>
                                <Select
                                    value={modalItem.status}
                                    style={{ width: 'fit-content' }}
                                    onChange={handleStatusChange}
                                >
                                    <Select.Option value="Active">
                                        <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                                    </Select.Option>
                                    <Select.Option value="Cancelled">
                                        <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Cancelled</>} />
                                    </Select.Option>
                                    <Select.Option value="Completed">
                                        <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Completed</>} />
                                    </Select.Option>
                                    <Select.Option value="Pending">
                                        <Badge count={<><LuMinusCircle style={{ color: '#FEA800', verticalAlign: 'middle', fontSize: '16px' }} /> Pending</>} />
                                    </Select.Option>
                                </Select>
                            </span>
                        </div>
                    )} />
                    <ConfirmationModal isVisible={isConfirmModalVisible} handleClose={handleCancelStatusChange} content={
                        <div>
                            <p style={{ margin: "0 0 12px 0", fontSize: '20px', fontWeight: '500' }}>Are you sure?</p>
                            <p>Are You Sure You Want To Change The Status From <strong>{modalItem?.status}</strong> To <strong>{statusToChange}</strong>?</p>
                        </div>
                    } actions={[
                        <Button
                            style={{
                                marginTop: '30px',
                                width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "black",
                                border: "1px solid rgba(89, 108, 148, 0.12)",
                            }}
                            key="cancel" onClick={handleCancelStatusChange}>Cancel</Button>,
                        <Button
                            style={{
                                marginTop: '30px',
                                width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "white",
                                background: '#001B72',
                                border: "none",
                            }}
                            key="confirm" type="primary" onClick={handleConfirmStatusChange}>Confirm</Button>
                    ]} />
                </div>
            </div>
        </div>
    );
}
