import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../Components/Navbar";
import HeaderTable from "../../Components/HeaderTable";
import "../NavlinksTable.css";
import { IoIosMore, IoMdCheckmark } from "react-icons/io";
import ViewButton from "../../Assets/ViewButton";
import DetailModal from "../../Assets/DetailModal";
import ConfirmationModal from "../../Assets/ConfirmationModal";
import {
  Table,
  Dropdown,
  Menu,
  Spin,
  Pagination,
  Select,
  Button,
  Steps,
  Input,
  DatePicker,
  Checkbox,
} from "antd";
import { FiPlus } from "react-icons/fi";
import DragDropInput from "../../Components/DragDropInput";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import successIcon from "../../Assets/Images/successIcon.png";

import MessageRes from "../../Assets/MessageRes";
import moment from "moment";
const ActionButton = ({ status }) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Active{" "}
          {status === "Active" ? (
            <IoMdCheckmark
              style={{
                color: "black",
                marginLeft: "20px",
                verticalAlign: "middle",
              }}
            />
          ) : null}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Inactive{" "}
          {status === "Inactive" ? (
            <IoMdCheckmark
              style={{
                color: "black",
                marginLeft: "20px",
                verticalAlign: "middle",
              }}
            />
          ) : null}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Edit
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="d-flex">
      <Dropdown overlay={menu}>
        <IoIosMore />
      </Dropdown>
    </div>
  );
};
const columns = [
  { title: "Reg Number", dataIndex: "regNumber" },
  { title: `Model`, dataIndex: "model" },
  { title: "Capacity", dataIndex: "capacity" },
  { title: "Make", dataIndex: "make" },
  { title: "Type", dataIndex: "type" },
  { title: "Phone No.", dataIndex: "phoneNumber" },
  { title: "Brand", dataIndex: "brand" },
  { title: "Manufacture Year", dataIndex: "manufactureYear" },
  { title: "Expiry ", dataIndex: "expiryDate" },
  {
    title: "Action",
    key: "x",
    dataIndex: "status",
    render: (status) => <ActionButton status={status} />,
  },
];
const generateData = (vehicles) => {
  return vehicles.map((vehicle, index) => ({
    regNumber: vehicle.registrationNumber || "",
    model: vehicle.model || "",
    capacity: (vehicle.capacity || "") + " Ton",
    make: vehicle.make || "",
    type: vehicle.type?.name || "",
    phoneNumber: vehicle.phoneNumber || "",
    brand: vehicle.brand || "",
    manufactureYear: vehicle.manufactureYear || "",
    expiryDate: vehicle.expiryDate || "",
  }));
};
const CardView = ({ data, currentPage, pageSize, onViewDetails }) => {
  // const startIndex = (currentPage - 1) * pageSize;
  // const endIndex = startIndex + pageSize;
  const currentData = data;
  return (
    <div className="cardContainer">
      {currentData.length > 0 ? (
        currentData.map((item) => (
          <div key={item.no} className="card">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600px",
                  margin: "0 0 10px 0",
                }}
              >
                {item.model}
              </p>
            </div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500px",
                margin: "10px 0 20px 0",
              }}
            >
              {item.regNumber}
            </p>
            <ViewButton onClick={() => onViewDetails(item)} />
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};
///////////
const { Step } = Steps;
///////////

const initFormData = {
  registrationNumber: "",
  expiryDate: "",
  transporterId: null,
  phoneNumber: "",
  brand: "",
  vehicleTypeId: "",
  make: "Toyota",
  model: "",
  manufactureYear: null,
  capacity: null,
  registrationCertificateUrl: null,
  insuranceCertificateUrl: null,
  inspectionCertificateUrl: null,
  vehicleImage: null,
  driverId: null,
}

export default function Vehicles() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [statusToChange, setStatusToChange] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [vehicles, setVehicles] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isAddConfirmModalVisible, setIsAddConfirmModalVisible] =
    useState(false);
  const [current, setCurrent] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = vehicles.filter(
    (item) =>
      item.regNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.model.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.emailId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.capacity.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = sessionStorage.getItem("token");
  const [formData, setFormData] = useState(initFormData);
  const [isValidStep, setIsValidStep] = useState([false, false, false]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  useEffect(() => {
    fetchVehicleType();
  }, []);

  const fetchVehicleType = async () => {
    const token = sessionStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/vehicle-types/list`, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.content.map(item => ({
          value: item.id,
          label: item.name,
        }));
        setVehicleTypes(options);
      })
      .catch((error) => {
        console.error("Error fetching Data:", error);
      });
  };
  useEffect(() => {
    validateCurrentStep();
  }, [formData, current]);

  const validateCurrentStep = () => {
    const stepValidations = [
      // Step 1 validation
      formData.registrationNumber &&
      formData.expiryDate &&
      formData.transporterId &&
      formData.phoneNumber &&
      formData.brand &&
      formData.vehicleTypeId &&
      formData.model &&
      formData.manufactureYear &&
      formData.capacity,

      // Step 2 validation
      formData.registrationCertificateUrl &&
      formData.insuranceCertificateUrl &&
      formData.inspectionCertificateUrl &&
      formData.vehicleImage,

      // Step 3 validation
      true,
      //   formData.driverId,
    ];
    setIsValidStep(stepValidations);
  };

  useEffect(() => {
    fetchVehicles(currentPage - 1, pageSize);
  }, [currentPage, pageSize]);

  const fetchVehicles = async (page = 0, size = pageSize) => {
    const params = new URLSearchParams({
      page: page,
      size: size,
      sort: "createdDate,desc",
    });
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL
        }/vehicles/list?${params.toString()}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setVehicles(generateData(data.content));
        setTotalItems(data.page.totalElements);
      })
      .catch((error) => {
        console.error("Error fetching locations:", error);
      }).finally(() => {
        setLoading(false); // Set loading to false after the request completes
      });
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleViewDetails = (item) => {
    setModalItem(item);
    setIsModalVisible(true);
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalItem(null);
  };
  // const handleStatusChange = (value) => {
  //     setStatusToChange(value);
  //     setIsConfirmModalVisible(true);
  // };
  const handleConfirmStatusChange = () => {
    setModalItem({ ...modalItem, status: statusToChange });
    setIsConfirmModalVisible(false);
  };
  const handleCancelStatusChange = () => {
    setIsConfirmModalVisible(false);
  };
  const handleAddVehicle = () => {
    setIsAddConfirmModalVisible(true);
  };
  const handleCancelAddVehicle = () => {
    setFormData(initFormData)
    setCurrent(0);
    setIsAddConfirmModalVisible(false);
  };
  //////////////
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const formattedValue = type === 'number' ? Number(value) : value;
    const finalValue = name === 'expiryDate' ? moment(formattedValue).format('YYYY-MM-DD') : formattedValue;
    setFormData({ ...formData, [name]: finalValue });
  };

  const handleDateChange = (date, dateString) => {
    setFormData({ ...formData, expiryDate: dateString });
  };
  const handleSelectChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };
  const handleFileChange = (name, file) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: file,
    }));
  };
  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/documents/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,
          },
        }
      );
      return response.data.url;
    } catch (error) {
      console.error("Error uploading image:", error);
      const errorMessage =
        error.response?.data?.message || "Error Uploading Image";
      MessageRes("error", "Upload Failed", errorMessage);
      throw error;
    }
  };
  const handleNext = async () => {
    if (current === steps.length - 1) {
      try {
        // Create an array of upload promises
        const uploadPromises = Object.entries(formData).map(
          async ([key, value]) => {
            if (value instanceof File) {
              const url = await uploadImage(value);
              return [key, url];
            }
            return [key, value];
          }
        );

        // Wait for all uploads to complete in parallel
        const updatedEntries = await Promise.all(uploadPromises);
        const updatedFormData = Object.fromEntries(updatedEntries);

        // Submit the final formData
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/vehicles`,
          updatedFormData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          }
        );
        setFormData(initFormData)
        Swal.fire({
          html: `<div style="padding: 45px 30px 25px 30px">
                <img src="${successIcon}" alt="Success Icon"/>
                <p style="color: black; font-size: 20px; font-weight: 500;">Success</p>
                <p style="color: rgba(0, 0, 0, 0.60); font-size: 16px; font-weight: 400;">Vehicle Added Successfully</p>
                <div id="customButtonContainer"></div>
            </div>`,
          showConfirmButton: false,
          didOpen: () => {
            const container = document.getElementById("customButtonContainer");
            if (container) {
              // Render the Ant Design button inside the container
              const button = document.createElement("div");
              container.appendChild(button);
              ReactDOM.render(
                <Button
                  style={{
                    width: "74px",
                    height: "32px",
                    textAlign: "center",
                    borderRadius: "15px",
                    color: "black",
                    paddingInline: "15px",
                    border: "1px solid rgba(89, 108, 148, 0.12)",
                  }}
                  onClick={() => {
                    Swal.close();
                  }}
                >
                  OK
                </Button>,
                button
              );
            }
          },
        });
        setIsAddConfirmModalVisible(false);
        fetchVehicles();
        setCurrent(0);
        setCurrentPage(1);
      } catch (error) {
        console.error("Error submitting data:", error);
        const errorMessage =
          error.response?.data?.message || "Error submitting data";
        MessageRes("error", "Failed To Add", errorMessage);
      } finally {
        setIsSubmitting(false); // Hide spin
      }
    } else {
      setCurrent(current + 1);
    }
  };
  const steps = [
    {
      title: "Details",
      content: (
        <>
          <div>
            <p style={{ marginTop: "0" }}>
              Register No <span style={{ color: "#FF4D4F" }}>*</span>
            </p>
            <Input
              placeholder="Enter Number"
              name="registrationNumber"
              value={formData.registrationNumber}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <div className="vehicleStepsInput">
              <div>
                <p>
                  Expiry Date <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <DatePicker
                  style={{ width: '100%' }}
                  value={formData.expiryDate
                    ? moment(formData.expiryDate)
                    : null}
                  onChange={handleDateChange}
                  name="expiryDate"
                  required
                  placeholder="Select"
                />
              </div>
              <div>
                <p>
                  Transporter<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Choose Transporter"
                  onChange={(value) =>
                    handleSelectChange(value, "transporterId")
                  }
                  options={[
                    { value: 1, label: "Option 1" },
                    { value: 2, label: "Option 2" },
                  ]}
                />
              </div>
            </div>
            <div className="vehicleStepsInput">
              <div>
                <p>
                  Phone Number<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <p>
                  Brand<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Brand"
                  name="brand"
                  value={formData.brand}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="vehicleStepsInput">
              <div>
                <p>
                  Type<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Choose Type"
                  onChange={(value) => handleSelectChange(value, "vehicleTypeId")}
                  options={vehicleTypes}
                />
              </div>
              <div>
                <p>
                  Model<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Model"
                  name="model"
                  value={formData.model}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="vehicleStepsInput">
              <div>
                <p>
                  Manufacturing Year<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Year"
                  type="number"
                  name="manufactureYear"
                  value={formData.manufactureYear}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <p>
                  Capacity<span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Capacity"
                  type="number"
                  name="capacity"
                  value={formData.capacity}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Documents",
      content: (
        <>
          <p style={{ marginTop: "0" }}>
            Registration Certificate <span style={{ color: "#FF4D4F" }}>*</span>
          </p>
          <DragDropInput
            responsiveClass={true}
            onChange={(file) =>
              handleFileChange("registrationCertificateUrl", file)
            }
          />
          <p>
            Insurance Certificate <span style={{ color: "#FF4D4F" }}>*</span>
          </p>
          <DragDropInput
            responsiveClass={true}
            onChange={(file) =>
              handleFileChange("insuranceCertificateUrl", file)
            }
          />
          <p>
            Inspection Certificate <span style={{ color: "#FF4D4F" }}>*</span>
          </p>
          <DragDropInput
            responsiveClass={true}
            onChange={(file) =>
              handleFileChange("inspectionCertificateUrl", file)
            }
          />
          <p>
            Vehicle Images <span style={{ color: "#FF4D4F" }}>*</span>
          </p>
          <DragDropInput
            responsiveClass={true}
            onChange={(file) => handleFileChange("vehicleImage", file)}
          />
        </>
      ),
    },
    {
      title: "Driver",
      content: (
        <>
          <div className="">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={{ marginTop: "0" }}>Driver</p>
              <Checkbox>Set as Default</Checkbox>
            </div>
            <Select
              style={{ width: "100%" }}
              onChange={(value) => handleSelectChange(value, "driverId")}
              options={[]}
            />
            {!!formData.driverId && (
              <>
                <div className="AddVehicleDriver">
                  <img
                    style={{
                      height: "48px",
                      width: "48px",
                      marginRight: "12px",
                    }}
                    src={require("../../Assets/Images/User2.png")}
                  />
                  <div>
                    <p style={{ margin: "0" }}>Khalid Sumbul</p>
                    <small style={{ color: "#8692A6" }}>+971 55 869 1856</small>
                  </div>
                </div>
                <Button
                  className="addVehicleButton"
                  style={{
                    position: "absolute",
                    height: "32px",
                    textAlign: "center",
                    borderRadius: "50px",
                    color: "black",
                    border: "1px solid rgba(89, 108, 148, 0.12)",
                    backgroundColor: "rgba(0, 200, 247, 0.12)",
                  }}
                  icon={<FiPlus style={{ fontSize: "15px" }} />}
                  key="cancel"
                >
                  Add
                </Button>
              </>
            )}
          </div>
        </>
      ),
    },
  ];
  /////////////////////
  return (
    <div
      style={{
        background: "rgba(230, 230, 230, 0.40)",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <Navbar showStatusInfo={false} />
      <div className="tableContainer" style={{ fontWeight: "600" }}>
        <HeaderTable
          title="Vehicles"
          buttonText="Add Vehicle"
          onSearchChange={handleSearchChange}
          onAddClick={handleAddVehicle} //////////////////////
          activeCount={vehicles.filter((v) => v.status === "ACTIVE").length}
          inactiveCount={vehicles.filter((v) => v.status === "Inactive").length}
          bookedCount={vehicles.filter((v) => v.status === "Booked").length}
        />
        <Spin size="large" spinning={loading}>
          <div className="bigScreenSize" style={{ color: "#747474 !important" }}>
            <Table
              style={{
                paddingInline: "20px",
                overflowX: "auto",
                borderRadius: "0 0 12px 12px",
                backgroundColor: "white",
              }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredData}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} items`,
                onChange: handlePageChange,
              }}
            />
          </div>
        </Spin>
        <div
          className="smallScreenSize"
          style={{ background: "#F7F8FB", padding: "0px 20px 20px 20px" }}
        >
          <CardView
            data={vehicles}
            currentPage={currentPage}
            pageSize={pageSize}
            onViewDetails={handleViewDetails}
          />
          <div className="mobilePagination">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalItems}
              showTotal={(total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`
              }
              onChange={handlePageChange}
              style={{ textAlign: "center" }}
            />
          </div>
          <DetailModal
            isVisible={isModalVisible}
            handleClose={handleCloseModal}
            content={
              modalItem && (
                <div>
                  <p
                    style={{ margin: "0", fontSize: "20px", fontWeight: "500" }}
                  >
                    Details
                  </p>
                  <span
                    style={{
                      display: "block",
                      height: "1px",
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.06)",
                      margin: "20px 0",
                    }}
                  ></span>
                  <span className="cardDetails">
                    <p>Reg Number</p>
                    <p className="cardDetailHeading">{modalItem.regNumber}</p>
                  </span>
                  <span className="cardDetails">
                    <p>Owner's Name</p>
                    <p className="cardDetailHeading"> {modalItem.model}</p>
                  </span>
                  <span className="cardDetails">
                    <p>Brand</p>
                    <p className="cardDetailHeading">{modalItem.brand}</p>
                  </span>
                  <span className="cardDetails">
                    <p>Model</p>
                    <p className="cardDetailHeading">{modalItem.model}</p>
                  </span>
                  <span className="cardDetails">
                    <p>Capacity</p>
                    <p className="cardDetailHeading">{modalItem.capacity}</p>
                  </span>
                  {/* <span className="cardDetails">
                            <p>Change Status</p>
                            <Select
                                value={modalItem.status}
                                style={{ width: 120 }}
                                onChange={handleStatusChange}
                            >
                                <Select.Option value="Active">
                                    <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                                </Select.Option>
                                <Select.Option value="Inactive">
                                    <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Inactive</>} />
                                </Select.Option>
                                <Select.Option value="Booked">
                                    <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
                                </Select.Option>
                            </Select>
                        </span> */}
                </div>
              )
            }
          />
          <ConfirmationModal
            isVisible={isConfirmModalVisible}
            confirmModalMargin={"20%"}
            handleClose={handleCancelStatusChange}
            content={
              <div>
                <p
                  style={{
                    margin: "0 0 12px 0",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Are you sure?
                </p>
                <p>
                  Are You Sure You Want To Change The Status From{" "}
                  <strong>{modalItem?.status}</strong> To{" "}
                  <strong>{statusToChange}</strong>?
                </p>
              </div>
            }
            actions={[
              <Button
                style={{
                  marginTop: "30px",
                  width: "48%",
                  height: "32px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "black",
                  border: "1px solid rgba(89, 108, 148, 0.12)",
                }}
                key="cancel"
                onClick={handleCancelStatusChange}
              >
                Cancel
              </Button>,
              <Button
                style={{
                  marginTop: "30px",
                  width: "48%",
                  height: "32px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "white",
                  background: "#001B72",
                  border: "none",
                }}
                key="confirm"
                type="primary"
                onClick={handleConfirmStatusChange}
              >
                Confirm
              </Button>,
            ]}
          />
        </div>
      </div>
      <ConfirmationModal
        isVisible={isAddConfirmModalVisible}
        closeBtn={true}
        // onConfirm={handleConfirmAddTransporter}
        handleClose={handleCancelAddVehicle}
        content={
          <Spin size="large" spinning={isSubmitting}>
            <div className="addVehicleMain">
              <div>
                <p style={{ margin: "0", fontSize: "20px", fontWeight: "500" }}>
                  Vehicle Onboarding
                </p>
              </div>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "rgba(0, 0, 0, 0.06)",
                  width: "100%",
                  margin: "20px 0 ",
                }}
              ></div>
              <Steps
                current={current}
                progressDot
                direction="horizontal"
                responsive={false}
                className="progressStepper"
              >
                {steps.map((step, index) => (
                  <Step key={index} title={step.title} />
                ))}
              </Steps>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "rgba(0, 0, 0, 0.06)",
                  width: "100%",
                  margin: "20px 0 ",
                }}
              ></div>
              <div className="steps-content">{steps[current].content}</div>
            </div>
          </Spin>
        }
        actions={[
          <div
            className="addFooterMainDiv"
            style={{
              display: "flex",
              gap: "12px",
              marginLeft: "auto",
              width: "max-content",
            }}
          >
            <Button
              className="addModalFooterBtn"
              style={{
                // width: '48%',
                height: "32px",
                textAlign: "center",
                borderRadius: "50px",
                color: "black",
                border: "1px solid rgba(89, 108, 148, 0.12)",
              }}
              key="cancel"
              onClick={handleCancelAddVehicle}
            >
              Cancel
            </Button>
            <Button
              disabled={!isValidStep[current]}
              className="addModalFooterBtn"
              style={{
                height: "32px",
                textAlign: "center",
                borderRadius: "50px",
                color: "white",
                background: "#00C8F7",
                border: "none",
              }}
              key="confirm"
              type="primary"
              onClick={handleNext}
            // onClick={() => {
            //     setIsAddConfirmModalVisible(false);
            // }}
            >
              Continue
            </Button>
          </div>,
        ]}
      />
    </div>
  );
}
