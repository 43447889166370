import React from 'react';
import { Modal } from 'antd';

const DetailModal = ({ isVisible, handleClose, content }) => {
    return (
        <Modal width='100%' className='smallScreenSize' visible={isVisible} onCancel={handleClose} footer={null} >
            {content}
        </Modal>
    );
};

export default DetailModal;
