import React, { useState, useEffect, useRef, useContext } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Button, Input } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import ReactDOM from "react-dom";
import { FiSearch } from "react-icons/fi";
import { GrContract } from "react-icons/gr";
import { CgExpand } from "react-icons/cg";
import { LuMap, LuRuler, LuBarChart4 } from "react-icons/lu";
import { BsClouds } from "react-icons/bs";
import { FaRegSquare } from "react-icons/fa6";
import { MdLocationSearching } from "react-icons/md";
import { GiPositionMarker } from "react-icons/gi";
import customMarkerImage from "../Assets/Images/Trucks/TruckOnly.png";
import { Tooltip } from "antd"
  ;
import FreeContainerE from '../Assets/Images/Trucks/FreeContainerE.png'
import FreeContainerH from '../Assets/Images/Trucks/FreeContainerH.png'
import FreeContainerHL from '../Assets/Images/Trucks/FreeContainerHL.png'
import FreeContainerM from '../Assets/Images/Trucks/FreeContainerM.png'
import TruckContainerH from '../Assets/Images/Trucks/TruckContainerH.png'
import TruckContainerM from '../Assets/Images/Trucks/TruckContainerM.png'


import "./Map.css";
import axios from "axios";
import { GlobalContext } from "../context";

const mapStyles = {
  position: "",
  width: "100%",
  height: "93%",
};

const MapContainer = (props) => {
  const { dispatch } = useContext(GlobalContext);
  const mapRef = useRef(null);
  const directionsRendererRef = useRef(null);
  const markerRefs = useRef([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [initialMarkers, setInitialMarkers] = useState([]);
  const [initialCenter, setInitialCenter] = useState({
    lat: 25.205518,
    lng: 55.271167,
  });

  useEffect(() => {
    fetchLocations();
  }, []);
  const clearMarkers = () => {
    markerRefs.current.forEach((marker) => marker.setMap(null));
    markerRefs.current = [];
    setInitialMarkers([]);
  };

  const initializeDirectionsRenderer = () => {
    const { origin, destination, google } = props;
    if (!origin || !destination) {
      if (directionsRendererRef.current) {
        directionsRendererRef.current.setDirections({ routes: [] });
      }
      mapRef.current.setCenter(initialCenter);
      const bounds = new google.maps.LatLngBounds();
      initialMarkers.forEach((marker) => bounds.extend(marker));
      mapRef.current.fitBounds(bounds);
      return;
    }

    const directionsRenderer = new google.maps.DirectionsRenderer();
    directionsRenderer.setMap(mapRef.current);
    directionsRendererRef.current = directionsRenderer;

    const directionsService = new google.maps.DirectionsService();
    const request = {
      origin: new google.maps.LatLng(origin.latitude, origin.longitude), // Use props origin
      destination: new google.maps.LatLng(
        destination.latitude,
        destination.longitude
      ), // Use props destination
      travelMode: "DRIVING",
    };

    directionsService.route(request, (result, status) => {
      if (status === "OK") {
        directionsRenderer.setDirections(result);
      } else {
        console.error("Error fetching directions", result);
      }
    });
  };

  useEffect(() => {
    if (mapLoaded) {
      initSearchBox();
      initializeDirectionsRenderer(); // Reinitialize directions when props change
    }
  }, [mapLoaded, props.google]);

  useEffect(() => {
    if (mapLoaded) {
      initializeDirectionsRenderer(); // Reinitialize directions when props change
    }
  }, [props.origin, props.destination, mapLoaded]);

  useEffect(() => {
    if (props.current.latitude !== null && props.current.longitude !== null) {
      addMarkers([props.current], true);
    } else {
      fetchLocations();
    }
  }, [props.current.latitude, props.current.longitude, props.selectedMapyType]);

  const fetchLocations = () => {
    const token = sessionStorage.getItem("token");
    const params = new URLSearchParams({
      type: props.selectedMapyType,
    });

    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/dashboard?${params.toString()}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        let locations = []
        if (props.selectedMapyType === "track") {
          locations = (response.data.vehicleLocationList);
        } else if (props.selectedMapyType === "trip") {
          locations = (response.data.tripLocationList);
        } else if (props.selectedMapyType === "container") {
          locations = (response.data.containerLocationList);
        }
        dispatch({ type: 'REQUEST_COUNT', payload: response.data.requestCount });
        addMarkers(locations);
      })
      .catch((error) => {
        console.error("Error fetching locations:", error);
      });
  };

  const addMarkers = (locations, singleMarker) => {
    const { google } = props;
    const map = mapRef.current;

    if (!google || !map) return;
    const markers = locations.map(({ latitude, longitude, ...location }) => ({
      lat: latitude,
      lng: longitude,
      ...location,
    }));

    clearMarkers();
    // const mapMarkers = markers.map((marker, index) => {
    //   const containerImageMapping = {
    //     1: FreeContainerHL,
    //     2: FreeContainerM,
    //     3: FreeContainerE,
    //     4: FreeContainerH,
    //   };

    //   const containerSizeMapping = {
    //     1: { width: 27, height: 99 },
    //     2: { width: 27, height: 99 },
    //     3: { width: 27, height: 99 },
    //     4: { width: 27, height: 99 },
    //   };

    //   let markerImage;
    //   let markerSize;

    //   if (props.selectedMapyType === "trip") {
    //     markerImage = parseInt(marker.id) % 2 === 0 ? TruckContainerM : TruckContainerH;
    //     markerSize = { width: 18, height: 80 };
    //   } else if (props.selectedMapyType === "container") {
    //     const mod = (parseInt(marker.id) % 4) + 1;
    //     markerImage = containerImageMapping[mod];
    //     markerSize = containerSizeMapping[mod];
    //   } else {
    //     markerImage = customMarkerImage;
    //     markerSize = { width: 22, height: 66 };
    //   }

    //   const markerInstance = new google.maps.Marker({
    //     position: { lat: marker.lat, lng: marker.lng },
    //     map: map,
    //     icon: {
    //       url: markerImage,
    //       scaledSize: new google.maps.Size(markerSize.width, markerSize.height),
    //     },
    //     title: marker.number,
    //   });

    //   const infoWindow = new google.maps.InfoWindow({
    //     content:
    //       `<div class="custom-info-window">
    //         <div class="custom-content">
    //            ${marker.number}
    //         </div>
    //         <div class="custom-arrow"></div>
    //       </div>`, // Customize the content as needed
    //   });

    //   markerInstance.addListener("mouseover", () => {
    //     infoWindow.open(map, markerInstance);
    //   });

    //   markerInstance.addListener("mouseout", () => {
    //     infoWindow.close();
    //   });

    //   if (!singleMarker) {
    //     markerInstance.addListener("click", () => {
    //       props.onMarkerClick(marker);
    //     });
    //   }

    //   return markerInstance;
    // });
    const mapMarkers = markers.map((marker, index) => {
      const containerImageMapping = {
        1: FreeContainerHL,
        2: FreeContainerM,
        3: FreeContainerE,
        4: FreeContainerH,
      };
      const containerSizeMapping = {
        1: { width: 27, height: 99 },
        2: { width: 27, height: 99 },
        3: { width: 27, height: 99 },
        4: { width: 27, height: 99 },
      };
      let markerImage;
      let markerSize;
      if (props.selectedMapyType === "trip") {
        markerImage = parseInt(marker.id) % 2 === 0 ? TruckContainerM : TruckContainerH;
        markerSize = { width: 18, height: 80 }; // Default size for trip markers
      } else if (props.selectedMapyType === "container") {
        const mod = (parseInt(marker.id) % 4) + 1;
        markerImage = containerImageMapping[mod];
        markerSize = containerSizeMapping[mod]; // Custom size based on container mod value
      } else {
        markerImage = customMarkerImage;
        markerSize = { width: 22, height: 66 }; // Default size for custom markers
      }

      const markerInstance = new google.maps.Marker({
        position: { lat: marker.lat, lng: marker.lng },
        map: map,
        icon: {
          url: markerImage,
          scaledSize: new google.maps.Size(markerSize.width, markerSize.height),
        },
        title: marker.number,
      });

      if (!singleMarker) {
        markerInstance.addListener("click", () => {
          props.onMarkerClick(marker);
        });
      }

      return markerInstance;
    });

    markerRefs.current = mapMarkers;
    setInitialMarkers(markers); // Store initial markers

    if (!singleMarker) {
      const bounds = new google.maps.LatLngBounds();
      markers.forEach((marker) => bounds.extend(marker));
      map.fitBounds(bounds);
    }

    if (locations.length === 0) {
      recenterMap(map)
    }
  };

  const initSearchBox = () => {
    const { google } = props;
    const map = mapRef.current;

    if (!google || !map) return;

    const input = document.getElementById("pac-input");
    const searchBox = new google.maps.places.SearchBox(input);

    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });

    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }

        new google.maps.Marker({
          position: place.geometry.location,
          map: map,
          title: place.name,
          icon: {
            url: customMarkerImage,
          },
        });

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });

    map.setOptions({
      disableDefaultUI: true,
    });

    const viewControlsContainer = document.createElement("div");
    viewControlsContainer.className = "custom-map-views";
    viewControlsContainer.index = 1;
    map.controls[google.maps.ControlPosition.RIGHT_TOP].push(
      viewControlsContainer
    );

    const mapViewButton = document.createElement("div");
    ReactDOM.render(
      <div>
        <Button type="default" shape="circle" icon={<LuMap />} />
        <hr style={{ border: ".2px solid #F0F0F0", margin: "0" }} />
      </div>,
      mapViewButton
    );
    mapViewButton.addEventListener("click", () => {
      map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    });
    viewControlsContainer.appendChild(mapViewButton);

    const satelliteViewButton = document.createElement("div");
    ReactDOM.render(
      <div>
        <Button type="default" shape="circle" icon={<BsClouds />} />
        <hr style={{ border: ".2px solid #F0F0F0", margin: "0" }} />
        <Button type="default" shape="circle" icon={<FaRegSquare />} />
        <hr style={{ border: ".2px solid #F0F0F0", margin: "0" }} />
        <Button type="default" shape="circle" icon={<LuRuler />} />
      </div>,
      satelliteViewButton
    );
    satelliteViewButton.addEventListener("click", () => {
      map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
    });
    viewControlsContainer.appendChild(satelliteViewButton);

    const zoomControlsContainer = document.createElement("div");
    zoomControlsContainer.className = "custom-map-control";
    zoomControlsContainer.index = 1;
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      zoomControlsContainer
    );

    const zoomInButton = document.createElement("div");
    ReactDOM.render(
      <Button type="default" shape="circle" icon={<PlusOutlined />} />,
      zoomInButton
    );
    zoomInButton.style.marginBottom = "0px";
    zoomInButton.addEventListener("click", () => {
      map.setZoom(map.getZoom() + 1);
      changeButtonColor(zoomInButton);
    });
    zoomControlsContainer.appendChild(zoomInButton);

    const zoomOutButton = document.createElement("div");
    ReactDOM.render(
      <Button type="default" shape="circle" icon={<MinusOutlined />} />,
      zoomOutButton
    );
    zoomOutButton.style.marginBottom = "0px";
    zoomOutButton.addEventListener("click", () => {
      map.setZoom(map.getZoom() - 1);
      changeButtonColor(zoomOutButton);
    });
    zoomControlsContainer.appendChild(zoomOutButton);

    const fullscreenControlsContainer = document.createElement("div");
    fullscreenControlsContainer.className = "fullscreen-map-control";
    fullscreenControlsContainer.index = 1;
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      fullscreenControlsContainer
    );

    const fullscreenButton = document.createElement("div");
    ReactDOM.render(
      <Button
        className="fullscreen-button"
        style={{
          cursor: "pointer",
          fontSize: "20px",
          width: "34px",
          marginRight: "20px",
          padding: "0",
          borderRadius: "50%",
        }}
      >
        {isFullscreen ? (
          <GrContract style={{ fontSize: "16px" }} />
        ) : (
          <CgExpand />
        )}
      </Button>,
      fullscreenButton
    );
    fullscreenButton.addEventListener("click", () => {
      toggleFullscreen();
      ReactDOM.render(
        <Button
          className="fullscreen-button"
          style={{
            cursor: "pointer",
            fontSize: "20px",
            width: "34px",
            marginRight: "20px",
            padding: "0",
            borderRadius: "50%",
          }}
        >
          {isFullscreen ? (
            <CgExpand />
          ) : (
            <GrContract style={{ fontSize: "16px" }} />
          )}
        </Button>,
        fullscreenButton
      );
    });
    fullscreenControlsContainer.appendChild(fullscreenButton);

    const recenterButtonContainer = document.createElement("div");
    recenterButtonContainer.className = "recenter-map-control";
    recenterButtonContainer.index = 1;
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      recenterButtonContainer
    );

    const recenterButton = document.createElement("div");
    ReactDOM.render(
      <Button type="default" shape="circle" icon={<MdLocationSearching />} />,
      recenterButton
    );
    recenterButton.style.marginBottom = "10px";
    recenterButton.addEventListener("click", () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            const circle = new google.maps.Circle({
              center: userLocation,
              radius: 50000,
            });

            const bounds = circle.getBounds();
            map.fitBounds(bounds);
          },
          (error) => {
            console.error("Error getting user location:", error);
            map.setCenter(initialCenter);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        map.setCenter(initialCenter);
      }
    });
    recenterButtonContainer.appendChild(recenterButton);
  };

  const changeButtonColor = (button) => {
    button.style.backgroundColor = "#30468D";
    setTimeout(() => {
      button.style.backgroundColor = "";
    }, 200);
  };

  const toggleFullscreen = () => {
    const element = mapRef.current.getDiv();

    if (!document.fullscreenElement) {
      element
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
        })
        .catch((err) => {
          console.error(
            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
          );
        });
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
        })
        .catch((err) => {
          console.error(
            `Error attempting to exit fullscreen mode: ${err.message} (${err.name})`
          );
        });
    }
  };

  function recenterMap(map) {
    setTimeout(() => {
      const { google } = props;
      map.setZoom(14);
      map.setCenter(
        new google.maps.LatLng(initialCenter.lat, initialCenter.lng)
      );
      const circle = new google.maps.Circle({
        center: initialCenter,
        radius: 50000,
      });

      const bounds = circle.getBounds();
      map.fitBounds(bounds);
    })
  }

  return (
    <div style={{ marginTop: "66px" }}>
      <div
        className="MapInputBtn"
        style={{
          display: "flex",
          position: "absolute",
          zIndex: 1,
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "35px",
          height: "38px",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Input
          id="pac-input"
          className="controls"
          type="text"
          placeholder="Search anything here..."
          style={{
            boxSizing: "border-box",
            border: "1px solid transparent",
            height: "38px",
            padding: "0 12px",
            borderRadius: "20px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            fontSize: "14px",
          }}
          prefix={<FiSearch style={{ fontSize: "20px", color: "#787878" }} />}
        />
        <Button
          className="statButton"
          style={{
            height: "38px",
            textAlign: "center",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
            borderRadius: "20px",
            color: "black",
            border: "none",
          }}
          type="default"
          shape="round"
          icon={<LuBarChart4 style={{ fontSize: "20px" }} />}
          size="large"
        >
          <span className="statLabel">Stats</span>
        </Button>
      </div>
      <Map
        google={props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={initialCenter}
        onReady={(mapProps, map) => {
          mapRef.current = map;
          setMapLoaded(true);
          recenterMap(map)
        }}
        options={{
          disableDefaultUI: true,
        }}
      />
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
})(MapContainer);
