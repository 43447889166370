import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import PrivateRoute from "./Pages/PrivateRoute";
import "@fontsource/inter";
import "./index.css";
import Transporter from "./Pages/Trasporter/Transporter";
import Vehicles from "./Pages/Trasporter/Vehicle";
import Drivers from "./Pages/Trasporter/Drivers";
import Trips from "./Pages/Trips";
import VehicleMaster from "./Pages/Masters/VehicleMaster";
import ZoneMaster from "./Pages/Masters/ZoneMaster";
import RateProfile from "./Pages/Masters/RateProfile";
import RateBrowser from "./Pages/Masters/RateBrowser";
import ShippingLine from "./Pages/Masters/ShippingLine";
import Reports from "./Pages/Reports";
import Requests from "./Pages/Requests";
import { GlobalProvider } from "./context";

const privateRoutes = [
  { path: "/transporter", element: <Transporter /> },
  { path: "/vehicle", element: <Vehicles /> },
  { path: "/driver", element: <Drivers /> },
  { path: "/trip", element: <Trips /> },
  { path: "/vehiclemaster", element: <VehicleMaster /> },
  { path: "/zonemaster", element: <ZoneMaster /> },
  { path: "/rateprofile", element: <RateProfile /> },
  { path: "/ratebrowser", element: <RateBrowser /> },
  { path: "/shippingline", element: <ShippingLine /> },
  { path: "/reports", element: <Reports /> },
  { path: "/request", element: <Requests /> },
  { path: "/dashboard", element: <Dashboard /> },
];

function App() {
  return (
    <GlobalProvider>
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Login />} />
          {privateRoutes.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={<PrivateRoute>{element}</PrivateRoute>}
            />
          ))}
        </Routes>
      </Router>
    </GlobalProvider>
  );
}

export default App;
