import React, { useContext, useEffect } from "react";
import { Layout, Menu, theme, Badge } from "antd";
import "../style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import UserInfo from "./UserInfo";
import { RiArrowDownSLine } from "react-icons/ri";
import { GlobalContext } from "../context";
const { Header, Content, Footer } = Layout;

const items = (requestCount) => [
  {
    label: (
      <span
        style={{ display: "block", height: "100%" }}
        className="dashNavLink dashBoard"
      >
        <a
          className="NavLinks"
          style={{ textDecoration: "none" }}
          href="/dashboard"
        >
          Dashboard
        </a>
      </span>
    ),
  },
  {
    label: (
      <>
        <span>
          <a className="NavLinks" style={{ textDecoration: "none" }} href="#">
            Transporters
          </a>
          <RiArrowDownSLine
            className="downArrowNav"
            style={{
              fontSize: "16px",
              verticalAlign: "middle",
              color: "#5D5D5D",
              marginLeft: "10px",
            }}
          />
        </span>
      </>
    ),
    children: [
      {
        label: (
          <a
            className=""
            style={{ textDecoration: "none" }}
            href="/transporter"
          >
            Transporter
          </a>
        ),
      },
      {
        label: (
          <a className="" style={{ textDecoration: "none" }} href="/vehicle">
            Vehicles
          </a>
        ),
      },
      {
        label: (
          <a className="" style={{ textDecoration: "none" }} href="/driver">
            Drivers
          </a>
        ),
      },
    ],
  },
  {
    label: (
      <span>
        <a className="NavLinks" style={{ textDecoration: "none" }} href="/trip">
          Trips
        </a>
      </span>
    ),
    children: [],
  },
  {
    label: (
      <>
        <span>
          <a className="NavLinks" style={{ textDecoration: "none" }} href="#">
            Masters
          </a>
          <RiArrowDownSLine
            className="downArrowNav"
            style={{
              fontSize: "16px",
              verticalAlign: "middle",
              color: "#5D5D5D",
              marginLeft: "10px",
            }}
          />
        </span>
      </>
    ),
    children: [
      {
        label: (
          <a
            className=""
            style={{ textDecoration: "none" }}
            href="/vehiclemaster"
          >
            Vehicle Type Master
          </a>
        ),
      },
      {
        label: (
          <a className="" style={{ textDecoration: "none" }} href="/zonemaster">
            Zone Master
          </a>
        ),
      },
      {
        label: (
          <a
            className=""
            style={{ textDecoration: "none" }}
            href="/rateprofile"
          >
            Rate Profile
          </a>
        ),
      },
      // {
      //   label: (
      //     <a className="" style={{ textDecoration: "none" }} href="/ratebrowser">
      //       Rate Browser
      //     </a>
      //   ),
      // },
      {
        label: (
          <a
            className=""
            style={{ textDecoration: "none" }}
            href="/shippingline"
          >
            Shipping Line
          </a>
        ),
      },
    ],
  },
  {
    label: (
      <span>
        <a
          className="NavLinks"
          style={{ textDecoration: "none" }}
          href="/reports"
        >
          Reports
        </a>
      </span>
    ),
    children: [],
  },
  {
    label: (
      <span>
        <a className="NavLinks" href="/request">
          Requests
        </a>
        {!!requestCount && (
          <Badge
            style={{}}
            color="#00C8F7"
            count={requestCount}
            offset={[5, -3]}
          />
        )}
      </span>
    ),
    style: { textDecoration: "none" },
    children: [],
  },
];

const Navbar = ({ showStatusInfo = true }) => {
  const { state } = useContext(GlobalContext);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout style={{ position: "fixed", width: "100%", zIndex: "99999" }}>
      <Header
        className="navHeader"
        style={{
          height: "66px",
          zIndex: "1100",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          className="demo-logo"
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={require("../Assets/Images/SWAP_New_Logo 1.png")}
            alt="Logo"
            style={{ width: "150px" }}
          />
        </div>
        <p
          className="vrLine"
          style={{
            padding: "0 15px 0 32px",
            fontSize: "2em",
            color: "#DCDCDC",
          }}
        >
          |
        </p>
        <Menu
          className="navLinks hide-mobile"
          theme="light"
          mode="horizontal"
          items={items(state.requestCount)}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        />
        <div style={{ textShadow: "0 0 0 transparent !important" }}>
          {showStatusInfo && (
            <>
              <span
                className="bulletsInfo"
                style={{
                  marginRight: "20px",
                  color: "5E5E5E",
                  fontWeight: "500",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  size={"2xs"}
                  style={{ color: "#000000", marginRight: "4px" }}
                />
                On Route: <span style={{ color: "black" }}>10</span>
              </span>
              <span
                className="bulletsInfo"
                style={{
                  marginRight: "20px",
                  color: "5E5E5E",
                  fontWeight: "500",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  size={"2xs"}
                  style={{ color: "#FA541C", marginRight: "4px" }}
                />
                Faulty: <span style={{ color: "black" }}>3</span>
              </span>
              <span
                className="bulletsInfo"
                style={{
                  marginRight: "25px",
                  color: "5E5E5E",
                  fontWeight: "500",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  size={"2xs"}
                  style={{ color: "#52C41A", marginRight: "4px" }}
                />
                Available: <span style={{ color: "black" }}>5</span>
              </span>
            </>
          )}
          <UserInfo mobileViewItems={items(state.requestCount)} />
        </div>
      </Header>
    </Layout>
  );
};

export default Navbar;
