import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Badge, Dropdown, Menu, Pagination, Select, Button, Spin } from 'antd';
import Navbar from "../../Components/Navbar";
import { LuCheckCircle2 } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import HeaderTable from '../../Components/HeaderTable';
import '../NavlinksTable.css';
import { IoIosMore, IoMdCheckmark } from "react-icons/io";
import ViewButton from "../../Assets/ViewButton";
import DetailModal from "../../Assets/DetailModal";
import ConfirmationModal from "../../Assets/ConfirmationModal";
const ActionButton = ({ status }) => {
    const menu = (
        <Menu>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">
                    Active {status === 'Active' ? <IoMdCheckmark style={{ color: 'black', marginLeft: '20px', verticalAlign: 'middle' }} /> : null}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">
                    Inactive {status === 'Inactive' ? <IoMdCheckmark style={{ color: 'black', marginLeft: '20px', verticalAlign: 'middle' }} /> : null}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">Edit</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="d-flex">
            <Dropdown overlay={menu}>
                <IoIosMore />
            </Dropdown>
        </div>
    );
};

const columns = [
    { title: '#', dataIndex: 'no' },
    { title: `Shipping Line`, dataIndex: 'shippingLine' },
    { title: 'Code', dataIndex: 'code' },
    // { title: 'Added On', dataIndex: 'addedOn' },
    // {
    //     title: 'Status',
    //     dataIndex: 'status',
    //     render: (text) => (
    //         <span>
    //             {text === 'Active' ? (
    //                 <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
    //             ) : text === 'Booked' ? (
    //                 <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
    //             ) : (
    //                 <Badge count={<><RxCrossCircled className="tabStatusIcon" style={{ color: 'red', verticalAlign: 'middle', fontSize: '17px' }} /> Inactive</>} />
    //             )}
    //         </span>
    //     ),
    // },
    {
        title: 'Action',
        key: 'x',
        dataIndex: 'status',
        render: (status) => <ActionButton status={status} />,
    },
];

const generateData = (shippings) => {
    return shippings.map((shipping, index) => ({
        no: (index + 1).toString().padStart(2, '0'),
        shippingLine: shipping.name,
        code: shipping.key,
        // addedOn: '18 Feb, 2014',
        // status: status,
    }));
};

const CardView = ({ data, currentPage, pageSize, onViewDetails }) => {
    // const startIndex = (currentPage - 1) * pageSize;
    // const endIndex = startIndex + pageSize;
    const currentData = data;
    return (
        <div className="cardContainer">
            {currentData.length > 0 ? (
                currentData.map(item => (
                    <div key={item.no} className="card">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p style={{ fontSize: '18px', fontWeight: '600px', margin: '0 0 10px 0' }}>{item.shippingLine}</p>
                            {/* {item.status === 'Active' ? (
                                <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                            ) : item.status === 'Booked' ? (
                                <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
                            ) : (
                                <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Inactive</>} />
                            )} */}
                        </div>
                        <p style={{ fontSize: '14px', fontWeight: '500px', margin: '10px 0 20px 0' }}>{item.code}</p>
                        <ViewButton onClick={() => onViewDetails(item)} />
                    </div>
                ))
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};
export default function ShippingLine() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalItem, setModalItem] = useState(null);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [statusToChange, setStatusToChange] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [shippings, setShippings] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const filteredData = shippings.filter(item =>
        item.shippingLine.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.code.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    useEffect(() => {
        fetchShippingLine(currentPage - 1, pageSize);
    }, [currentPage, pageSize]);

    const fetchShippingLine = async (page, size) => {
        const token = sessionStorage.getItem("token");
        const params = new URLSearchParams({
            page: page,
            size: size,
            sort: "createdDate,desc",
        });
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL
                }/shipping-lines/list?${params.toString()}`,
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            )
            .then((response) => {
                const data = response.data;
                setShippings(generateData(data.content));
                setTotalItems(data.page.totalElements);
            })
            .catch((error) => {
                console.error("Error fetching Data:", error);
            }).finally(() => {
                setLoading(false); // Set loading to false after the request completes
            });

    };
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };
    const handleViewDetails = (item) => {
        setModalItem(item);
        setIsModalVisible(true);
    };
    const handleCloseModal = () => {
        setIsModalVisible(false);
        setModalItem(null);
    };
    const handleStatusChange = (value) => {
        setStatusToChange(value);
        setIsConfirmModalVisible(true);
    };
    const handleConfirmStatusChange = () => {
        setModalItem({ ...modalItem, status: statusToChange });
        setIsConfirmModalVisible(false);
    };
    const handleCancelStatusChange = () => {
        setIsConfirmModalVisible(false);
    };

    return (
        <div style={{ background: 'rgba(230, 230, 230, 0.40)', height: '100%', overflow: 'scroll' }}>
            <Navbar showStatusInfo={false} />
            <div className="tableContainer" style={{ fontWeight: '600' }}>
                <HeaderTable
                    onSearchChange={handleSearchChange}
                    title="Shipping Line"
                    buttonText="Add Shipping Line"
                    onAddClick={() => console.log('Add Shipping Line button clicked')}
                    activeCount={shippings.filter(v => v.status === 'Active').length}
                    inactiveCount={shippings.filter(v => v.status === 'Inactive').length}
                    bookedCount={shippings.filter(v => v.status === 'Booked').length}
                />
                <Spin size="large" spinning={loading}>
                    <div className="bigScreenSize" style={{ color: '#747474 !important' }}>
                        <Table
                            style={{
                                paddingInline: '20px', overflowX: 'auto', borderRadius: '0 0 12px 12px', backgroundColor: 'white'
                            }}

                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={filteredData}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                total: totalItems,
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
                                onChange: handlePageChange,
                            }}
                        />
                    </div>
                </Spin>
                <div className="smallScreenSize" style={{ background: '#F7F8FB', padding: '0px 20px 20px 20px' }} >
                    <CardView data={shippings} currentPage={currentPage} pageSize={pageSize} onViewDetails={handleViewDetails} />
                    <div className="mobilePagination">
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={totalItems}
                            showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`}
                            onChange={handlePageChange}
                            style={{ textAlign: 'center' }}
                        />
                    </div>
                    <DetailModal isVisible={isModalVisible} handleClose={handleCloseModal} content={modalItem && (
                        <div>
                            <p style={{ margin: "0", fontSize: '20px', fontWeight: '500' }}>Details</p>
                            <span style={{ display: 'block', height: '1px', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.06)', margin: '20px 0' }}></span>
                            <span className="cardDetails">
                                <p>Shipping Line</p>
                                <p className="cardDetailHeading">{modalItem.shippingLine}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Code</p>
                                <p className="cardDetailHeading"> {modalItem.code}</p >
                            </span>
                            {/* <span className="cardDetails">
                                <p>Added On</p>
                                <p className="cardDetailHeading">{modalItem.addedOn}</p>
                            </span> */}
                            {/* <span className="cardDetails">
                                <p>Change Status</p>
                                <Select
                                    value={modalItem.status}
                                    style={{ width: 'fit-content' }}
                                    onChange={handleStatusChange}
                                >
                                    <Select.Option value="Active">
                                        <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                                    </Select.Option>
                                    <Select.Option value="Inactive">
                                        <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Inactive</>} />
                                    </Select.Option>
                                    <Select.Option value="Booked">
                                        <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
                                    </Select.Option>
                                </Select>
                            </span> */}
                        </div>
                    )} />
                    <ConfirmationModal isVisible={isConfirmModalVisible} handleClose={handleCancelStatusChange} content={
                        <div>
                            <p style={{ margin: "0 0 12px 0", fontSize: '20px', fontWeight: '500' }}>Are you sure?</p>
                            <p>Are You Sure You Want To Change The Status From <strong>{modalItem?.status}</strong> To <strong>{statusToChange}</strong>?</p>
                        </div>
                    } actions={[
                        <Button
                            style={{
                                marginTop: '30px',
                                width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "black",
                                border: "1px solid rgba(89, 108, 148, 0.12)",
                            }}
                            key="cancel" onClick={handleCancelStatusChange}>Cancel</Button>,
                        <Button
                            style={{
                                marginTop: '30px',
                                width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "white",
                                background: '#001B72',
                                border: "none",
                            }}
                            key="confirm" type="primary" onClick={handleConfirmStatusChange}>Confirm</Button>
                    ]} />
                </div>
            </div>
        </div>
    );
}
