// CustomNotification.js
import React from 'react';
import { Button, notification } from 'antd';
import { CheckCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { PiWarningCircleFill } from "react-icons/pi";
const getNotificationIcon = (type) => {
  switch (type) {
    case 'success':
      return <CheckCircleFilled style={{ color: '#52c41a' }} />;
    case 'info':
      return <InfoCircleFilled style={{ color: '#1677FF' }} />;
    case 'warning':
      return <PiWarningCircleFill  style={{ color: '#faad14' }} />;
    default:
      return null;
  }
};

const MessageRes = (type, message, description) => {
  notification[type]({
    message: message,
    description: (
      <div style={{ margin: '0 0px 0 0', fontSize: '14px', fontWeight: '500', display:'block' }}>
        {description}
      </div>
    ),
    duration:5,
    placement: 'topRight',
    closeIcon: false,
    icon: getNotificationIcon(type),
    btn: (
      <Button
        className='notificationBtn'
        type="primary"
        onClick={() => notification.destroy()}
      >
        OK
      </Button>
    ),
  });
};

export default MessageRes;
