import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import { Table, Badge, Dropdown, Avatar, Menu, Pagination, Select, Button, Steps, Spin, Input, Divider, Checkbox } from 'antd';
import Navbar from "../Components/Navbar";
import { LuCheckCircle2, LuMinusCircle } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import HeaderTable from '../Components/HeaderTable';
import './NavlinksTable.css';
import { IoIosMore, IoMdCheckmark } from "react-icons/io";
import ViewButton from "../Assets/ViewButton";
import DetailModal from "../Assets/DetailModal";
import ConfirmationModal from "../Assets/ConfirmationModal";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import Swal from 'sweetalert2';
import ReactDOM from 'react-dom';
import successIcon from "../Assets/Images/successIcon.png"
import { redirect } from "react-router-dom";
import MessageRes from '../Assets/MessageRes';
const ActionButton = ({ onReviewClick, item }) => {
    return (
        <div className="ActionBtn">
            <Button
                className="reqActionBtn tabStatusIcon"
                style={{
                    textAlign: "center",
                    backgroundColor: 'white',
                    borderRadius: "50px",
                    color: "#C01111",
                    marginRight: '8px',
                    border: "1px solid rgba(89, 108, 148, 0.12)",
                }}
                type="default"
                shape="round"
            >
                <span style={{ fontSize: '13px', fontWeight: '400' }}>
                    Reject
                </span>
            </Button>
            <Button
                className="reqActionBtn tabStatusIcon"
                style={{
                    textAlign: "center",
                    backgroundColor: '#001B72',
                    borderRadius: "50px",
                    color: "white",
                    border: "none",
                }}
                type="default"
                shape="round"
                onClick={() => onReviewClick(item.key)} // Pass the user ID
            >
                <span style={{ fontSize: '13px', fontWeight: '400' }}>
                    Review
                </span>
            </Button>
        </div>
    );
};
const generateData = (reqData) => {
    return reqData.map((request, index) => {
        const dateTime = moment(request.requestedTime);
        return {
            key: request.id, // Use id as the key
            no: (index + 1).toString().padStart(2, '0'),
            pickupDate: dateTime.format('DD MMM, YYYY') || '',
            pickupTime: dateTime.format('hh:mm A') || '', // Ensure this is a string
            pickupAddress: `${request.fromAddressLine1} ${request.fromAddressLine2}`,
            destination: `${request.toAddressLine1} ${request.toAddressLine2}`,
            weight: request.vehicleType.capacity.toString(),
            profVehicleType: request.vehicleType.name,
            // customerName: 'Zaire Korsgaard',
            // revenue: '300 AED',
            status: request.status,
        };
    });
};
const CardView = ({ data, currentPage, pageSize, onViewDetails }) => {
    // const startIndex = (currentPage - 1) * pageSize;
    // const endIndex = startIndex + pageSize;
    const currentData = data;
    return (
        <div className="cardContainer">
            {currentData.length > 0 ? (
                currentData.map(item => (
                    <div key={item.no} className="card">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p style={{ fontSize: '18px', fontWeight: '600px', margin: '0 0 10px 0' }}>{item.pickupAddress}</p>
                            {item.status === 'ACTIVE' ? (
                                <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                            ) : item.status === 'Completed' ? (
                                <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Completed</>} />
                            ) : (
                                <Badge count={<><LuMinusCircle style={{ color: '#FEA800', verticalAlign: 'middle', fontSize: '17px' }} /> Pending</>} />
                            )}
                        </div>
                        <p style={{ fontSize: '14px', fontWeight: '500px', margin: '10px 0 20px 0' }}>{item.weight}</p>
                        <ViewButton onClick={() => onViewDetails(item)} />
                    </div>
                ))
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};
export default function Requests() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalItem, setModalItem] = useState(null);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [statusToChange, setStatusToChange] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [reqData, setreqData] = useState([]);
    const [apiResponse, setApiResponse] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [isAddConfirmModalVisible, setIsAddConfirmModalVisible] = useState(false)
    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const filteredData = reqData.filter(item =>
        item.pickupDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.pickupTime.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.pickupAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.destination.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.weight.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.profVehicleType.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.destination.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const token = sessionStorage.getItem("token");
    const [selectedUserData, setSelectedUserData] = useState(null);
    const [itemId, setItemId] = useState(null);
    const tripTime = selectedUserData?.tripTime;
    const formattedDate = moment(tripTime).format('YYYY-MM-DD');
    const formattedTime = moment(tripTime).format('hh:mm A');
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Example breakpoint for mobile view
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize(); // Check the initial size
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(() => {
        fetchRequestList(currentPage - 1, pageSize);
    }, [currentPage, pageSize]);

    const fetchRequestList = async (page, size) => {
        const token = sessionStorage.getItem("token");
        const params = new URLSearchParams({
            page: page,
            size: size,
            sort: "createdDate,desc",
        });
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL
                }/trip-requests/list?${params.toString()}`,
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            )
            .then((response) => {
                const data = response.data;
                setreqData(generateData(data.content));
                setApiResponse(data.content);
                setTotalItems(data.page.totalElements);
            })
            .catch((error) => {
                console.error("Error fetching Data:", error);
            }).finally(() => {
                setLoading(false); // Set loading to false after the request completes
            });
    };
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };
    const handleViewDetails = (item) => {
        setModalItem(item);
        setIsModalVisible(true);
    };
    const handleCloseModal = () => {
        setIsModalVisible(false);
        setModalItem(null);
    };
    const handleStatusChange = (value) => {
        setStatusToChange(value);
        setIsConfirmModalVisible(true);
    };
    const handleConfirmStatusChange = () => {
        setModalItem({ ...modalItem, status: statusToChange });
        setIsConfirmModalVisible(false);
    };
    const handleCancelStatusChange = () => {
        setIsConfirmModalVisible(false);
    };
    const columns = [
        { title: '#', dataIndex: 'no' },
        // { title: 'Customer Name', dataIndex: 'customerName' },
        { title: 'Pick Up Date', dataIndex: 'pickupDate' },
        { title: 'Pick Up Time', dataIndex: 'pickupTime' },
        { title: 'Pickup Address', dataIndex: 'pickupAddress' },
        { title: 'Destination', dataIndex: 'destination' },
        { title: 'Weight', dataIndex: 'weight' },
        { title: 'Prof Vehicle Type', dataIndex: 'profVehicleType' },
        // { title: 'Revenue', dataIndex: 'revenue' },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text) => (
                <span>
                    {text === 'ACTIVE' ? (
                        <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                    ) : text === 'Booked' ? (
                        <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Completed</>} />
                    ) : (
                        <Badge count={<><LuMinusCircle className="tabStatusIcon" style={{ color: '#FEA800', verticalAlign: 'middle', fontSize: '17px' }} /> Pending</>} />
                    )}
                </span>
            ),
        },
        {
            title: 'Action', dataIndex: '', key: 'x', render: (_, item) => <ActionButton onReviewClick={handleReviewClick} item={item} />,
        },
    ];
    const handleReviewClick = (itemId) => {
        const itemData = apiResponse.find(item => item.id === itemId);
        if (itemData) {
            setSelectedUserData(itemData);
            setItemId(itemId); // Set itemId here
            setIsAddConfirmModalVisible(true);
        } else {
            console.error('Item not found:', itemId);
        }
    };

    const handleCancelAddRequest = () => {
        setIsAddConfirmModalVisible(false);
    };
    const handleApproval = async () => {
        if (!selectedVehicle) {
            console.error("Vehicle not selected");
            return;
        }
        // setIsSubmitting(true); 
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/trip-requests/approve`,
                {
                    tripRequestId: itemId,
                    vehicleId: selectedVehicle 
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${token}`,
                    }
                }
            );
            console.log("Data submitted successfully:", response.data);
            Swal.fire({
                html: `
                <div style="padding: 45px 30px 25px 30px">
                    <img src="${successIcon}" alt="Success Icon"/>
                    <p style="color: black; font-size: 20px; font-weight: 500;">Request Approved</p>
                    <p style="color: rgba(0, 0, 0, 0.60); font-size: 16px; font-weight: 400;">Lorem ipsum dolor sit amet consectetur.<br/> Interdum diam amet tincidunt amet</p>
                    <div id="customButtonContainer"></div>
                </div>
            `,
                showConfirmButton: false,
                didOpen: () => {
                    const container = document.getElementById('customButtonContainer');
                    if (container) {
                        // Render the Ant Design button inside the container
                        const button = document.createElement('div');
                        container.appendChild(button);
                        ReactDOM.render(
                            <Button
                                style={{
                                    width: '74px',
                                    height: "32px",
                                    textAlign: "center",
                                    borderRadius: "15px",
                                    color: "black",
                                    paddingInline: '15px',
                                    border: "1px solid rgba(89, 108, 148, 0.12)",
                                }}
                                onClick={() => Swal.close()}>
                                OK
                            </Button>,
                            button
                        );
                    }
                }
            });
            setIsAddConfirmModalVisible(false);
        } catch (error) {
            console.error("Error submitting data:", error);
            const errorMessage =
                error.response?.data?.message || "Error submitting data";
            MessageRes("error", "Failed To Add", errorMessage);
        }
        //  finally {
        //     setIsSubmitting(false); // Hide spin
        // }
    };

    return (
        <div style={{ background: 'rgba(230, 230, 230, 0.40)', height: '100%', overflow: 'scroll' }}>
            <Navbar showStatusInfo={false} />
            <div className="tableContainer" style={{ fontWeight: '600' }}>
                <HeaderTable
                    title="All Requests"
                    buttonText="New Request"
                    onSearchChange={handleSearchChange}
                    activeCount={reqData.filter(v => v.status === 'ACTIVE').length}
                    inactiveCount={reqData.filter(v => v.status === 'INACTIVE').length}
                    bookedCount={reqData.filter(v => v.status === 'Booked').length}
                />
                <Spin size="large" spinning={loading}>
                    <div className="bigScreenSize" style={{ color: '#747474 !important' }}>
                        <Table
                            style={{
                                paddingInline: '20px', overflowX: 'auto', borderRadius: '0 0 12px 12px', backgroundColor: 'white'
                            }}
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={filteredData}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                total: totalItems,
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
                                onChange: handlePageChange,
                            }}
                        />
                    </div>
                </Spin>
                <div className="smallScreenSize" style={{ background: '#F7F8FB', padding: '0px 20px 20px 20px' }} >
                    <CardView data={reqData} currentPage={currentPage} pageSize={pageSize} onViewDetails={handleViewDetails} />
                    <div className="mobilePagination">
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={totalItems}
                            showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`}
                            onChange={handlePageChange}
                            style={{ textAlign: 'center' }}
                        />
                    </div>
                    <DetailModal isVisible={isModalVisible} handleClose={handleCloseModal} content={modalItem && (
                        <div>
                            <p style={{ margin: "0", fontSize: '20px', fontWeight: '500' }}>Details</p>
                            <span style={{ display: 'block', height: '1px', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.06)', margin: '20px 0' }}></span>
                            {/* <span className="infoDetails">
                                <p>Customer Name</p>
                                <p className="cardDetailHeading">{modalItem.customerName}</p>
                            </span> */}
                            <span className="cardDetails">
                                <p>Pick Up Date</p>
                                <p className="cardDetailHeading"> {modalItem.pickupDate}</p >
                            </span>
                            <span className="cardDetails">
                                <p>Pick Up Time</p>
                                <p className="cardDetailHeading">{modalItem.pickupTime}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Pick Up Address</p>
                                <p className="cardDetailHeading">{modalItem.pickupAddress}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Destination</p>
                                <p className="cardDetailHeading">{modalItem.destination}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Weight</p>
                                <p className="cardDetailHeading">{modalItem.weight}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Prof Vehicle Type</p>
                                <p className="cardDetailHeading">{modalItem.profVehicleType}</p>
                            </span>
                            {/* <span className="cardDetails">
                                <p>Revenue</p>
                                <p className="cardDetailHeading">{modalItem.revenue}</p>
                            </span> */}
                            {/* <span className="cardDetails">
                                <p>Change Status</p>
                                <Select
                                    value={modalItem.status}
                                    style={{ width: 'fit-content' }}
                                    onChange={handleStatusChange}
                                >
                                    <Select.Option value="Approve">
                                        <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Approve</>} />
                                    </Select.Option>
                                    <Select.Option value="Completed">
                                        <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Completed</>} />
                                    </Select.Option>
                                    <Select.Option value="Pending">
                                        <Badge count={<><LuMinusCircle style={{ color: '#FEA800', verticalAlign: 'middle', fontSize: '16px' }} /> Pending</>} />
                                    </Select.Option>
                                </Select>
                            </span> */}
                            <span >
                                <ActionButton />
                            </span>
                        </div>
                    )} />
                    <ConfirmationModal isVisible={isConfirmModalVisible} handleClose={handleCancelStatusChange} content={
                        <div>
                            <p style={{ margin: "0 0 12px 0", fontSize: '20px', fontWeight: '500' }}>Are you sure?</p>
                            <p>Are You Sure You Want To Change The Status From <strong>{modalItem?.status}</strong> To <strong>{statusToChange}</strong>?</p>
                        </div>
                    } actions={[
                        <Button
                            style={{
                                marginTop: '30px',
                                width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "black",
                                border: "1px solid rgba(89, 108, 148, 0.12)",
                            }}
                            key="cancel" onClick={handleCancelStatusChange}>Cancel</Button>,
                        <Button
                            style={{
                                marginTop: '30px',
                                width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "white",
                                background: '#001B72',
                                border: "none",
                            }}
                            key="confirm" type="primary" onClick={handleConfirmStatusChange}>Confirm</Button>
                    ]} />
                </div>
            </div>
            <ConfirmationModal
                isVisible={isAddConfirmModalVisible}
                closeBtn={true}
                // onConfirm={handleConfirmAddTransporter}
                handleClose={handleCancelAddRequest}
                content={
                    <div className="addVehicleMain" >
                        <div>
                            <p style={{ margin: "0", fontSize: '20px', fontWeight: '500' }}>Trip Request Review</p>
                        </div>
                        <div className="responsiveHrLine" style={{ height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.06)', width: '100%' }}></div>
                        <p className="userInfoHeading deliveryInfoDivHead" style={{ fontSize: '12px', fontWeight: '400', lineHeight: '22px', color: 'rgba(0, 0, 0, 0.88)', }}>USER INFO</p>
                        <div className="userInfoReqMain" style={{ background: '#F6F7F8', borderRadius: '12px', padding: '12px 15px' }} >
                            <div className="userInfoReq" style={{ textAlign: 'center', height: 'fit-content', margin: "auto 0", background: '#FFF', borderRadius: '8px', boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 8px 16px 0px rgba(0, 0, 0, 0.08)' }}>
                                {/* <span className="userInfoImgReq" style={{ display: 'block', borderRadius: '50%', width: '28px', height: '28px', background: '#D9D9D9' }}></span> */}
                                <div className="userInforNameReq">
                                    <p style={{ marginBottom: '0', fontSize: '13px', fontWeight: '500', color: 'rgba(0, 0, 0, 0.88)', }}>Transporter Name</p>
                                    <p style={{ margin: '0', fontSize: '12px', fontWeight: '400', color: '#8692A6' }}>+971 55 869 1856</p>
                                </div>
                            </div>
                            <div className="userInfoData">
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <p style={{ margin: '0', fontSize: '12px', fontWeight: '400', color: '#8692A6' }}>Pickup Date</p>
                                        <p style={{ marginTop: '0', fontSize: '13px', fontWeight: '500', color: 'rgba(0, 0, 0, 0.88)', }}>{formattedDate}</p>
                                    </div>
                                    <div>
                                        <p style={{ margin: '0', fontSize: '12px', fontWeight: '400', color: '#8692A6' }}>Pickup Time</p>
                                        <p style={{ marginTop: '0', fontSize: '13px', fontWeight: '500', color: 'rgba(0, 0, 0, 0.88)', }}>{formattedTime}</p>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <div>
                                        <p style={{ margin: '0', fontSize: '12px', fontWeight: '400', color: '#8692A6' }}>Pickup Date</p>
                                        <p style={{ marginTop: '0', fontSize: '13px', fontWeight: '500', color: 'rgba(0, 0, 0, 0.88)', }}>{formattedDate}</p>
                                    </div>
                                    <div>
                                        <p style={{ margin: '0', fontSize: '12px', fontWeight: '400', color: '#8692A6' }}>Pickup Time</p>
                                        <p style={{ marginTop: '0', fontSize: '13px', fontWeight: '500', color: 'rgba(0, 0, 0, 0.88)', }}>{formattedTime}</p>
                                    </div>
                                </div>

                            </div>
                            <div style={{ height: 'inherit', display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <p style={{ margin: '0', fontSize: '12px', fontWeight: '400', color: '#8692A6' }}>Weight</p>
                                    <p style={{ marginTop: '0', fontSize: '13px', fontWeight: '500', color: 'rgba(0, 0, 0, 0.88)', }}>{selectedUserData?.vehicleType?.capacity} Ton</p>
                                </div>
                            </div>

                        </div>
                        <div className="infoDetailMain">
                            <div className="deliveryInfoDiv" >
                                <p className="deliveryInfoDivHead" style={{ fontSize: '12px', fontWeight: '400', lineHeight: '22px', color: 'rgba(0, 0, 0, 0.88)', }}>DELIVERY INFO</p>
                                <p style={{ marginBottom: '6px' }}>Container Number
                                    {/* <span style={{ color: '#FF4D4F' }}>*</span> */}
                                </p>
                                <small>{selectedUserData?.key}</small>
                                <p style={{ marginBottom: '6px' }}>Vehicle <span style={{ color: '#FF4D4F' }}>*</span></p>
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(value) => setSelectedVehicle(value)}
                                    placeholder="Choose Vehicle"
                                    options={[
                                        { value: 1, label: 'Option 1' },
                                        { value: 2, label: 'Option 2' },
                                    ]}
                                />
                                <p style={{ marginBottom: '6px' }}>Driver <span style={{ color: '#FF4D4F' }}>*</span></p>
                                <small>Imran Khan Ali</small>
                            </div>
                            <div className="requestInfoDiv">
                                <p style={{ margin: "0 0 12px 0", fontSize: '12px', fontWeight: '400', lineHeight: '22px', color: 'rgba(0, 0, 0, 0.88)', }}>REQUEST INFO</p>
                                <div style={{ padding: '12px', background: '#F6F7F8', borderRadius: '12px', marginBottom: '8px' }}>
                                    <span className="infoDetails">
                                        <p style={{ color: 'rgba(0, 0, 0, 0.88)', opacity: '0.6', fontWeight: '400' }}>PickUp Location</p>
                                        <p style={{ fontWeight: '500', color: 'rgba(0,0,0,0.88)' }}>{selectedUserData?.fromZone?.name}</p >
                                    </span>
                                    <span className="infoDetails">
                                        <p style={{ color: 'rgba(0, 0, 0, 0.88)', opacity: '0.6', fontWeight: '400' }}>Pick Up Time</p>
                                        <p style={{ fontWeight: '500', color: 'rgba(0,0,0,0.88)' }}>{formattedTime}</p>
                                    </span>
                                    <span className="infoDetails">
                                        <p style={{ color: 'rgba(0, 0, 0, 0.88)', opacity: '0.6', fontWeight: '400' }}>Pick Up Address</p>
                                        <p style={{ textAlign: 'end', fontWeight: '500', color: 'rgba(0,0,0,0.88)' }}>{selectedUserData?.fromAddressLine1},<br />{selectedUserData?.fromAddressLine2}</p>
                                    </span>
                                    <span className="infoDetails">
                                        <p style={{ color: 'rgba(0, 0, 0, 0.88)', opacity: '0.6', fontWeight: '400' }}>Destination</p>
                                        <p style={{ fontWeight: '500', color: 'rgba(0,0,0,0.88)' }}>{selectedUserData?.toZone?.name}</p>
                                    </span>
                                    <span className="infoDetails">
                                        <p style={{ color: 'rgba(0, 0, 0, 0.88)', opacity: '0.6', fontWeight: '400' }}>Weight</p>
                                        <p style={{ fontWeight: '500', color: 'rgba(0,0,0,0.88)' }}>{selectedUserData?.vehicleType?.capacity} Ton</p>
                                    </span>
                                </div>
                                <div style={{ padding: '12px', background: '#F6F7F8', borderRadius: '12px', marginBottom: '8px' }}>
                                    <span className="infoDetails">
                                        <p style={{ color: 'rgba(0, 0, 0, 0.88)', opacity: '0.6', fontWeight: '400' }}>Delivery Location</p>
                                        <p style={{ textAlign: 'end', fontWeight: '500', color: 'rgba(0,0,0,0.88)' }}>{selectedUserData?.toAddressLine1}, {selectedUserData?.toAddressLine2}</p >
                                    </span>
                                    <span className="infoDetails">
                                        <p style={{ color: 'rgba(0, 0, 0, 0.88)', opacity: '0.6', fontWeight: '400' }}>Building No</p>
                                        <p style={{ fontWeight: '500', color: 'rgba(0,0,0,0.88)' }}>102</p>
                                    </span>
                                    <span className="infoDetails">
                                        <p style={{ color: 'rgba(0, 0, 0, 0.88)', opacity: '0.6', fontWeight: '400' }}>Building Name</p>
                                        <p style={{ fontWeight: '500', color: 'rgba(0,0,0,0.88)' }}>XYZ</p>
                                    </span>
                                    <span className="infoDetails">
                                        <p style={{ color: 'rgba(0, 0, 0, 0.88)', opacity: '0.6', fontWeight: '400' }}>Street</p>
                                        <p style={{ fontWeight: '500', color: 'rgba(0,0,0,0.88)' }}>Lorem Ipsum</p>
                                    </span>
                                    <span className="infoDetails">
                                        <p style={{ color: 'rgba(0, 0, 0, 0.88)', opacity: '0.6', fontWeight: '400' }}>Address</p>
                                        <p style={{ textAlign: 'end', fontWeight: '500', color: 'rgba(0,0,0,0.88)' }}>{selectedUserData?.toAddressLine1}, {selectedUserData?.toAddressLine2}</p>
                                    </span>
                                </div>

                            </div>

                        </div>

                    </div>
                } actions={[
                    <div className="addFooterMainDiv" style={{ display: 'flex', gap: '12px', marginLeft: 'auto', width: 'max-content' }}>
                        <Button
                            className="addModalFooterBtn"
                            style={{
                                // width: '48%',
                                marginTop: isMobile ? '20px' : '50px',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "black",
                                border: "1px solid rgba(89, 108, 148, 0.12)",
                            }}
                            key="cancel" onClick={handleCancelAddRequest}>Cancel</Button>
                        <Button
                            onClick={handleApproval}
                            className="addModalFooterBtn"
                            style={{
                                marginTop: isMobile ? '20px' : '50px',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "white",
                                background: '#00C8F7',
                                border: "none",

                            }}
                            key="confirm" type="primary" >Approve</Button>
                    </div>
                ]}
            />
        </div>
    );
}
