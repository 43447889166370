import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Badge,
  Dropdown,
  Menu,
  Pagination,
  Input,
  Select,
  Button,
  Spin,
} from "antd";
import Navbar from "../../Components/Navbar";
import { LuCheckCircle2 } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import HeaderTable from "../../Components/HeaderTable";
import "../NavlinksTable.css";
import { IoIosMore, IoMdCheckmark } from "react-icons/io";
import ViewButton from "../../Assets/ViewButton";
import DetailModal from "../../Assets/DetailModal";
import ConfirmationModal from "../../Assets/ConfirmationModal";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import successIcon from "../../Assets/Images/successIcon.png";
import MessageRes from "../../Assets/MessageRes";
const requiredFields = ["vehicle", "from", "to", "distance", "cost"];
const ActionButton = ({ status }) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Active{" "}
          {status === "Active" ? (
            <IoMdCheckmark
              style={{
                color: "black",
                marginLeft: "20px",
                verticalAlign: "middle",
              }}
            />
          ) : null}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Inactive{" "}
          {status === "Inactive" ? (
            <IoMdCheckmark
              style={{
                color: "black",
                marginLeft: "20px",
                verticalAlign: "middle",
              }}
            />
          ) : null}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Edit
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="d-flex">
      <Dropdown overlay={menu}>
        <IoIosMore />
      </Dropdown>
    </div>
  );
};
const columns = [
  { title: "#", dataIndex: "no" },
  { title: `Key`, dataIndex: "key" },
  { title: "Starting From", dataIndex: "startFrom" },
  { title: "Destination", dataIndex: "destination" },
  { title: "Vehicle Type", dataIndex: "vehicleType" },
  { title: "Rate", dataIndex: "rate" },
  { title: "Distance", dataIndex: "distance" },
  {
    title: "Action",
    key: "x",
    dataIndex: "status",
    render: (status) => <ActionButton status={status} />,
  },
];
const generateData = (profileRates) => {
  return profileRates.map((profileRate, index) => ({
    no: (index + 1).toString().padStart(2, "0"),
    key: `${profileRate.key}`,
    startFrom: `${profileRate.fromZone.name}`,
    destination: `${profileRate.toZone.name}`,
    vehicleType: profileRate.vehicleType.name,
    rate: profileRate.cost,
    distance: profileRate.distance,
  }));
};
const CardView = ({ data, currentPage, pageSize, onViewDetails }) => {
  const currentData = data;
  return (
    <div className="cardContainer">
      {currentData.length > 0 ? (
        currentData.map((item) => (
          <div key={item.no} className="card">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600px",
                  margin: "0 0 10px 0",
                }}
              >
                {item.key}
              </p>
              {item.status === "Active" ? (
                <Badge
                  count={
                    <>
                      <LuCheckCircle2
                        style={{
                          color: "green",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />{" "}
                      Active
                    </>
                  }
                />
              ) : item.status === "Booked" ? (
                <Badge
                  count={
                    <>
                      <LuCheckCircle2
                        style={{
                          color: "#00C8F7",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />{" "}
                      Booked
                    </>
                  }
                />
              ) : (
                <Badge
                  count={
                    <>
                      <RxCrossCircled
                        style={{
                          color: "#C01111",
                          verticalAlign: "middle",
                          fontSize: "16px",
                        }}
                      />{" "}
                      Inactive
                    </>
                  }
                />
              )}
            </div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500px",
                margin: "10px 0 20px 0",
              }}
            >
              {item.vehicleType}
            </p>
            <ViewButton onClick={() => onViewDetails(item)} />
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

const initPayload = {
  key: "",
  fromZoneId: null,
  toZoneId: null,
  vehicleTypeId: null,
  distance: 0,
  cost: 0,
};
export default function RateProfile() {
  const [payload, setPayload] = useState(initPayload);
  const [zones, setZones] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [statusToChange, setStatusToChange] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [profileRates, setProfileRates] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isAddConfirmModalVisible, setIsAddConfirmModalVisible] =
    useState(false);
  const [filterParams, setFilterParams] = useState({
    toZoneId: "",
    fromZoneId: "",
    vehicleTypeId: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };
    if (value.trim() === "" && requiredFields.includes(name)) {
      newErrors[name] = `${name} is required`;
    } else {
      delete newErrors[name];
    }
    setErrors(newErrors);
    const isAnyError = Object.keys(newErrors).length > 0;
    setIsSaveDisabled(isAnyError);
  };

  const handleSelectChange = (value, option) => {
    const name = option.name;
    let newErrors = { ...errors };
    if (!value && requiredFields.includes(name)) {
      newErrors[name] = `${name} is required`;
    } else {
      delete newErrors[name];
    }
    setErrors(newErrors);
    const isAnyError = Object.keys(newErrors).length > 0;
    setIsSaveDisabled(isAnyError);
  };

  const fetchVehicleTypeMaster = async () => {
    const token = sessionStorage.getItem("token");
    const params = new URLSearchParams({
      page: 0,
      size: 100,
      sort: "createdDate,desc",
    });
    setLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/vehicle-types/list?${params.toString()}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setVehicleTypes(data.content);
      })
      .catch((error) => {
        console.error("Error fetching Data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the request completes
      });
  };

  const fetchZoneMaster = async () => {
    const token = sessionStorage.getItem("token");
    const params = new URLSearchParams({
      page: 0,
      size: 100,
      sort: "createdDate,desc",
    });
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/zones/list?${params.toString()}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setZones(data.content);
      })
      .catch((error) => {
        console.error("Error fetching Data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the request completes
      });
  };

  useEffect(() => {
    fetchVehicleTypeMaster();
    fetchZoneMaster();
  }, []);

  const handleSearchChange = (key, value) => {
    setFilterParams({ ...filterParams, [key]: value });
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchRateProfile(currentPage - 1, pageSize);
  }, [
    currentPage,
    pageSize,
    filterParams.fromZoneId,
    filterParams.toZoneId,
    filterParams.vehicleTypeId,
  ]);

  const fetchRateProfile = async (page = 0, size = pageSize) => {
    const { fromZoneId, toZoneId, vehicleTypeId } = filterParams;
    const token = sessionStorage.getItem("token");
    const params = new URLSearchParams({
      page: page,
      size: size,
      sort: "createdDate,desc",
      ...(fromZoneId ? { fromZoneId } : {}),
      ...(toZoneId ? { toZoneId } : {}),
      ...(vehicleTypeId ? { vehicleTypeId } : {}),
    });
    setLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/cost-profiles/list?${params.toString()}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setProfileRates(generateData(data.content));
        setTotalItems(data.page.totalElements);
      })
      .catch((error) => {
        console.error("Error fetching Data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the request completes
      });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleViewDetails = (item) => {
    setModalItem(item);
    setIsModalVisible(true);
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalItem(null);
  };
  const handleStatusChange = (value) => {
    setStatusToChange(value);
    setIsConfirmModalVisible(true);
  };
  const handleConfirmStatusChange = () => {
    setModalItem({ ...modalItem, status: statusToChange });
    setIsConfirmModalVisible(false);
  };
  const handleCancelStatusChange = () => {
    setIsConfirmModalVisible(false);
  };
  const handleAddRateProfile = () => {
    setIsAddConfirmModalVisible(true);
  };
  const handleCancelAddRateProfile = () => {
    setIsAddConfirmModalVisible(false);
  };

  const successModal = () => {
    setIsAddConfirmModalVisible(false);
    Swal.fire({
      html: `
                              <div style="padding: 45px 30px 25px 30px">
                                  <img src="${successIcon}" alt="Success Icon"/>
                                  <p style="color: black; font-size: 20px; font-weight: 500;">Success</p>
                                  <p style="color: rgba(0, 0, 0, 0.60); font-size: 16px; font-weight: 400;">Rate Profile Added Successfully</p>
                                  <div id="customButtonContainer"></div>
                              </div>
                          `,
      showConfirmButton: false,
      didOpen: () => {
        const container = document.getElementById("customButtonContainer");
        if (container) {
          // Render the Ant Design button inside the container
          const button = document.createElement("div");
          container.appendChild(button);
          ReactDOM.render(
            <Button
              style={{
                width: "74px",
                height: "32px",
                textAlign: "center",
                borderRadius: "15px",
                color: "black",
                paddingInline: "15px",
                border: "1px solid rgba(89, 108, 148, 0.12)",
              }}
              onClick={() => {
                Swal.close();
              }}
            >
              OK
            </Button>,
            button
          );
        }
      },
    });
  };

  return (
    <div
      style={{
        background: "rgba(230, 230, 230, 0.40)",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <Navbar showStatusInfo={false} />
      <div className="tableContainer" style={{ fontWeight: "600" }}>
        <HeaderTable
          title="Rate Profile"
          buttonText="Add Rate Profile"
          onSearchChange={handleSearchChange}
          onAddClick={handleAddRateProfile}
          activeCount={profileRates.filter((v) => v.status === "ACTIVE").length}
          inactiveCount={
            profileRates.filter((v) => v.status === "Inactive").length
          }
          bookedCount={profileRates.filter((v) => v.status === "Booked").length}
          isRateProfile={true}
        />
        <Spin size="large" spinning={loading}>
          <div
            className="bigScreenSize"
            style={{ color: "#747474 !important" }}
          >
            <Table
              style={{
                paddingInline: "20px",
                overflowX: "auto",
                borderRadius: "0 0 12px 12px",
                backgroundColor: "white",
              }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={profileRates}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} items`,
                onChange: handlePageChange,
              }}
            />
          </div>
        </Spin>
        <div
          className="smallScreenSize"
          style={{ background: "#F7F8FB", padding: "0px 20px 20px 20px" }}
        >
          <CardView
            data={profileRates}
            currentPage={currentPage}
            pageSize={pageSize}
            onViewDetails={handleViewDetails}
          />
          <div className="mobilePagination">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalItems}
              showTotal={(total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`
              }
              onChange={handlePageChange}
              style={{ textAlign: "center" }}
            />
          </div>
          <DetailModal
            isVisible={isModalVisible}
            handleClose={handleCloseModal}
            content={
              modalItem && (
                <div>
                  <p
                    style={{ margin: "0", fontSize: "20px", fontWeight: "500" }}
                  >
                    Details
                  </p>
                  <span
                    style={{
                      display: "block",
                      height: "1px",
                      width: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.06)",
                      margin: "20px 0",
                    }}
                  ></span>
                  <span className="cardDetails">
                    <p>Profile Name</p>
                    <p className="cardDetailHeading">{modalItem.profileName}</p>
                  </span>
                  <span className="cardDetails">
                    <p>Starting From</p>
                    <p className="cardDetailHeading"> {modalItem.startFrom}</p>
                  </span>
                  <span className="cardDetails">
                    <p>Destination</p>
                    <p className="cardDetailHeading">{modalItem.destination}</p>
                  </span>
                  <span className="cardDetails">
                    <p>Vehicle Type</p>
                    <p className="cardDetailHeading">{modalItem.vehicleType}</p>
                  </span>
                  <span className="cardDetails">
                    <p>Rate</p>
                    <p className="cardDetailHeading">{modalItem.rate}</p>
                  </span>
                  {/* <span className="cardDetails">
                                <p>Change Status</p>
                                <Select
                                    value={modalItem.status}
                                    style={{ width: 'fit-content' }}
                                    onChange={handleStatusChange}
                                >
                                    <Select.Option value="Active">
                                        <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                                    </Select.Option>
                                    <Select.Option value="Inactive">
                                        <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Inactive</>} />
                                    </Select.Option>
                                    <Select.Option value="Booked">
                                        <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
                                    </Select.Option>
                                </Select>
                            </span> */}
                </div>
              )
            }
          />
          <ConfirmationModal
            isVisible={isConfirmModalVisible}
            handleClose={handleCancelStatusChange}
            content={
              <div>
                <p
                  style={{
                    margin: "0 0 12px 0",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                >
                  Are you sure?
                </p>
                <p>
                  Are You Sure You Want To Change The Status From{" "}
                  <strong>{modalItem?.status}</strong> To{" "}
                  <strong>{statusToChange}</strong>?
                </p>
              </div>
            }
            actions={[
              <Button
                style={{
                  marginTop: "30px",
                  width: "48%",
                  height: "32px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "black",
                  border: "1px solid rgba(89, 108, 148, 0.12)",
                }}
                key="cancel"
                onClick={handleCancelStatusChange}
              >
                Cancel
              </Button>,
              <Button
                style={{
                  marginTop: "30px",
                  width: "48%",
                  height: "32px",
                  textAlign: "center",
                  borderRadius: "50px",
                  color: "white",
                  background: "#001B72",
                  border: "none",
                }}
                key="confirm"
                type="primary"
                onClick={handleConfirmStatusChange}
              >
                Confirm
              </Button>,
            ]}
          />
        </div>
      </div>
      <ConfirmationModal
        isVisible={isAddConfirmModalVisible}
        closeBtn={true}
        // onConfirm={handleConfirmAddTransporter}
        handleClose={handleCancelAddRateProfile}
        content={
          <div className="addVehicleMain addvehicleMasterMain">
            <div>
              <p style={{ margin: "0", fontSize: "20px", fontWeight: "500" }}>
                Add Rate Profile
              </p>
            </div>
            <div
              className="responsiveHrLine"
              style={{
                height: "1px",
                backgroundColor: "rgba(0, 0, 0, 0.06)",
                width: "100%",
                margin: "20px 0",
              }}
            ></div>
            <div className="addTripModalContent" style={{ width: "100%" }}>
              <div>
                <p>
                  Key <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="Enter Key"
                  name="key"
                  onChange={(e) =>
                    setPayload({ ...payload, [e.target.name]: e.target.value })
                  }
                />
              </div>
              <div>
                <p>
                  Vehicle Type <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Select
                  style={{ width: "100%" }}
                  name="vehicleTypeId"
                  placeholder="Choose Vehicle"
                  onChange={(value) =>
                    setPayload({ ...payload, vehicleTypeId: value })
                  }
                  options={vehicleTypes.map((v) => ({
                    label: v.name,
                    value: v.id,
                  }))}
                />
                {errors.fieldName1 && (
                  <span className="error-message">{errors.vehicle}</span>
                )}
              </div>
              <div>
                <p>
                  From <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Select
                  name="fromZoneId"
                  style={{ width: "100%" }}
                  placeholder="Choose Zone"
                  onChange={(value) =>
                    setPayload({ ...payload, fromZoneId: value })
                  }
                  options={zones.map((v) => ({ label: v.name, value: v.id }))}
                />
                {errors.fieldName1 && (
                  <span className="error-message">{errors.from}</span>
                )}
              </div>
              <div>
                <p>
                  To <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Select
                  name="toZoneId"
                  style={{ width: "100%" }}
                  placeholder="Choose Zone"
                  onChange={(value) =>
                    setPayload({ ...payload, toZoneId: value })
                  }
                  options={zones.map((v) => ({ label: v.name, value: v.id }))}
                />
                {errors.fieldName1 && (
                  <span className="error-message">{errors.to}</span>
                )}
              </div>
              <div>
                <p>
                  Distance <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="174 Km approx"
                  type="number"
                  onChange={(e) =>
                    setPayload({ ...payload, [e.target.name]: e.target.value })
                  }
                  name="distance"
                />
                {errors.fieldName1 && (
                  <span className="error-message">{errors.distance}</span>
                )}
              </div>
              <div>
                <p>
                  Cost <span style={{ color: "#FF4D4F" }}>*</span>
                </p>
                <Input
                  placeholder="AED Cost"
                  onChange={(e) =>
                    setPayload({ ...payload, [e.target.name]: e.target.value })
                  }
                  name="cost"
                  type="number"
                />
                {errors.fieldName1 && (
                  <span className="error-message">{errors.cost}</span>
                )}
              </div>
            </div>
          </div>
        }
        actions={[
          <div
            className="addFooterMainDiv"
            style={{
              display: "flex",
              gap: "12px",
              marginLeft: "auto",
              width: "max-content",
            }}
          >
            <Button
              className="addModalFooterBtn"
              style={{
                // width: '48%',
                height: "32px",
                textAlign: "center",
                borderRadius: "50px",
                color: "black",
                border: "1px solid rgba(89, 108, 148, 0.12)",
              }}
              key="cancel"
              onClick={handleCancelAddRateProfile}
            >
              Cancel
            </Button>
            <Button
              className="addModalFooterBtn"
              style={{
                height: "32px",
                textAlign: "center",
                borderRadius: "50px",
                color: "white",
                background: "#00C8F7",
                border: "none",
              }}
              //   disabled={isSaveDisabled}
              disabled={
                !payload.toZoneId ||
                !payload.fromZoneId ||
                !payload.key ||
                !payload.vehicleTypeId ||
                !payload.distance ||
                !payload.cost
              }
              key="confirm"
              type="primary"
              onClick={async () => {
                try {
                  const token = sessionStorage.getItem("token");
                  await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/cost-profiles`,
                    payload,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `${token}`,
                      },
                    }
                  );
                  fetchRateProfile();
                  setPayload(initPayload);
                  successModal();
                } catch (error) {
                  console.error("Error submitting data:", error);
                  const errorMessage =
                    error.response?.data?.message || "Error submitting data";
                  MessageRes("error", "Failed To Add", errorMessage);
                }
              }}
            >
              Save now
            </Button>
          </div>,
        ]}
      />
    </div>
  );
}
