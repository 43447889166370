import React from 'react';
import { Modal } from 'antd';
import '../Components/HeaderTable.css'
const ConfirmationModal = ({ isVisible, handleClose, content, actions, closeBtn,confirmModalMargin }) => {
    return (
        <Modal className=' confirmationModal' visible={isVisible} style={{marginTop:confirmModalMargin}}
        // style={{overflow:'scroll'}}
        closable={closeBtn}
         onCancel={handleClose} footer={actions} >
            {content}
        </Modal>
    );
};

export default ConfirmationModal;
