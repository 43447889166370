import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Badge, Dropdown, Menu, Pagination, Input, Select, Button, Spin } from 'antd';
import { FiPlus } from "react-icons/fi";
import Navbar from "../../Components/Navbar";
import { LuCheckCircle2 } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import HeaderTable from '../../Components/HeaderTable';
import '../NavlinksTable.css';
import { IoIosMore, IoMdCheckmark } from "react-icons/io";
import ViewButton from "../../Assets/ViewButton";
import DetailModal from "../../Assets/DetailModal";
import ConfirmationModal from "../../Assets/ConfirmationModal";
import Swal from 'sweetalert2';
import ReactDOM from 'react-dom';
import successIcon from "../../Assets/Images/successIcon.png"
const ActionButton = ({ status }) => {
    const menu = (
        <Menu>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">
                    Active {status === 'Active' ? <IoMdCheckmark style={{ color: 'black', marginLeft: '20px', verticalAlign: 'middle' }} /> : null}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">
                    Inactive {status === 'Inactive' ? <IoMdCheckmark style={{ color: 'black', marginLeft: '20px', verticalAlign: 'middle' }} /> : null}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">Edit</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="d-flex">
            <Dropdown overlay={menu}>
                <IoIosMore />
            </Dropdown>
        </div>
    );
};

const columns = [
    { title: '#', dataIndex: 'key' },
    { title: `Zone Name`, dataIndex: 'zoneName' },
    { title: 'Latitude', dataIndex: 'latitude' },
    { title: 'Longitude', dataIndex: 'longitude' },
    // { title: 'Time Restrictions', dataIndex: 'timeRestriction' },
    // {
    //     title: 'Status',
    //     dataIndex: 'status',
    //     render: (text) => (
    //         <span>
    //             {text === 'Active' ? (
    //                 <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
    //             ) : text === 'Booked' ? (
    //                 <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
    //             ) : (
    //                 <Badge count={<><RxCrossCircled className="tabStatusIcon" style={{ color: 'red', verticalAlign: 'middle', fontSize: '17px' }} /> Inactive</>} />
    //             )}
    //         </span>
    //     ),
    // },
    {
        title: 'Action',
        key: 'x',
        dataIndex: 'status',
        render: (status) => <ActionButton status={status} />,
    },
];

const generateData = (zones, statuses) => {
    return zones.map((zone, index) => ({
        key: zone.key,
        zoneName: zone.name || '', // Ensure zoneName is a string
        from: `${zone.latitude} - ${zone.longitude}`, // Raw string for filtering
        to: `${zone.latitude} - ${zone.longitude}`, // Raw string for filtering
        latitude: (
            <span>
                <span style={{ color: '#666666' }}>
                    {zone.latitude}
                </span>
            </span>
        ),
        longitude: (
            <span>
                <span style={{ color: '#666666' }}>
                    {zone.longitude}
                </span>
            </span>
        ),
        // timeRestriction:
        //     <span style={{ display: 'flex', alignItems: 'center' }}>
        //         10AM - 12PM
        //         <span style={{ display: 'block', height: '22px', width: '1px', marginInline: '10px', background: 'rgba(0, 0, 0, 0.06)' }}></span>05PM - 09PM

        //     </span>,
        // status: status,
    }));
};
// const statuses = [
//     'Active',
//     'Inactive',
//     'Active',
//     'Inactive',
//     'Booked',
//     'Inactive',
//     'Active',
//     'Inactive',
//     'Active',
//     'Booked',
//     'Active',
// ];

// const data = generateData(statuses);
// const activeCount = statuses.filter(status => status === 'Active').length;
// const inactiveCount = statuses.filter(status => status === 'Inactive').length;
// const bookedCount = statuses.filter(status => status === 'Booked').length;
const CardView = ({ data, currentPage, pageSize, onViewDetails }) => {
    // const startIndex = (currentPage - 1) * pageSize;
    // const endIndex = startIndex + pageSize;
    const currentData = data;
    return (
        <div className="cardContainer">
            {currentData.length > 0 ? (
                currentData.map(item => (
                    <div key={item.no} className="card">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <p style={{ fontSize: '18px', fontWeight: '600px', margin: '0 0 10px 0' }}>{item.zoneName}</p>
                            {item.status === 'Active' ? (
                                <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                            ) : item.status === 'Booked' ? (
                                <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
                            ) : (
                                <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Inactive</>} />
                            )}
                        </div>
                        <p style={{ fontSize: '14px', fontWeight: '500px', margin: '10px 0 20px 0' }}>Location Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Location Name </p>
                        <ViewButton onClick={() => onViewDetails(item)} />
                    </div>
                ))
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};
export default function ZoneMaster() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalItem, setModalItem] = useState(null);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [statusToChange, setStatusToChange] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [zones, setZones] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [isAddConfirmModalVisible, setIsAddConfirmModalVisible] = useState(false);
    const [menus, setMenus] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const filteredData = zones.filter(item =>
        item.zoneName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.from.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.to.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    const addMenu = () => {
        setMenus([...menus, menus.length + 1]);
    };
    const removeMenu = (indexToRemove) => {
        setMenus(menus.filter((_, index) => index !== indexToRemove));
    };
    useEffect(() => {
        fetchZoneMaster(currentPage - 1, pageSize);
    }, [currentPage, pageSize]);

    const fetchZoneMaster = async (page, size) => {
        const token = sessionStorage.getItem("token");
        const params = new URLSearchParams({
            page: page,
            size: size,
            sort: "createdDate,desc",
        });
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_BASE_URL
                }/zones/list?${params.toString()}`,
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            )
            .then((response) => {
                const data = response.data;
                setZones(generateData(data.content));
                setTotalItems(data.page.totalElements);
            })
            .catch((error) => {
                console.error("Error fetching Data:", error);
            }).finally(() => {
                setLoading(false); // Set loading to false after the request completes
            });
    };
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };
    const handleViewDetails = (item) => {
        setModalItem(item);
        setIsModalVisible(true);
    };
    const handleCloseModal = () => {
        setIsModalVisible(false);
        setModalItem(null);
    };
    const handleStatusChange = (value) => {
        setStatusToChange(value);
        setIsConfirmModalVisible(true);
    };
    const handleConfirmStatusChange = () => {
        setModalItem({ ...modalItem, status: statusToChange });
        setIsConfirmModalVisible(false);
    };
    const handleCancelStatusChange = () => {
        setIsConfirmModalVisible(false);
    };
    const handleAddZoneMaster = () => {
        setIsAddConfirmModalVisible(true);
    };
    const handleCancelAddZoneMaster = () => {
        setIsAddConfirmModalVisible(false);
    };
    const menu = (
        <>
            <div className="AddZoneMaster">
                <div style={{ height: '40px', width: '40px', textAlign: 'center', border: '1px solid #E6E6E6', borderRadius: '10px', marginRight: '12px' }}>
                    <img
                        style={{ marginTop: '25%' }}
                        src={require('../../Assets/Images/map-pin.png')}
                        alt="User"
                    />
                </div>
                <div>
                    <p style={{ margin: '0', color: ' rgba(0, 0, 0, 0.88)', fontWeight: '500', fontSize: "16px" }}>Jebel Ali Port Zone</p>
                    <small style={{ color: '#8692A6' }}>11:00 - 13:00</small>
                </div>
                <Button
                    className="zoneAddButton"
                    style={{
                        height: '32px',
                        textAlign: 'center',
                        borderRadius: '50px',
                        color: 'black',
                        border: '1px solid rgba(89, 108, 148, 0.12)',
                        marginLeft: 'auto',
                    }}
                    icon={<FiPlus style={{ fontSize: '15px' }} />}
                    onClick={addMenu}
                >
                    Add
                </Button>
            </div>
            <div className="hrLineZone"
                style={{ height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.06)', width: '90%', margin: '0px 12px 0 12px' }}
            ></div>
        </>
    );

    const menuList = (
        <div style={{ background: '#FFF', borderRadius: '8px', boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)' }}>
            {Array.from({ length: 3 }, (_, index) => (
                <div key={index}>{menu}</div>
            ))}
        </div>
    );

    return (
        <div style={{ background: 'rgba(230, 230, 230, 0.40)', height: '100%', overflow: 'scroll' }}>
            <Navbar showStatusInfo={false} />
            <div className="tableContainer" style={{ fontWeight: '600' }}>
                <HeaderTable
                    title="Zone Master"
                    buttonText="Add Zone"
                    onAddClick={handleAddZoneMaster}
                    onSearchChange={handleSearchChange}
                    activeCount={zones.filter(v => v.status === 'Active').length}
                    inactiveCount={zones.filter(v => v.status === 'Inactive').length}
                    bookedCount={zones.filter(v => v.status === 'Booked').length}
                />
                <Spin size="large" spinning={loading}>
                    <div className="bigScreenSize" style={{ color: '#747474 !important' }}>
                        <Table
                            style={{
                                paddingInline: '20px', overflowX: 'auto', borderRadius: '0 0 12px 12px', backgroundColor: 'white'
                            }}
                            rowSelection={rowSelection}
                            columns={columns}
                            dataSource={filteredData}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                total: totalItems,
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
                                onChange: handlePageChange,
                            }}
                        />
                    </div>
                </Spin>
                <div className="smallScreenSize" style={{ background: '#F7F8FB', padding: '0px 20px 20px 20px' }} >
                    <CardView data={zones} currentPage={currentPage} pageSize={pageSize} onViewDetails={handleViewDetails} />
                    <div className="mobilePagination">
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={totalItems}
                            showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`}
                            onChange={handlePageChange}
                            style={{ textAlign: 'center' }}
                        />
                    </div>
                    <DetailModal isVisible={isModalVisible} handleClose={handleCloseModal} content={modalItem && (
                        <div>
                            <p style={{ margin: "0", fontSize: '20px', fontWeight: '500' }}>Details</p>
                            <span style={{ display: 'block', height: '1px', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.06)', margin: '20px 0' }}></span>
                            <span className="cardDetails">
                                <p>Zone Name</p>
                                <p className="cardDetailHeading">{modalItem.zoneName}</p>
                            </span>
                            <span className="cardDetails">
                                <p>From</p>
                                <p className="cardDetailHeading"> {modalItem.from}</p >
                            </span>
                            <span className="cardDetails">
                                <p>To</p>
                                <p className="cardDetailHeading">{modalItem.to}</p>
                            </span>
                            {/* <span className="cardDetails">
                                <p>Time Restriction</p>
                                <p className="cardDetailHeading">{modalItem.timeRestriction}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Change Status</p>
                                <Select
                                    value={modalItem.status}
                                    style={{ width: 'fit-content' }}
                                    onChange={handleStatusChange}
                                >
                                    <Select.Option value="Active">
                                        <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                                    </Select.Option>
                                    <Select.Option value="Inactive">
                                        <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Inactive</>} />
                                    </Select.Option>
                                    <Select.Option value="Booked">
                                        <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
                                    </Select.Option>
                                </Select>
                            </span> */}
                        </div>
                    )} />
                    <ConfirmationModal isVisible={isConfirmModalVisible} handleClose={handleCancelStatusChange} content={
                        <div>
                            <p style={{ margin: "0 0 12px 0", fontSize: '20px', fontWeight: '500' }}>Are you sure?</p>
                            <p>Are You Sure You Want To Change The Status From <strong>{modalItem?.status}</strong> To <strong>{statusToChange}</strong>?</p>
                        </div>
                    } actions={[
                        <Button
                            style={{
                                marginTop: '30px',
                                width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "black",
                                border: "1px solid rgba(89, 108, 148, 0.12)",
                            }}
                            key="cancel" onClick={handleCancelStatusChange}>Cancel</Button>,
                        <Button
                            style={{
                                marginTop: '30px',
                                width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "white",
                                background: '#001B72',
                                border: "none",
                            }}
                            key="confirm" type="primary" onClick={handleConfirmStatusChange}>Confirm</Button>
                    ]} />
                </div>
            </div>
            <ConfirmationModal
                isVisible={isAddConfirmModalVisible}
                closeBtn={true}
                // onConfirm={handleConfirmAddTransporter}
                handleClose={handleCancelAddZoneMaster}
                content={
                    <div className="addVehicleMain addvehicleMasterMain">
                        <div>
                            <p style={{ margin: '0', fontSize: '20px', fontWeight: '500' }}>Add Zone Master</p>
                        </div>
                        <div className="responsiveHrLine" style={{ height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.06)', width: '100%', margin: '20px 0' }}></div>
                        <div className="" style={{ width: '100%' }}>
                            <div>
                                <p>
                                    Zone Name
                                </p>
                                <Dropdown overlay={menuList}>
                                    <Input placeholder="Enter Vehicle Type" />
                                </Dropdown>
                            </div>
                        </div>
                        {menus.map((_, index) => (
                            <div className="zoneLocationFromTo" key={index} style={{ marginTop: '12px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p style={{ color: 'rgba(0, 0, 0, 0.88)', opacity: '0.6' }}>FROM:</p>
                                    <a style={{ color: '#FF4D4F' }} href="#" onClick={() => removeMenu(index)}>Remove</a>
                                </div>
                                {menu}
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p style={{ color: 'rgba(0, 0, 0, 0.88)', opacity: '0.6' }}>TO:</p>
                                    <a style={{ color: '#FF4D4F' }} href="#" onClick={() => removeMenu(index)}>Remove</a>
                                </div>
                                {menu}
                            </div>
                        ))}
                    </div>
                } actions={[
                    <div className="addZoneFooterMainDiv" style={{ display: 'flex', gap: '12px', marginLeft: 'auto', width: 'max-content' }}>
                        <Button
                            className="addModalFooterBtn"
                            style={{
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "black",
                                border: "1px solid rgba(89, 108, 148, 0.12)",
                            }}
                            key="cancel" onClick={handleCancelAddZoneMaster}>Cancel</Button>
                        <Button
                            className="addModalFooterBtn"
                            style={{
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "white",
                                background: menus.length > 0 ? '#00C8F7' : '#D8D8D8',
                                border: "none",

                            }}
                            key="confirm" type="primary" onClick={() => {
                                // Close the modal
                                setIsAddConfirmModalVisible(false);
                                // Show SweetAlert
                                Swal.fire({
                                    html: `
                                        <div style="padding: 45px 30px 25px 30px">
                                            <img src="${successIcon}" alt="Success Icon"/>
                                            <p style="color: black; font-size: 20px; font-weight: 500;">New Zone Added Successfully</p>
                                            <p style="color: rgba(0, 0, 0, 0.60); font-size: 16px; font-weight: 400;">Lorem ipsum dolor sit amet consectetur.<br/> Interdum diam amet tincidunt amet</p>
                                            <div id="customButtonContainer"></div>
                                        </div>
                                    `,
                                    showConfirmButton: false,
                                    didOpen: () => {
                                        const container = document.getElementById('customButtonContainer');
                                        if (container) {
                                            // Render the Ant Design button inside the container
                                            const button = document.createElement('div');
                                            container.appendChild(button);
                                            ReactDOM.render(
                                                <Button
                                                    style={{
                                                        width: '74px',
                                                        height: "32px",
                                                        textAlign: "center",
                                                        borderRadius: "15px",
                                                        color: "black",
                                                        paddingInline: '15px',
                                                        border: "1px solid rgba(89, 108, 148, 0.12)",
                                                    }}
                                                    onClick={() => Swal.close()}>
                                                    OK</Button>,
                                                button
                                            );
                                        }
                                    }
                                });
                            }}>Save now</Button>
                    </div>
                ]}
            />
        </div>
    );
}
