import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Components/Navbar";
import MapContainer from "../Components/Map";
import { Button, Modal, Card, Tag } from "antd";
import CardModal from "../Assets/CardModal";
import "./dashboard.css";
import axios from "axios";

var refreshSelectedCurrentLocation;
const Dashboard = () => {
  const [controller, setController] = useState(null);
  const [selectedButton, setSelectedButton] = useState("Track+"); // Set initial state to "Track+"
  const [selectedMapyType, setSelectedMapType] = useState("track"); // Set initial state to "Track+"
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [current, setCurrent] = useState({ latitude: null, longitude: null });
  const [images, setImages] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);

  useEffect(() => {
    return () => {
      clearInterval(refreshSelectedCurrentLocation)
      if (controller) {
        controller.abort(); 
      }
    };
  }, []);

  const handleButtonClick = (buttonName, mapType) => {
    setSelectedButton(buttonName);
    setSelectedMapType(mapType);
    // Removed the modal opening functionality from here
  };

  const handleMarkerClick = (marker) => {
    console.log("marker :>> ", marker);
    fetchSelectedMarkerLocation(marker.id, marker);
    refreshSelectedCurrentLocation = setInterval(() => {
      if (selectedMapyType !== "container") {
        fetchSelectedMarkerLocation(marker.id);
        fetchSelectedMarkerImages(marker.id);
      }
    }, 1000);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    console.log("origin :>> ", origin);
    setIsModalVisible(false);
    setOrigin(null);
    setDestination(null);
    setCurrent({ latitude: null, longitude: null });
    setImages({});
    clearInterval(refreshSelectedCurrentLocation);
    if (controller) {
      controller.abort(); 
    }
  };

  const getButtonStyle = (buttonName) => {
    const isSelected = selectedButton === buttonName;
    return {
      textAlign: "center",
      border: "none",
      color: isSelected ? "white" : "black",
      borderRadius: "20px",
      backgroundColor: isSelected ? "#001B72" : "white",
      margin: "0 0", // Added margin for spacing between buttons
    };
  };

  const fetchSelectedMarkerLocation = (vehicleId, marker) => {
    const abortController = new AbortController();
    setController(abortController);
    const token = sessionStorage.getItem("token");
    const params = new URLSearchParams({
      ...(selectedMapyType === "container"
        ? { containerId: vehicleId }
        : { vehicleId }),
    });

    axios
      .get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/vehicle-snapshot/list?${params.toString()}`,
        {
          headers: {
            Authorization: `${token}`,
          },
          signal: abortController.signal,
        }
      )
      .then((response) => {
        if (response.data && response.data?.content.length) {
          const currentLocation = response.data.content[0];
          console.log(response.data);
          if (selectedMapyType === "container") {
            setCurrent({
              ...currentLocation,
              id: marker.id,
              latitude: marker.lat,
              longitude: marker.lng,
            });
          } else {
            setCurrent(currentLocation);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching locations:", error);
      });
  };

  const fetchSelectedMarkerImages = (vehicleId) => {
    const token = sessionStorage.getItem("token");
    const params = new URLSearchParams({
      page: 0,
      size: 10,
      searchKey: "latest_vehicle_images_by_vehicle",
    });

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/media/list?${params.toString()}`,
        {
          vehicleId,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data) {
          setImages(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching locations:", error);
      });
  };

  return (
    <>
      <Navbar />
      <MapContainer
        onMarkerClick={handleMarkerClick}
        origin={origin}
        destination={destination}
        current={current}
        selectedMapyType={selectedMapyType}
      />
      <div
        className="baseBtnGrp"
        style={{
          position: "absolute",
          bottom: "5vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            padding: "6px",
            backgroundColor: "white",
            borderRadius: "30px",
          }}
        >
          <Button
            className="mapBaseBtn"
            style={getButtonStyle("Track+")}
            onClick={() => handleButtonClick("Track+", "track")}
            shape="round"
            size="large"
          >
            Track+
          </Button>
          <Button
            className="mapBaseBtn"
            style={getButtonStyle("Transport")}
            onClick={() => handleButtonClick("Transport", "trip")}
            shape="round"
            size="large"
          >
            Transport
          </Button>
          <Button
            className="mapBaseBtn"
            style={getButtonStyle("Container SWAP")}
            onClick={() => handleButtonClick("Container SWAP", "container")}
            shape="round"
            size="large"
          >
            Container SWAP
          </Button>
        </div>
      </div>
      <Modal
        className="driverDashboardModal"
        maskClosable={false}
        open={isModalVisible}
        footer={null}
        onCancel={handleModalCancel}
        width={"auto"}
        mask={false}
      >
        <div
          className="truckInfoLabel"
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "40px",
            padding: "15px 20px 0 20px",
          }}
        >
          <h3 style={{ verticalAlign: "middle", height: "100%", margin: "0" }}>
            Truck Info
          </h3>
          <span
            style={{
              height: "5px",
              width: "55px",
              backgroundColor: "#B3B3B3",
              borderRadius: "5px",
              top: "10px",
              left: "45%",
              position: "absolute",
            }}
          ></span>
          <span style={{ width: "90px" }}></span>
        </div>
        <div className="CardDiv">
          <CardModal
            handleModalCancel={handleModalCancel}
            selectedMapyType={selectedMapyType}
            current={current}
            images={images}
          />
        </div>
      </Modal>
    </>
  );
};

export default Dashboard;
