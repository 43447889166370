import React, { useRef } from 'react';
import "./CamView.css";
import { Tag, Button } from 'antd';
import { GrDeliver } from "react-icons/gr";
import { HiMiniSignal } from "react-icons/hi2";
import { RiArrowDownSLine } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";
import { GoScreenFull } from "react-icons/go";
import { TfiDownload } from "react-icons/tfi";

const CamViewModal = ({ isVisible, handleCancel, currentImage, toggleImage, images }) => {
    const imageRef = useRef(null);

    const handleButtonClick = (buttonName, image) => {
        toggleImage(image);
    };

    const getButtonStyle = (buttonName) => {
        const isDriverView = currentImage === 'Front' && buttonName === 'Driver View';
        const isRoadView = currentImage === 'Back' && buttonName === 'Road View';
        return {
            textAlign: 'center',
            border: '.2px solid #E1E5EEC',
            color: isDriverView || isRoadView ? 'white' : 'black',
            borderRadius: '20px',
            backgroundColor: isDriverView || isRoadView ? '#001B72' : 'white',
            margin: '0 0 0 5px',
        };
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = currentImage === "Front" ? images.frontImage : images.backImage;
        // link.href = require(`./Images/${currentImage}`);
        link.download = currentImage === "Front" ? images.frontImage : images.backImage;
        link.click();
    };

    const handleFullscreen = () => {
        if (window.innerWidth > 767) {
            if (imageRef.current.requestFullscreen) {
                imageRef.current.requestFullscreen();
            } else if (imageRef.current.mozRequestFullScreen) { // Firefox
                imageRef.current.mozRequestFullScreen();
            } else if (imageRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
                imageRef.current.webkitRequestFullscreen();
            } else if (imageRef.current.msRequestFullscreen) { // IE/Edge
                imageRef.current.msRequestFullscreen();
            }
        }
    };

    return (
        <div className={`modal ${isVisible ? "show" : ""}`}>
            <div className="modal-content">
                <div className='modalControlsLg'>
                    <div style={{ display: 'flex', gap: '5px', alignItems:'center' }}>
                        <h3 style={{ margin: '0', padding: '0' }}>
                            Scania High Roof Front View
                        </h3>
                        <Tag icon={<GrDeliver style={{ verticalAlign: 'middle', marginRight: '5px' }} />} style={{ border: '.2px solid #596C9418 ', borderRadius: '20px', width: 'fit-content', textAlign: 'center', height: '28px', fontSize: "14px", padding: '3px 10px' }}>
                            #SCA122
                        </Tag>
                        <Tag icon={<HiMiniSignal style={{ verticalAlign: 'middle', marginRight: '5px' }} />} style={{ color: 'red', border: '.2px solid #596C9418 ', borderRadius: '20px', width: 'fit-content', textAlign: 'center', height: '28px', fontSize: "14px", padding: '3px 10px' }}>
                            Live Cam
                        </Tag>
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <Button
                            style={getButtonStyle('Driver View')}
                            onClick={() => handleButtonClick('Driver View', 'Front')}
                            shape="round"
                            size='small'
                            className='camViewBtn'
                        >
                            Driver View
                        </Button>
                        <Button
                            style={getButtonStyle('Road View')}
                            onClick={() => handleButtonClick('Road View', 'Back')}
                            shape="round"
                            size='small'
                            className='camViewBtn'
                        >
                            Road View
                        </Button>
                        <span className='vrLine' style={{ fontSize: '1em', color: '#DCDCDC', padding: '0 15px 0 25px', }}>|</span>
                        <Button
                            onClick={handleCancel}
                            className='close'
                            style={{ width: '24px', height: '24px', padding: '0', verticalAlign: 'center', border: 'none' }}
                        >
                            <IoCloseSharp style={{ fontSize: '14px', color: '#1C1C1D' }} />
                        </Button>
                    </div>
                </div>
                <div className='modalControlsMd'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', borderBottom: '1px solid #E5E5E5' }}>
                        <h3 style={{ margin: '0' }}>
                            Scania High Roof Front View
                        </h3>
                        <Button
                            onClick={handleCancel}
                            className='close'
                            style={{ width: '24px', height: '24px', padding: '0', verticalAlign: 'center', border: 'none' }}
                        >
                            <IoCloseSharp style={{ fontSize: '14px', color: '#1C1C1D' }} />
                        </Button>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center ', padding: '0 15px', overflow:'scroll' }}>
                        <Tag icon={<GrDeliver style={{ verticalAlign: 'middle', marginRight: '5px' }} />} style={{ border: '.2px solid #596C9418 ', borderRadius: '20px', width: 'fit-content', textAlign: 'center', height: '28px', fontSize: "12px", padding: '3px 10px' }}>
                            #SCA122
                        </Tag>
                        {currentImage === 'Front' ? (
                            <Tag icon={<HiMiniSignal style={{ verticalAlign: 'middle', marginRight: '5px' }} />} style={{ color: 'red', border: '.2px solid #596C9418 ', borderRadius: '20px', width: 'fit-content', textAlign: 'center', height: '28px', fontSize: "12px", padding: '3px 10px' }}>
                                Live Cam
                            </Tag>
                        ) : null}
                        <span className='' style={{ fontSize: '28px', color: '#DCDCDC' }}>|</span>
                        <Button
                            style={getButtonStyle('Driver View')}
                            onClick={() => handleButtonClick('Driver View', 'Front')}
                            shape="round"
                            size='small'
                            className='camViewBtn'
                        >
                            Driver View
                        </Button>
                        <Button
                            style={getButtonStyle('Road View')}
                            onClick={() => handleButtonClick('Road View', 'Back')}
                            shape="round"
                            size='small'
                            className='camViewBtn'
                        >
                            Road View
                        </Button>
                    </div>
                </div>

                {currentImage === 'Front' ? (
                    <div style={{ position: 'absolute', margin: '15px 0 0 20px'}}>
                        <Tag style={{ borderRadius: '12px',  fontSize:'11px'  }}>8 Jun 2024, 3:35PM</Tag>
                        <Tag style={{ borderRadius: '12px', fontSize:'11px'  }}>65 km/h</Tag>
                        <Tag style={{ borderRadius: '12px', fontSize:'11px'  }}>37.7749, -122.4194</Tag>
                    </div>
                ) : currentImage === 'Back' ? (
                    <div style={{ position: 'absolute', margin: '15px 0 0 20px', width: '92%' }}>
                        <Tag style={{ borderRadius: '12px', fontSize:'11px'  }}>8 Jun 2024 <RiArrowDownSLine style={{ fontSize: '16px', verticalAlign: 'middle', color: '#5D5D5D' }} /></Tag>
                        <Tag style={{ borderRadius: '12px', fontSize:'11px'  }}>3:35PM <RiArrowDownSLine style={{ fontSize: '16px', verticalAlign: 'middle', color: '#5D5D5D' }} /></Tag>
                        <Tag style={{ borderRadius: '12px', fontSize:'11px'  }}>65 km/h</Tag>
                        <Tag style={{ borderRadius: '12px', fontSize:'11px'  }}>37.7749, -122.4194</Tag>
                    </div>
                ) : null}
                <div className='imageControls'>
                    <span onClick={handleDownload} style={{ cursor: 'pointer' }}>
                        <TfiDownload style={{ color: '#FFFFFF', fontSize: '24px', marginRight: '20px', cursor:'pointer' }} />
                    </span>
                    <span onClick={handleFullscreen} style={{ cursor: 'pointer' }}>
                        <GoScreenFull style={{ color: '#FFFFFF', fontSize: '26px', marginRight: '20px', cursor:'pointer' }} />
                    </span>
                </div>
                <div className='camClipsDiv' >
                    {currentImage && (
                        <img ref={imageRef} className='camClips'
                            src={currentImage === "Front" ? images.frontImage : images.backImage}
                            // src={require(`./Images/${currentImage}`)}
                            alt="Modal Content"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CamViewModal;
