import React, { useState } from "react";
import { Table, Badge, Dropdown, Menu, Pagination, Select, Button } from 'antd';
import Navbar from "../../Components/Navbar";
import { LuCheckCircle2 } from "react-icons/lu";
import { RxCrossCircled } from "react-icons/rx";
import HeaderTable from '../../Components/HeaderTable';
import '../NavlinksTable.css';
import { IoIosMore, IoMdCheckmark } from "react-icons/io";
import ViewButton from "../../Assets/ViewButton";
import DetailModal from "../../Assets/DetailModal";
import ConfirmationModal from "../../Assets/ConfirmationModal";

const ActionButton = ({ status }) => {
    const menu = (
        <Menu>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">
                    Active {status === 'Active' ? <IoMdCheckmark style={{ color: 'black', marginLeft: '20px', verticalAlign: 'middle' }} /> : null}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">
                    Inactive {status === 'Inactive' ? <IoMdCheckmark style={{ color: 'black', marginLeft: '20px', verticalAlign: 'middle' }} /> : null}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a rel="noopener noreferrer" href="#">Edit</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="d-flex">
            <Dropdown overlay={menu}>
                <IoIosMore />
            </Dropdown>
        </div>
    );
};

const columns = [
    { title: '#', dataIndex: 'no' },
    { title: `Profile Name`, dataIndex: 'profileName' },
    { title: 'Starting From', dataIndex: 'startFrom' },
    { title: 'Destination', dataIndex: 'destination' },
    { title: 'Vehicle Type', dataIndex: 'vehicleType' },
    { title: 'Rate', dataIndex: 'rate' },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (text) => (
            <span>
                {text === 'Active' ? (
                    <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                ) : text === 'Booked' ? (
                    <Badge count={<><LuCheckCircle2 className="tabStatusIcon" style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
                ) : (
                    <Badge count={<><RxCrossCircled className="tabStatusIcon" style={{ color: 'red', verticalAlign: 'middle', fontSize: '17px' }} /> Inactive</>} />
                )}
            </span>
        ),
    },
    {
        title: 'Action',
        key: 'x',
        dataIndex: 'status',
        render: (status) => <ActionButton status={status} />,
    },
];

const generateData = (statuses) => {
    return statuses.map((status, index) => ({
        no: (index + 1).toString().padStart(2, '0'),
        profileName: 'Profile Name A',
        startFrom: 'Zone A',
        destination: 'Zone B',
        vehicleType: 'Truck Type Name 1',
        rate: '600 DHS',
        status: status,
    }));
};
const statuses = [
    'Active',
    'Inactive',
    'Active',
    'Inactive',
    'Booked',
    'Inactive',
    'Active',
    'Inactive',
    'Active',
    'Booked',
    'Active',
];
const data = generateData(statuses);
const activeCount = statuses.filter(status => status === 'Active').length;
const inactiveCount = statuses.filter(status => status === 'Inactive').length;
const bookedCount = statuses.filter(status => status === 'Booked').length;
const CardView = ({ data, currentPage, pageSize, onViewDetails }) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentData = data.slice(startIndex, endIndex);

    return (
        <div className="cardContainer">
            {currentData.map(item => (
                <div key={item.no} className="card">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontSize: '18px', fontWeight: '600px', margin: '0 0 10px 0' }}>{item.profileName}</p>
                        {item.status === 'Active' ? (
                            <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                        ) : item.status === 'Booked' ? (
                            <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
                        ) : (
                            <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Inactive</>} />
                        )}
                    </div>
                    <p style={{ fontSize: '14px', fontWeight: '500px', margin: '10px 0 20px 0' }}>{item.vehicleType}</p>
                    <ViewButton onClick={() => onViewDetails(item)} />
                </div>
            ))}
        </div>
    );
};
export default function RateBrowser() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [currentPageMobile, setCurrentPageMobile] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalItem, setModalItem] = useState(null);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [statusToChange, setStatusToChange] = useState(null);
    const pageSize = 5;

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const handlePageChangeMobile = (page) => {
        setCurrentPageMobile(page);
    };
    const handleViewDetails = (item) => {
        setModalItem(item);
        setIsModalVisible(true);
    };
    const handleCloseModal = () => {
        setIsModalVisible(false);
        setModalItem(null);
    };
    const handleStatusChange = (value) => {
        setStatusToChange(value);
        setIsConfirmModalVisible(true);
    };
    const handleConfirmStatusChange = () => {
        setModalItem({ ...modalItem, status: statusToChange });
        setIsConfirmModalVisible(false);
    };
    const handleCancelStatusChange = () => {
        setIsConfirmModalVisible(false);
    }; return (
        <div style={{ background: 'rgba(230, 230, 230, 0.40)', height: '100%', overflow: 'scroll' }}>
            <Navbar showStatusInfo={false} />
            <div className="tableContainer" style={{ fontWeight: '600' }}>
                <HeaderTable
                    title="Rate Browser"
                    buttonText="Refresh"
                    icon="refresh"
                    onAddClick={() => console.log('Add Rate Browser button clicked')}
                    activeCount={activeCount}
                    inactiveCount={inactiveCount}
                    bookedCount={bookedCount}
                />
                <div className="bigScreenSize" style={{ color: '#747474 !important' }}>
                    <Table
                        style={{ paddingInline: '20px',overflowX:'auto', borderRadius: '0 0 12px 12px',backgroundColor:'white' }}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            total: 100,
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of 892 items`,
                            defaultPageSize: 11,
                            defaultCurrent: 1,
                        }}
                    />
                </div>
                <div className="smallScreenSize" style={{ background: '#F7F8FB', padding: '0px 20px 20px 20px' }} >
                    <CardView data={data} currentPage={currentPageMobile} pageSize={pageSize} onViewDetails={handleViewDetails} />
                    <div className="mobilePagination">
                        <Pagination
                            current={currentPageMobile}
                            pageSize={pageSize}
                            total={data.length}
                            onChange={handlePageChangeMobile}
                            style={{ textAlign: 'center' }}
                        />
                    </div>
                    <DetailModal isVisible={isModalVisible} handleClose={handleCloseModal} content={modalItem && (
                        <div>
                            <p style={{ margin: "0", fontSize: '20px', fontWeight: '500' }}>Details</p>
                            <span style={{ display: 'block', height: '1px', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.06)', margin: '20px 0' }}></span>
                            <span className="cardDetails">
                                <p>Profile Name</p>
                                <p className="cardDetailHeading">{modalItem.profileName}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Starting From</p>
                                <p className="cardDetailHeading"> {modalItem.startFrom}</p >
                            </span>
                            <span className="cardDetails">
                                <p>Destination</p>
                                <p className="cardDetailHeading">{modalItem.destination}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Vehicle Type</p>
                                <p className="cardDetailHeading">{modalItem.vehicleType}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Rate</p>
                                <p className="cardDetailHeading">{modalItem.rate}</p>
                            </span>
                            <span className="cardDetails">
                                <p>Change Status</p>
                                <Select
                                    value={modalItem.status}
                                    style={{ width: 'fit-content' }}
                                    onChange={handleStatusChange}
                                >
                                    <Select.Option value="Active">
                                        <Badge count={<><LuCheckCircle2 style={{ color: 'green', verticalAlign: 'middle', fontSize: '16px' }} /> Active</>} />
                                    </Select.Option>
                                    <Select.Option value="Inactive">
                                        <Badge count={<><RxCrossCircled style={{ color: '#C01111', verticalAlign: 'middle', fontSize: '16px' }} /> Inactive</>} />
                                    </Select.Option>
                                    <Select.Option value="Booked">
                                        <Badge count={<><LuCheckCircle2 style={{ color: '#00C8F7', verticalAlign: 'middle', fontSize: '16px' }} /> Booked</>} />
                                    </Select.Option>
                                </Select>
                            </span>
                        </div>
                    )} />
                    <ConfirmationModal isVisible={isConfirmModalVisible} handleClose={handleCancelStatusChange} content={
                        <div>
                            <p style={{ margin: "0 0 12px 0", fontSize: '20px', fontWeight: '500' }}>Are you sure?</p>
                            <p>Are You Sure You Want To Change The Status From <strong>{modalItem?.status}</strong> To <strong>{statusToChange}</strong>?</p>
                        </div>
                    } actions={[
                        <Button
                            style={{
                                marginTop:'30px',
                                width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "black",
                                border: "1px solid rgba(89, 108, 148, 0.12)",
                            }}
                            key="cancel" onClick={handleCancelStatusChange}>Cancel</Button>,
                        <Button
                            style={{
                                marginTop:'30px',
                                width: '48%',
                                height: "32px",
                                textAlign: "center",
                                borderRadius: "50px",
                                color: "white",
                                background: '#001B72',
                                border: "none",
                            }}
                            key="confirm" type="primary" onClick={handleConfirmStatusChange}>Confirm</Button>
                    ]} />
                </div>
            </div>
        </div>
    );
}
